import { useTranslation } from "react-i18next";

import { GoogleLoginButton, FacebookLoginButton, AppleLoginButton, LocationLogo } from "@components/index";

import './login.scss';

/**
 * /login - 로그인
 */

const Login = () => {

  const { t } = useTranslation();

  return (
    <div className="content login">
      <div className="login-member">
        <div className="login-member__base">
          <div className="login-member__header">
            <LocationLogo />
            <p className="login-member__description">{t("login_description")}</p>
          </div>
          <div className="login-member__button">
            {/* Google */}
            <GoogleLoginButton />
            {/* Apple */}
            <AppleLoginButton />
            {/* Facebook */}
            <FacebookLoginButton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;