export const GOODS_DATA = [
  {
    id: 0,
    nameEn: "BLACKPINK",
    nameKo: "OFFICIAL LIGHT STICK ver.2",
    price: 39000,
    description:
      "[진함 고소함] 견과류 풍미와 초콜릿처럼 달콤 쌉싸름한 맛이 밸런스 있게 어우러진 균형잡힌 바디감의 커피",
    imgUrl: require("./images/0.png"),
  },
  {
    id: 1,
    nameEn: "BLACKPINK",
    nameKo: "ARTIST BIRTHDAY MINI CAKE HOLDER",
    price: 30000,
    description:
      "[진함 고소함] 견과류 풍미와 초콜릿처럼 달콤 쌉싸름한 맛이 밸런스 있게 어우러진 균형잡힌 바디감의 커피",
    imgUrl: require("./images/1.png"),
  },
  {
    id: 2,
    nameEn: "BLACKPINK",
    nameKo: "블랙핑크 지수 제니 로제 리사 사진 굿즈 포스터 브로마이드",
    price: 30000,
    description: "블랙핑크 지수 제니 로제 리사 사진 굿즈 포스터 브로마이드",
    imgUrl: require("./images/2.png"),
  },
  {
    id: 3,
    nameEn: "BLACKPINK",
    nameKo: "ARTIST BIRTHDAY NUMBER WHEEL NECKLACE",
    price: 30000,
    description: "ARTIST BIRTHDAY NUMBER WHEEL NECKLACE",
    imgUrl: require("./images/3.png"),
  },
  {
    id: 4,
    nameEn: "BLACKPINK",
    nameKo: "LACKPINK THE GAME PHOTOCARD COLLECTION No.1~3 (RANDOM)",
    price: 30000,
    description: "BLACKPINK THE GAME PHOTOCARD COLLECTION No.1~3 (RANDOM)",
    imgUrl: require("./images/4.png"),
  },
  {
    id: 5,
    nameEn: "BLACKPINK",
    nameKo: "블랙핑크 더 게임 OST [THE GIRLS] Stella ver. (LIMITED EDITION)",
    price: 30000,
    description: "달콤한 연유 & 고소한 카페라떼의 조합. 부드러운 단맛이 매력적인 커피",
    imgUrl: require("./images/5.png"),
  },
  {
    id: 6,
    nameEn: "BLACKPINK",
    nameKo: "[JZW] BLACKPINK BROKEN HEART SUPERSTARS",
    price: 30000,
    description:
      "은은하게 버터풍미가 도는 밀크카라멜 + 진한에스프레소 + 고소한 우유의 앙상블이 매력적인, 달콤한 카라멜 커피",
    imgUrl: require("./images/6.png"),
  },
];
