import { http } from "./http";
import qs from "qs";

// get avatar list
export const getAvatarList = (data: {}) => {
  return http.get<any>(`/avatar`);
};

// get main avatar
export const getAvatarMain = (data: {}) => {
  return http.get<any>(`/avatar/main`);
};

// post main avatar
export const postAvatarMain = (data: { CHGUID: string }) => {
  return http.post<any, null>(`/avatar/main`, data);
};
