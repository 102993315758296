import coffee1 from "../../../assets/images/kiosk/coffee1.png";
import coffee2 from "../../../assets/images/kiosk/coffee2.png";
import coffee3 from "../../../assets/images/kiosk/coffee3.png";
import coffee4 from "../../../assets/images/kiosk/coffee4.png";
import coffee5 from "../../../assets/images/kiosk/coffee5.png";
import coffee6 from "../../../assets/images/kiosk/coffee6.png";

const imgList = [coffee1, coffee2, coffee3, coffee4, coffee5, coffee6];

export default imgList;
