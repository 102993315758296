import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import { useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";
import { HOTEL_DATA } from "./placeholder-data";
import { numberWithCommas } from "@utils/numberWithCommas";
import "@components/layout/KioskLayout/kioskLayout.scss";
import Icon from "../../../shared/Icon";

const HotelOrder = () => {
  const tomorrow = new Date(new Date().setDate(new Date().getDate() + 1)); // 내일
  const navigate = useNavigate();
  const location = useLocation();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(tomorrow);
  const id = location?.state?.id || 1;
  const [data, setData] = useState<any>();
  const [times, setTimes] = useState(2);

  useEffect(() => {
    setData(HOTEL_DATA.find((data) => data.id === id));
  }, [id]);
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <div className="hotel-order-wrapper">
      <div className="hotel-order-calender">
        <DatePicker
          selected={startDate}
          onChange={onChange}
          locale={ko} // 한글로 변경
          dateFormat="yyyy-MM-dd aa hh:mm" // 시간 포맷 변경
          startDate={startDate}
          endDate={endDate}
          selectsRange
          inline
          renderCustomHeader={({ monthDate, decreaseMonth, increaseMonth }) => (
            <div className="date-picker-inner">
              <button
                aria-label="Previous Month"
                className={"react-datepicker__navigation react-datepicker__navigation--previous"}
                onClick={decreaseMonth}
              >
                <span
                  className={
                    "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
                  }
                >
                  {"<"}
                </span>
              </button>
              <span className="react-datepicker__current-month">
                {monthDate.toLocaleString("ko", {
                  month: "long",
                  year: "numeric",
                })}
              </span>
              <button
                aria-label="Next Month"
                className={"react-datepicker__navigation react-datepicker__navigation--next"}
                onClick={increaseMonth}
              >
                <span
                  className={
                    "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
                  }
                >
                  {">"}
                </span>
              </button>
            </div>
          )}
        />
      </div>

      <div className="hotel-order-detail">
        <div className="hotel-order-product">
          <div className="selected-hotel-img">
            <img src={data?.imgUrl} alt={data?.nameEn} />
          </div>
          <div className="selected-hotel-info">
            <p className="selected-hotel-name">{data?.nameEn}</p>
            <p className="selected-hotel-price">{numberWithCommas(data?.price)}원</p>
          </div>
        </div>

        <div className="hotel-headcount-wrapper">
          <p className="hotel-headcount-label">
            인원을 선택하세요. <span>(최소 2인부터 예약 가능합니다.)</span>
          </p>
          <div className={classNames("bottom-order", "hotel-headcount")}>
            <div className="box-count">
              <div className="left">
                <div className="box-icon">
                  <Icon
                    className="minus"
                    icon="minus"
                    size="100%"
                    color="#344054"
                    onClick={() => {
                      if (times > 2) {
                        setTimes(times - 1);
                      }
                    }}
                  />
                </div>
                <p>{times}</p>
                <div className="box-icon">
                  <Icon
                    className="plus"
                    icon="plus"
                    size="100%"
                    color="#344054"
                    onClick={() => {
                      if (times < 10) {
                        setTimes(times + 1);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="hotel-total-wrapper">
          <p>최종 결제 금액</p>
          <p>{numberWithCommas(data?.price)}</p>
        </div>

        <div className="hotel-terms">
          <span>· 예약 및 취소/환불에 대한 서비스 약관 동의</span>
          <button>보기</button>
        </div>
        <div className="hotel-terms">
          <span>· 개인정보 제3자 제공 동의</span>
          <button>보기</button>
        </div>
        <button
          className="hotel-order-btn"
          onClick={() => {
            navigate(`/hotel/complete`, {
              state: {
                id: data.id,
                headcount: times,
                checkInDate: startDate,
                checkOutDate: endDate === null ? new Date() : endDate,
              },
            });
          }}
        >
          예약하기
        </button>
      </div>
    </div>
  );
};

export default HotelOrder;
