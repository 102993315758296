import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { dateToString } from "@shared/utils/date";

import Icon from "../../../../shared/Icon";
import { NormalButton, MyMenu, InputBox, SearchList, LocationLogo, WorldSearchMobileModal } from "@components/index";
import {
  RootState,
  GNBState,
  gnbSetting,
  SearchModalState,
  LoginState,
  mymenuSetting,
  MypageGnbState,
  mypageGnbSetting,
  PathState,
  worldSearchModalSetting,
} from "@store/index";

import "./mainHeader.scss";

const zemiAlertData = [
  // {
  //   title: "콘텐츠 검수완료",
  //   description:
  //     "요청하신 콘텐츠 검수가 완료 처리되었습니다.\nMy 컬렉션에서 확인 가능합니다.요청하신 콘텐츠 검수가 완료 처리되었습니다.요청하신 콘텐츠 검수가 완료 처리되었습니다.요청하신 콘텐츠 검수가 완료 처리되었습니다.요청하신 콘텐츠 검수가 완료 처리되었습니다.요청하신 콘텐츠 검수가 완료 처리되었습니다.요청하신 콘텐츠 검수가 완료 처리되었습니다.요청하신 콘텐츠 검수가 완료 처리되었습니다.요청하신 콘텐츠 검수가 완료 처리되었습니다.",
  //   date: "2023-08-04T14:02:18.809Z",
  //   view: false,
  // },
  // {
  //   title: "콘텐츠 검수완료",
  //   description: "요청하신 콘텐츠 검수가 완료 처리되었습니다.\nMy 컬렉션에서 확인 가능합니다.",
  //   date: "2023-08-03T14:02:18.809Z",
  //   view: false,
  // },
];

interface keyInterface {
  id: number;
  text: string;
}

const MainHeader = () => {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const gnb: GNBState = useSelector((state: RootState) => state.gnb);
  const mypageGnb: MypageGnbState = useSelector((state: RootState) => state.mypageGnb);
  const searchModal: SearchModalState = useSelector((state: RootState) => state.searchModal);
  const path: PathState = useSelector((state: RootState) => state.path);
  const login: LoginState = useSelector((state: RootState) => state.login);

  const [isShown, setIsShown] = useState(false);
  const [zemiAlert, setZemiAlert] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [keywords, setKeywords] = useState<keyInterface[]>([]);

  // 검색어 추가
  const handleAddKeyword = (text: string) => {
    if (text === null || text === undefined || text === "") {
    } else {
      const newKeyword = {
        id: Date.now(),
        text: text,
      };
      setKeywords([newKeyword, ...keywords]);
    }
  };

  // 단일 검색어 삭제
  const handleRemoveKeyword = (id: number) => {
    const nextKeyword = keywords.filter((keyword) => {
      return keyword.id !== id;
    });
    setKeywords(nextKeyword);
  };

  //검색어 전체 삭제
  const handleClearKeywords = () => {
    setKeywords([]);
  };

  const handleOnKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const isEnterKey = e.key === "Enter";
    if (isEnterKey) {
      handleAddKeyword(searchValue);
      setSearchValue("");
      setIsShown(false);
    }
  };

  const AlermList = (data: any) => {
    return (
      <li className="list__alerm">
        <div className="top">
          <p className="title">{data.data.title}</p>
          <p className="date">{dateToString(data.data.date, "yyyy.MM.dd", i18n.language)}</p>
        </div>
        <p className="description">{data.data.description}</p>
      </li>
    );
  };

  // ① window 즉, 브라우저가 모두 렌더링된 상태에서 해당 함수를 실행할 수 있도록 작업
  useEffect(() => {
    if (typeof window !== "undefined") {
      const result = localStorage.getItem("keywords") || "[]";
      setKeywords(JSON.parse(result));
    }
  }, []);

  // ② keywords 객체를 의존하여, 변경될 경우 새롭게 localStroage의 아이템 'keywords'를 세팅한다
  useEffect(() => {
    localStorage.setItem("keywords", JSON.stringify(keywords));
  }, [keywords]);

  return (
    <>
      <div
        className={classNames(
          "main_header",
          location.pathname === "/" ? "main" : "",
          searchModal.open ? "search-modal" : null
        )}
      >
        <Icon
          className="button__hamburger"
          icon={!gnb.open ? "menu" : "x"}
          size={24}
          color="#101828"
          onClick={() => {
            console.log(`sdfsdfsafds`);
            console.log(gnb.open, mypageGnb.open);
            if (gnb.open === false && mypageGnb.open === false) {
              if (path.firstPath === "mypage") {
                dispatch(mypageGnbSetting(true));
                dispatch(mymenuSetting(false));
              } else {
                dispatch(gnbSetting(true));
                dispatch(mymenuSetting(false));
              }
            } else if (gnb.open && mypageGnb.open === false) {
              dispatch(gnbSetting(false));
              dispatch(mymenuSetting(false));
            } else if (gnb.open === false && mypageGnb.open) {
              dispatch(mypageGnbSetting(false));
              dispatch(mymenuSetting(false));
            }
          }}
        />
        <LocationLogo />

        <div className="right">
          {/* search world */}
          <div className="search__world">
            <Icon
              className="globe-search"
              icon="globe-search"
              size={28}
              color="#101828"
              onClick={() => {
                dispatch(gnbSetting(false));
                dispatch(mymenuSetting(false));
                dispatch(worldSearchModalSetting({ open: !searchModal.open, type: "world" }));
              }}
            />
            <InputBox
              value={searchValue}
              onChange={(e: any) => {
                setSearchValue(e.target.value);
              }}
              onKeyPress={handleOnKeyPress}
              name="title"
              placeholder="월드를 입력하세요"
              icon
              iconLocation="back"
              iconName="globe-search"
              iconSize={24}
              iconColor="#344054"
              onFocus={() => {
                setIsShown(true);
                setZemiAlert(false);
                dispatch(mymenuSetting(false));
                dispatch(gnbSetting(false));
              }}
              onBlur={() => {
                setIsShown(false);
              }}
            />
            {!isShown || keywords.length <= 0 ? null : (
              <div className="box__search">
                {keywords.map((data: any, id: number) => (
                  <SearchList
                    key={id}
                    data={data}
                    deleteSearchValue={handleRemoveKeyword}
                    setSearchValue={setSearchValue}
                  />
                ))}
              </div>
            )}
          </div>

          {/* alert */}
          <div className="alert__zemi">
            <Icon
              className="button__bell"
              icon="bell"
              size={28}
              color="#344054"
              onClick={() => {
                setZemiAlert(!zemiAlert);
                // setIsShown(false);
                // dispatch(mymenuSetting(false));
              }}
            />

            {!zemiAlert ? null : (
              <div className="box">
                <div
                  className="button__back"
                  onClick={() => {
                    setZemiAlert(!zemiAlert);
                    setIsShown(false);
                    dispatch(mymenuSetting(false));
                  }}
                ></div>
                <p className="title">알림 센터</p>
                <ul className="box__alert">
                  {zemiAlertData === null || zemiAlertData === undefined || zemiAlertData?.length <= 0 ? (
                    <div className="center">
                      <div className="img"></div>
                      <p>수신한 알림이 없습니다.</p>
                    </div>
                  ) : (
                    zemiAlertData.map((data: any, idx: number) => <AlermList data={data} key={idx} />)
                  )}
                </ul>
                {zemiAlertData === null || zemiAlertData === undefined || zemiAlertData?.length <= 0 ? (
                  <p className="empty">알림은 30일간 보관됩니다.</p>
                ) : (
                  <NormalButton title="알림 목록 전체 삭제" size="xl" buttonStyle="box" hierarchy="red" />
                )}
              </div>
            )}
          </div>

          {/* my menu */}
          {login.login && <MyMenu />}

          {/* login_button */}
          {!login.login && (
            <NormalButton
              title={t("header_login")}
              onClick={() => {
                dispatch(gnbSetting(false));
                navigate("/login");
                // dispatch(alertModalSetting(!alert.open));
              }}
              buttonStyle="round"
              size="sm"
              hierarchy="secondary_gray"
              buttonClassName="avatar"
            />
          )}
        </div>
      </div>
      {searchModal.open && searchModal.type === "world" && (
        <WorldSearchMobileModal
          searchValueList={keywords}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          handleOnKeyPress={handleOnKeyPress}
          deleteAllSearchValue={handleClearKeywords}
          deleteSearchValue={handleRemoveKeyword}
        />
      )}
    </>
  );
};

export default MainHeader;
