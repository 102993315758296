import { sendMessageExit } from "@components/Webview/unitySender";
import Icon from "@shared/Icon";
import classNames from "classnames";

const PlaceDetail = () => {
  const handleOnCancel = () => {
    console.log("exit");
    if (window.vuplex) {
      sendMessageExit();
    } else {
      window.addEventListener("vuplexready", sendMessageExit);
    }
  };

  const handleLookAround = (travel) => {
    console.log(travel);
    const sendMessage = () => {
      window.vuplex.postMessage({ type: "Teleport", message: travel });
    };
    if (window.vuplex) {
      sendMessage();
    } else {
      window.addEventListener("vuplexready", sendMessage);
    }
  };

  return (
    <>
      <div className="milestone-header">
        <h1>체험할 목적지를 선택하세요.</h1>
        <div className="concert-x-icon">
          <Icon className="x" icon="x" size="100%" color="#344054" onClick={handleOnCancel} />
        </div>
      </div>
      <div className="intro-outer">
        <div className="intro-inner">
          <div className={classNames("intro-wrapper", "hotel")}>
            <p className="intro-title">Hotel 체험</p>
            <button className="intro-button" onClick={() => handleLookAround("ir_hotel")}>
              Play
            </button>
          </div>
          <div className={classNames("intro-wrapper", "firework")}>
            <p className="intro-title">불꽃놀이 체험</p>
            <button className="intro-button" onClick={() => handleLookAround("ir_firework")}>
              Play
            </button>
          </div>
        </div>
        <div className="intro-inner">
          <div className={classNames("intro-wrapper", "cafe")}>
            <p className="intro-title">Cafe 체험</p>
            <button className="intro-button" onClick={() => handleLookAround("ir_cafe")}>
              Play
            </button>
          </div>
          <div className={classNames("intro-wrapper", "restaurant")}>
            <p className="intro-title">음식점 체험</p>
            <button className="intro-button" onClick={() => handleLookAround("ir_restaurant")}>
              Play
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PlaceDetail;
