import Icon from "../../shared/Icon";
import { useState, useRef } from "react";

const WebviewSearch = ({ setActiveSearch }) => {
  const [keyword, setKeyword] = useState("");
  const searchRef = useRef(null);

  return (
    <>
      <div className="webview-search">
        <div className="icon-wrapper">
          <Icon
            className="chevron-right-small"
            icon="chevron-right-small"
            size="100%"
            color="#98A2B3"
            onClick={() => {
              setActiveSearch(false);
            }}
          />
        </div>

        <div className="search-wrapper">
          <div className="search-icon-wrapper">
            <Icon className="search-icon" icon="search" size="100%" color="#101828" />
          </div>
          <input
            className="search-input"
            value={keyword}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setKeyword(e.target.value);
            }}
            placeholder="월드 검색"
            ref={searchRef}
          />
          {keyword && (
            <div
              className="cancel-button"
              onClick={(e) => {
                setKeyword("");
                searchRef.current.focus();
              }}
            >
              <Icon icon="x" size="62%" color="#fff" />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default WebviewSearch;
