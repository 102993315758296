import { Dispatch, SetStateAction } from "react";
import Icon from "../../../../shared/Icon";
import classNames from "classnames";

import "./avatarList.scss";

type AvatarListProps = {
  data: any;
  selected: string;
  setSelected: Dispatch<SetStateAction<string>>;
};

const AvatarList = ({ data, selected, setSelected }: AvatarListProps) => {
  return (
    <div
      className={classNames("box-item", { selected: selected === data.CHGUID })}
      onClick={() => {
        setSelected(data.CHGUID);
      }}
    >
      <div className="top">
        <p className="nickname">{data.nickname}</p>
        <div className="img__wrapper">
          {/* <img src="" alt="" /> */}
          <div className="img"></div>
        </div>
      </div>
      <div className="bottom">
        <Icon
          className="check-square"
          icon="check-square"
          size={18}
          color={selected === data.CHGUID ? "#7F56D9" : "#D0D5DD"}
        />
        <p>대표아바타</p>
      </div>
    </div>
  );
};

export default AvatarList;
