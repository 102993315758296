import classNames from "classnames";

import Icon from "../../../shared/Icon";
import { ReactComponent as SearchIcon } from "@assets/images/layer/search.svg";

import "./inputbox.scss";

type iconLocation = "front" | "back";
type inputStyle = "round" | "box";

export interface InputBoxProps {
  value: string | undefined;
  name?: string;
  placeholder?: string;
  label?: string;

  style?: inputStyle;
  icon?: boolean;
  iconLocation?: iconLocation;
  iconSize?: number;
  iconColor?: string;
  iconName?: string;

  logMessage?: string;
  logType?: string;

  maxLength?: number;
  disabled?: boolean;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onKeyPress?: React.KeyboardEventHandler<HTMLInputElement> | undefined;
}

/**
 * @param name input에 들어갈 이름, label과 연결하기 위해서 사용
 * @param value 현재 설정되어 있는 기본 값
 * @param onChange onChnage함수로 동작하게 될 함수
 * @param placeholder placeholder값
 * @param icon icon의 여부
 *
 * input 아래에 경고 메시지가 생겨야 할 경우
 * @param logMessage 경고 메시지 text
 * @param logType 경고의 종류(info, error, warn)
 */
const InputBox = ({
  name,
  value,
  onChange,
  onKeyPress,
  onFocus,
  onBlur,
  placeholder,
  label,
  style,
  icon,
  iconLocation,
  iconSize,
  iconColor,
  iconName,
  logMessage,
  logType,
  disabled,
  maxLength,
}: InputBoxProps) => {
  
  const searchInput = () => {
    return (
      <div className="search_input">
        {iconLocation === "front" && (iconName === null || iconName === undefined || iconName === "") ? (
          <Icon
            className="search"
            icon="search"
            size={iconSize ? iconSize : 24}
            color={iconColor ? iconColor : "#101828"}
          />
        ) : iconLocation === "front" && (iconName !== null || iconName !== undefined || iconName !== "") ? (
          <Icon
            className={iconName}
            icon={iconName}
            size={iconSize ? iconSize : 24}
            color={iconColor ? iconColor : "#101828"}
          />
        ) : null}
        <input
          type="text"
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          onKeyPress={onKeyPress}
          disabled={disabled}
          maxLength={maxLength}
          onFocus={onFocus}
          onBlur={onBlur}
        />
        {iconLocation === "back" && (iconName === null || iconName === undefined || iconName === "") ? (
          <Icon
            className="search"
            icon="search"
            size={iconSize ? iconSize : 24}
            color={iconColor ? iconColor : "#101828"}
          />
        ) : iconLocation === "back" && (iconName !== null || iconName !== undefined || iconName !== "") ? (
          <Icon
            className={iconName}
            icon={iconName}
            size={iconSize ? iconSize : 24}
            color={iconColor ? iconColor : "#101828"}
          />
        ) : null}
      </div>
    );
  };

  const defaultInput = () => {
    return (
      <input
        type="text"
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        onKeyPress={onKeyPress}
        disabled={disabled}
        maxLength={maxLength}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    );
  };

  return (
    <div className={classNames("input_wrapper", style)}>
      {label && <label htmlFor={name}>{label}</label>}
      {icon ? searchInput() : defaultInput()}
      {logMessage && (
        <p
          className={classNames(`log`, {
            info: logType === "info",
            error: logType === "error",
            warn: logType === "warn",
          })}
        >
          {logMessage}
        </p>
      )}
    </div>
  );
};

export default InputBox;
