import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  RootState,
  ModalState,
  modalRegisterSetting,
  AlertModalState,
  alertModalSetting,
  profileSetting,
  loginSetting,
} from "@store/index";
import { PageTop, NormalButton, InputBox, CheckBox, SelectBox } from "@components/index";
import Icon from "../../../shared/Icon";

import "./withDrawModal.scss";

const WithDrawModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const modal: ModalState = useSelector((state: RootState) => state.modal);
  const alert: AlertModalState = useSelector((state: RootState) => state.alert);

  const [confirm, setConfirm] = useState(false);

  return (
    <div
      className="dim"
      onClick={() => {
        dispatch(modalRegisterSetting({ open: !modal.open, type: "" }));
      }}
    >
      <div
        className="box-withdraw"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Icon
          className="x"
          icon="x"
          size={24}
          color="#101828"
          onClick={() => {
            dispatch(modalRegisterSetting({ open: !modal.open, type: "" }));
          }}
        />
        <p className="title">통합 회원 탈퇴</p>
        <p className="subtitle">회원탈퇴 규정&유의 사항</p>
        <div className="content-withdraw">{t("withdraw")}</div>
        <CheckBox
          name="confirm-modal"
          onChange={() => setConfirm(!confirm)}
          checked={confirm}
          terms={confirm}
          label="회원 탈퇴 유의사항을 확인하였습니다."
        />
        <div className="box-buttons">
          <NormalButton
            title="취소"
            size="md"
            hierarchy="secondary_gray"
            buttonStyle="box"
            onClick={() => {
              dispatch(modalRegisterSetting({ open: !modal.open, type: "" }));
            }}
          />
          <NormalButton
            title="회원탈퇴하기"
            size="md"
            hierarchy="primary"
            buttonStyle="box"
            onClick={() => {
              if (confirm) {
                dispatch(alertModalSetting({ open: !alert.open, title: "현재 탈퇴가 불가능합니다." }));
                dispatch(modalRegisterSetting({ open: !modal.open, type: "" }));
                // dispatch(profileSetting({ nickname: "", picture: "", sns: "", email: "" }));
                // dispatch(loginSetting(false));
                // navigate("/");
                // dispatch(
                //   alertModalSetting({
                //     open: !alert.open,
                //     title: "회원탈퇴 신청이 완료 되었습니다.",
                //     icon: "check",
                //     iconColor: "#12B76A",
                //   })
                // );
                setTimeout(() => {
                  dispatch(alertModalSetting({ open: false, title: "" }));
                }, 2000);
              }
            }}
            disable={confirm === false}
          />
        </div>
      </div>
    </div>
  );
};

export default WithDrawModal;
