import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import kioskData from "../../../shared/kioskData.json";
import { numberWithCommas } from "@utils/numberWithCommas";
import imgList from "./imgData";
import { updateDateToString } from "@utils/updateDate";
import { useTranslation } from "react-i18next";

const OrderComplete = () => {
  const { i18n, t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const pageId = location.state?.id;
  const [data, setData] = useState<any>();

  useEffect(() => {
    if (pageId !== null || pageId !== undefined) {
      setData(kioskData.find((data) => data.id === pageId));
    }
  }, [pageId]);

  return (
    <>
      <div className="box-complete">
        <p className="complete">주문이 완료되었습니다.</p>
        <p className="date">{updateDateToString(location.state?.now, "yyyy.MM.dd hh:mm", i18n.language)}</p>
      </div>
      <div className="box-complete-list">
        <div className="list-order">
          <div className="box-img">
            <img src={imgList[pageId - 1]} alt="" />
          </div>
          <div className="right">
            <div className="top">
              <p className="title-ko">{data?.nameKo}</p>
              <p className="temperature">{location.state?.temperature === 0 ? "HOT" : "ICED"}</p>
            </div>
            <p className="price">{numberWithCommas(data?.price)}원</p>
          </div>
        </div>
      </div>
      <div className="box-total">
        <p className="total-count">총 결제 금액&#40;{location.state?.times}개&#41;</p>
        <p className="total-price">{numberWithCommas(data?.price * location.state?.times)}원</p>
      </div>
      <div
        className="button-complete"
        onClick={() => {
          navigate(`/kiosk`);
        }}
      >
        <p>메뉴 보기</p>
      </div>
    </>
  );
};

export default OrderComplete;
