import { SelectBox } from "@components/common";
import "./useAvatar.scss";
import useSelect from "@hooks/useSelect";
import { useTranslation } from "react-i18next";

export interface avatarProps {
  avatarList?: any[];
  selectData: any;
}
const UseAvatar = ({ avatarList, selectData }: avatarProps) => {
  const { t } = useTranslation();

  if (avatarList === null || avatarList === undefined || avatarList?.length <= 0) {
    return (
      <div className="avatar__container">
        <div className="avatar__wrapper">
          <p className="avatar__label">{t("avatar_choose")}</p>
          <p className="avatar__empty">{t("register_no_avatar")}</p>
        </div>
        <div className="avatar__explanation">
          <p>{t("avatar_check4")}</p>
          <p>{t("avatar_check2")}</p>
          <p>{t("avatar_check3")}</p>
        </div>
      </div>
    );
  } else {
    return (
      <div className="avatar__container">
        <div className="avatar__wrapper">
          <p className="avatar__label">{t("avatar_choose")}</p>
          <SelectBox name="avatar" image data={avatarList} selectData={selectData} />
        </div>
        <div className="avatar__explanation">
          <p>{t("avatar_check1")}</p>
          <p>{t("avatar_check2")}</p>
          <p>{t("avatar_check3")}</p>
        </div>
      </div>
    );
  }
};

export default UseAvatar;
