import { Outlet, useLocation } from "react-router-dom";
import { KioskHeader } from "@components/index";
import "../KioskLayout/kioskLayout.scss";
import "./restaurantLayout.scss";
import { useEffect, useState } from "react";

const RestaurantLayout = () => {
  const location = useLocation();
  const [headerType, setHeaderType] = useState("title");

  useEffect(() => {
    if (window.location.pathname === "/restaurant") {
      setHeaderType("restaurant-title");
    } else if (window.location.pathname === "/restaurant/complete") {
      setHeaderType("close");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <div className="webview kiosk container">
      <KioskHeader type={headerType} />
      <Outlet />
    </div>
  );
};

export default RestaurantLayout;
