import { useTranslation } from 'react-i18next';

import './club.scss';

const Club = () => {

  const { t } = useTranslation();

  return (
    <div className="container club">
      <div className="club__inner">

        {/* content1 */}
        <div className="content1">
          <p className="title">{t("club_content1_title")}</p>
          <p className="description">{t("club_content1_description")}</p>
        </div>

        {/* content2 */}
        <div className="content2">
          <div className="content2__inner">
            <p className="title">{t("club_content2_title")}</p>
            <p className="description">{t("club_content2_description")}</p>
          </div>
        </div>

        {/* content3 */}
        <div className="content3">
          <p className="title">What`s ZEMIVERSE Club?</p>
          <div className="content3__inner">
            <div className="box">
              <div className="img"></div>
              <div className="box__inner">
                <p className="title">{t("club_content3_box1_title")}</p>
                <p className="description">{t("club_content3_box1_description")}</p>
              </div>
            </div>
            <div className="box">
              <div className="img"></div>
              <div className="box__inner">
                <p className="title">{t("club_content3_box2_title")}</p>
                <p className="description">{t("club_content3_box2_description")}</p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Club;