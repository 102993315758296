import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { HOTEL_DATA } from "./placeholder-data";
import classNames from "classnames";

const HotelDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pageId = location.state?.id;

  const [data, setData] = useState<any>({
    id: 1,
    nameEn: "Americano",
    nameKo: "아메리카노",
    price: 2500,
    description:
      "[진함 고소함] 견과류 풍미와 초콜릿처럼 달콤 쌉싸름한 맛이 밸런스 있게 어우러진 균형잡힌 바디감의 커피",
  });

  useEffect(() => {
    if (pageId !== null || pageId !== undefined) {
      setData(HOTEL_DATA.find((data) => data.id === pageId));
    }
  }, [pageId]);

  const handleLookAround = () => {
    const sendMessage = () => {
      window.vuplex.postMessage({ type: "Teleport", message: "ir_room" });
    };
    if (window.vuplex) {
      sendMessage();
    } else {
      window.addEventListener("vuplexready", sendMessage);
    }
  };

  return (
    <>
      <div className={classNames("top-order", "goods-detail-wrapper", "hotel-wrapper")}>
        <div className={classNames("box-img", "hotel-detail-img")}>
          <img src={data.imgUrl} alt="" />
        </div>
        <div className={classNames("right", "hotel-right")}>
          <p className={classNames("hotel-title")}>{data?.nameEn}</p>
          <p className="hotel-description">
            대형 창을 통해 광안리의 푸른 바다와 아름다운 광안대교가 한 눈에 내려다 보이며 화려한
            광안리해수욕장의 오션뷰가 이국적인 분위기를 선사합니다.
          </p>
          <div className="hotel-info">
            <div className="hotel-info-label">
              <ul>
                <li>· 체크인/체크아웃</li>
                <li>· 객실크기</li>
                <li>· 객실 구성</li>
                <li>· 전망</li>
                <li>· 침대 타입</li>
                <li>· 기타</li>
              </ul>
            </div>
            <div className="hotel-info-content">
              <ul>
                <li>체크인 15:00 ~ 체크아웃 11:00</li>
                <li>
                  60m<sup>2</sup>
                </li>
                <li>침실, 거실, 욕실</li>
                <li>부산 광안리 바다 전망</li>
                <li>King or Twin</li>
                <li>전 객실 금연</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="hotel-btn">
        <button onClick={handleLookAround}>둘러보기</button>
        <button
          onClick={() => {
            navigate(`/hotel/order`, {
              state: { id: data.id },
            });
          }}
        >
          예약하기
        </button>
      </div>
    </>
  );
};

export default HotelDetail;
