import { InputBox, NormalButton, SearchList } from "@components/index";
import { RootState, SearchModalState, searchModalSetting } from "@store/index";
import { useSelector, useDispatch } from "react-redux";

import "../modal.scss";
import classNames from "classnames";

interface SearchMobileModalProps {
  searchValueList?: any[];
  searchValue?: string | undefined;
  setSearchValue: Function;
  handleOnKeyPress?: React.KeyboardEventHandler<HTMLInputElement> | undefined;
  deleteAllSearchValue?: Function;
  deleteSearchValue?: Function;
}

const SearchMobileModal = ({
  searchValueList,
  searchValue,
  setSearchValue,
  handleOnKeyPress,
  deleteAllSearchValue,
  deleteSearchValue,
}: SearchMobileModalProps) => {
  const dispatch = useDispatch();

  const searchModal: SearchModalState = useSelector((state: RootState) => state.searchModal);

  return (
    <div className="dim search__modal">
      <div className="top">
        <div className="box">
          <InputBox
            value={searchValue}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSearchValue(e.target.value);
            }}
            placeholder="검색어를 입력해주세요."
            onKeyPress={handleOnKeyPress}
            icon
            iconLocation={searchValue !== "" && searchValue !== undefined && searchValue !== null ? null : "back"}
            iconSize={20}
            iconColor="#344054"
            iconName="search"
          />
          <div
            className={classNames("delete__searchvalue", {
              on: searchValue !== "" && searchValue !== undefined && searchValue !== null,
            })}
            onClick={() => {
              setSearchValue(``);
            }}
          ></div>
        </div>

        <p
          className="button-cancel"
          onClick={() => {
            dispatch(searchModalSetting(!searchModal.open));
          }}
        >
          취소
        </p>
        {/* <NormalButton title="취소" size="sm" hierarchy="link_gray" buttonStyle="round" /> */}
      </div>
      <div className="bottom">
        <div className="top">
          <p className="title">최근검색어</p>
          {searchValueList.length !== 0 && (
            <p
              className="button-delete-all"
              onClick={() => {
                deleteAllSearchValue();
              }}
            >
              전체삭제
            </p>
          )}
        </div>
        <div className="box">
          {searchValueList.length <= 0 ? (
            <p className="no_list">최근 검색 내역이 없습니다.</p>
          ) : (
            <>
              {searchValueList.map((data: any, id: number) => (
                <SearchList
                  key={id}
                  data={data}
                  deleteSearchValue={deleteSearchValue}
                  setSearchValue={setSearchValue}
                />
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchMobileModal;
