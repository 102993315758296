import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import classNames from "classnames";

import Icon from "../../../../shared/Icon";
import {
  MypageGnbState,
  mypageGnbSetting,
  RootState,
  LoginState,
  alertModalSetting,
  AlertModalState,
} from "@store/index";
import { Footer } from "@components/index";

import "./mypageHeader.scss";

const MypageHeader = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const alert: AlertModalState = useSelector((state: RootState) => state.alert);
  const login: LoginState = useSelector((state: RootState) => state.login);
  const mypageGnb: MypageGnbState = useSelector((state: RootState) => state.mypageGnb);

  const [open, setOpen] = useState({
    open: false,
    id: 0,
  });

  const mypageMenu = [
    { id: 1, title: "대쉬보드", link: "/mypage" },
    { id: 2, title: "My 컬렉션", link: "/mypage/collection" },
    { id: 4, title: "My 샵", link: "/mypage/shop" },
    { id: 3, title: "거래내역", link: "/mypage/transaction" },
    { id: 3, title: "클럽", link: "/mypage/club" },
    {
      id: 5,
      title: "결재/정산",
      link: "/mypage/calculate",
      children: [
        { id: 51, title: "내 수익", link: "/mypage/calculate/earn" },
        { id: 52, title: "ZEMI 충전 하기", link: "/mypage/calculate/charge" },
        { id: 53, title: "ZEMI 환전 하기", link: "/mypage/calculate/exchange" },
      ],
    },
    {
      id: 6,
      title: "계정설정",
      link: "/mypage/setting",
      children: [
        { id: 61, title: "프로필 변경", link: "/mypage/setting/profile" },
        { id: 62, title: "대표 아바타 변경", link: "/mypage/setting/avatar" },
      ],
    },
    { id: 7, title: "1:1 문의", link: "/mypage/inquiry" },
    // { id: 8, title: "로그아웃", link: "/" },
  ];

  const checkOn = (data: any) => {
    if (!data.children) {
      return data.link === location.pathname ? "on" : "off";
    } else {
      return data.children.some((d: any) => d.link === location.pathname) ? "on" : "off";
    }
  };

  // zemiMenu map 용
  const Nav = (data: any, index: number) => {
    return (
      <li
        key={index}
        className={classNames(
          `nav_li_1deps`,
          checkOn(data.data),
          {
            open:
              checkOn(data.data) === "on" || // 현재 열린 페이지거나
              (checkOn(data.data) === "off" && // 현재 열린 페이지는 아니지만 현재 열어놓은 메뉴가 일치할 때
                open.open &&
                ((data.data.title === "결재/정산" && open.id === 1) ||
                  (data.data.title === "계정설정" && open.id === 2))),
          },
          {
            close:
              checkOn(data.data === "off") ||
              (checkOn(data.data === "on") &&
                (!open.open ||
                  (data.data.title === "결재/정산" && open.id === 1) ||
                  (data.data.title === "계정설정" && open.id === 2))),
          }
        )}
        onClick={() => {
          console.log(`data.data.title`, data.data.title);
          console.log(`checkOn(data.data)`, checkOn(data.data));
          if (data.data.title === "결재/정산" || data.data.title === "계정설정") {
            if (checkOn(data.data) === "on") {
              if (data.data.title === "결재/정산") {
                if (open.id === 1) {
                  setOpen({ open: !open.open, id: 1 });
                }
              } else if (data.data.title === "계정설정") {
                if (open.id === 2) {
                  setOpen({ open: !open.open, id: 2 });
                }
              }
            } else {
              if (data.data.title === "결재/정산") {
                if (open.id === 1) {
                  setOpen({ open: !open.open, id: 1 });
                } else {
                  setOpen({ open: true, id: 1 });
                }
              } else if (data.data.title === "계정설정") {
                if (open.id === 2) {
                  setOpen({ open: !open.open, id: 2 });
                } else {
                  setOpen({ open: true, id: 2 });
                }
              }
            }
          } else {
            dispatch(alertModalSetting({ open: !alert.open, title: "개발 중입니다." }));
            setTimeout(() => {
              dispatch(alertModalSetting({ open: false, title: "" }));
            }, 2000);
            // navigate(`${data.data.link}`);
            setOpen({ open: false, id: 0 });
          }
        }}
      >
        <Icon
          className={data.data.icon}
          icon={data.data.icon}
          size={24}
          color={
            checkOn(data.data) === "on" && (checkOn(data.data) === "on" || (open.open && checkOn(data.data) === "off"))
              ? "#6941C6"
              : "#667085"
          }
        />
        {data.data.title}
        {data.data.children && (
          <ul className={classNames(`nav_ul_2deps`)} onClick={(e) => e.stopPropagation()}>
            {data.data.children.map((d: any, idx: number) => (
              <li
                key={idx}
                className={classNames(`nav_li_2deps`, checkOn(d))}
                onClick={(e) => {
                  if (d.title === "프로필 변경" || d.title === "대표 아바타 변경") {
                    navigate(`${d.link}`);
                  } else {
                    dispatch(alertModalSetting({ open: !alert.open, title: "개발 중입니다." }));
                    setTimeout(() => {
                      dispatch(alertModalSetting({ open: false, title: "" }));
                    }, 2000);
                  }
                  dispatch(mypageGnbSetting(false));
                }}
              >
                <Icon className="corner-down-right" icon="corner-down-right" size={10} color="#d0d5dd" />
                {d.title}
              </li>
            ))}
          </ul>
        )}
      </li>
    );
  };

  return (
    <div
      className={classNames("dim__header", { open: mypageGnb.open })}
      onClick={() => {
        dispatch(mypageGnbSetting(false));
      }}
    >
      <div className={classNames("mypage-header", { open: mypageGnb.open })} onClick={(e) => e.stopPropagation()}>
        {/* 상단 프로필 */}
        <div className="top">
          <div className="box-profile">
            <img
              className="profile"
              src={
                login.picture !== undefined &&
                login.picture !== null &&
                login.picture !== `undefined` &&
                login.picture !== ""
                  ? login.picture
                  : `/images/avatar2.png`
              }
              alt=""
            />
            <p className="nickname">{login.nickname}</p>
            <p>마이페이지</p>
          </div>

          {/* 메뉴 */}
          <ul className="gnb__main">
            {mypageMenu.map((data: any, idx: number) => (
              <Nav data={data} id={idx} key={idx} />
            ))}
          </ul>
        </div>

        {/* footer */}
        <Footer />
      </div>
    </div>
  );
};

export default MypageHeader;
