import { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { WorldList, ChipsGroup, NormalButton } from "@components/index";
import { usehttp } from "@hooks/index";
import { getWorldList } from "@apis/world";
import { LoginState, RootState } from "@store/index";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "./home.scss";

const categories: { name: string; path: string }[] = [
  { name: "추천", path: "recommend" },
  { name: "인기", path: "popular" },
  { name: "신규", path: "new" },
  { name: "즐겨찾기", path: "favorite" },
  { name: "최근 참여한", path: "recent" },
  { name: "My 월드", path: "myworld" },
];

const Home = () => {
  const [page, setpage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [list, setList] = useState([]);

  const [category, setCategory] = useState(0);
  const [hide, setHide] = useState(false);

  const login: LoginState = useSelector((state: RootState) => state.login);

  const { sendRequest } = usehttp();

  // get world list 월드 리스트 가져오기
  const _getWorldList = async () => {
    const { data: result } = await sendRequest(getWorldList, {
      category: "OFFICIAL",
      sort: category === 1 ? "LIKE" : category === 2 ? "NEW" : null,
      page: page,
      limit: limit,
      select: category === 3 ? "FAVORITE" : category === 4 ? "RECENT_PLAY" : category === 5 ? "MY_WORLD" : null,
    });

    if (result?.error) {
      window.alert(result.code);
      return;
    } else {
      if (page > 1) {
        setList([...list, ...result.posts]);
      } else {
        setList([...result.posts]);
      }
      if (page * limit > result.count) {
        setHide(true);
      } else {
        setHide(false);
      }
    }
  };

  useEffect(() => {
    _getWorldList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, category]);

  return (
    <div className="container home">
      <div className="home__inner">
        <ChipsGroup
          data={login.login ? categories : categories.slice(0, 3)}
          nowCategory={category}
          setNowCategory={setCategory}
        />

        <div className="board__creator">
          <div className="box">
            {list.map((data: any, idx: number) => (
              <WorldList type="creator" data={data} key={idx} />
            ))}
          </div>
          {!hide && (
            <NormalButton
              title={"더보기"}
              buttonStyle="box"
              size="md"
              hierarchy={"secondary_gray"}
              icon="trailing"
              iconSize={20}
              iconType="chevron-down"
              iconColor="#1D2939"
              onClick={() => {
                setpage(page + 1);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Home;
