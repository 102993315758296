import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Icon from "../../../../shared/Icon";
import { RootState, gnbSetting, mymenuSetting, alertModalSetting, AlertModalState } from "@store/index";
import classNames from "classnames";
import { useState } from "react";
import { REACT_APP_URL_ZEMIVERSE, REACT_APP_URL_STUDIO, REACT_APP_URL_MARKET } from "@utils/index";

import "./locationLogo.scss";

const LocationLogo = () => {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const alert: AlertModalState = useSelector((state: RootState) => state.alert);

  const List = [
    {
      id: 0,
      title: "재미버스",
      url: REACT_APP_URL_ZEMIVERSE,
    },
    {
      id: 1,
      title: "재미스튜디오",
      url: REACT_APP_URL_STUDIO,
    },
    {
      id: 2,
      title: "재미마켓",
      url: REACT_APP_URL_MARKET,
    },
  ];

  const [open, setOpen] = useState(false);
  const [selectNow, setSelectNow] = useState(0);

  return (
    <div
      className={classNames("logo", { on: open })}
      onClick={() => {
        setOpen(!open);
        dispatch(gnbSetting(false));
        dispatch(mymenuSetting(false));
        navigate(`/`);
      }}
    >
      <p>{t("zemiverse")}</p>
      <div className="icon_toggle">
        <Icon className="chevron-down" icon="chevron-down" size={20} color="#475467" />
      </div>
      <ul className="list-location" onClick={(e) => e.stopPropagation()}>
        {List.map((data: any, idx: number) => (
          <li
            className={classNames({ on: selectNow === data.id })}
            onClick={() => {
              setSelectNow(data.id);
              if (data.id === 0) {
                navigate(`/`);
              } else if (data.id === 2) {
                dispatch(alertModalSetting({ open: !alert.open, title: "준비중입니다." }));
              } else {
                // window.location.href = data.url;
                window.location.assign(data.url);
              }
              setOpen(false);
            }}
            key={idx}
          >
            <div className="image"></div>
          </li>
        ))}
        <li
          className="button-close"
          onClick={() => {
            setOpen(false);
          }}
        >
          <Icon className="x" icon="x" size={24} color="#101828" />
        </li>
      </ul>
    </div>
  );
};

export default LocationLogo;
