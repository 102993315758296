import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import creatorPicture from "@assets/images/creator-picture.png";
import Icon from "@shared/Icon";
import Border from "@components/common/Border/Border";
import { NormalButton } from "@components/common";
import { updateDateToString } from "@shared/utils/index";
import useWebviewDetail from "@hooks/webview/useWebviewDetail";
import { Viewer } from "@toast-ui/react-editor";
import { sendMessageMapGUID } from "@components/Webview/unitySender";
import "@toast-ui/editor/dist/toastui-editor-viewer.css";

type WebviewDetail = { [key: string]: any };

const addComma = (num: number) => num.toLocaleString("ko-KR");

const WebviewWorldDetail = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { fetchWebviewDetail, webviewDetail } = useWebviewDetail();
  const { i18n } = useTranslation();
  const { title, mapGUID, content, summary, createdAt, playCount, thumbnailImageUrl, updatedAt, user }: WebviewDetail =
    webviewDetail;

  useEffect(() => {
    fetchWebviewDetail({ id: state.id });
  }, []);

  const handleOnPlay = () => {
    console.log(mapGUID);
    if (window.vuplex) {
      sendMessageMapGUID(mapGUID);
    } else {
      window.addEventListener("vuplexready", sendMessageMapGUID);
    }
  };
  // console.log(webviewDetail);
  return (
    <div className="webview-detail">
      <div className="detail-main">
        <div className="detail-title">
          <div className="detail-image">
            <img src={thumbnailImageUrl && thumbnailImageUrl[0]} alt="world thumbnail" />
          </div>
          <div className="detail-text">
            <h2>{title}</h2>
            <div className="user-info">
              <div className="user-img">
                <img src={creatorPicture} alt="creator" />
              </div>
              <div className="user-text">
                <p>{user?.nickname ? user?.nickname : "크리에이터닉네임"}</p>
                <p>Creator</p>
              </div>
            </div>
            <div className="play-count">
              <div className="play-icon">
                <Icon className="play-circle-fill" icon="play-circle-fill" size="100%" color="#667085" />
              </div>
              <span>{playCount && addComma(playCount)}</span>
            </div>
            <Border width="100%" height={1} />
            <p className="world-explanation">{summary}</p>
          </div>
        </div>
        <div className="detail-content">
          <h2>월드 소개</h2>
          <div className="content-inner">
            {content && (
              <Viewer
                initialValue={content || ""}
                customHTMLRenderer={{
                  htmlBlock: {
                    iframe(node) {
                      return [
                        {
                          type: "openTag",
                          tagName: "iframe",
                          outerNewLine: true,
                          attributes: node.attrs,
                        },
                        { type: "html", content: node.childrenHTML },
                        { type: "closeTag", tagName: "iframe", outerNewLine: false },
                      ];
                    },
                  },
                }}
              />
            )}
          </div>
          <ul className="world-date">
            <li>
              <span>출시일</span>
              <span>{updateDateToString(createdAt, "yyyy.MM.dd", i18n.language)}</span>
            </li>
            <li>
              <span>업데이트</span>
              <span>{updateDateToString(updatedAt, "yyyy.MM.dd", i18n.language)}</span>
            </li>
          </ul>
        </div>
      </div>
      <div className="detail-side">
        <div className="cancel-button" onClick={() => navigate(-1)}>
          <Icon icon="x" size="53%" color="#fff" />
        </div>
        <NormalButton
          hierarchy="app-play"
          buttonClassName="play-button"
          icon="leading"
          iconType="play-fill"
          iconSize="50%"
          iconColor="#fff"
          buttonStyle="round"
          size="lg"
          onClick={() => {
            handleOnPlay();
          }}
        />
      </div>
    </div>
  );
};

export default WebviewWorldDetail;
