import {Outlet} from 'react-router-dom';
import { ContentHeader } from '@components/index';

const SubLayout = () => {
  return (
    <>
      { /* content Header */ }
      <ContentHeader />

      {/* content Body */}
      <Outlet />

      {/* content Footer */}
      {/* <ContentFooter230801/> */}
    </>
  )
}

export default SubLayout;