import { http } from "./http";
import qs from "qs";

// ------------------------------- list -------------------------------

// get news list
export const getNewsList = (data: {
  path: string;
  comparison?: string;
  category?: string;
  title?: string;
  content?: string;
  page?: number;
  limit?: number;
  year?: number;
  isOpen?: boolean;
}) => {
  if (data.path === "notice") {
    return http.get<any>(
      `/news/notice/list/${data.category}${qs.stringify(
        {
          title: data.title,
          content: data.content,
          page: data.page,
          limit: data.limit,
        },
        { arrayFormat: "repeat", skipNulls: true, addQueryPrefix: true }
      )}`
    );
  } else if (data.path === "update") {
    return http.get<any>(
      `/news/update/list${qs.stringify(
        {
          year: data.year,
          page: data.page,
          limit: data.limit,
        },
        { arrayFormat: "repeat", skipNulls: true, addQueryPrefix: true }
      )}`
    );
  } else if (data.path === "event") {
    return http.get<any>(
      `/news/event/list/ALL${qs.stringify(
        {
          isOpen: data.isOpen,
          title: data.title,
          content: data.content,
          page: data.page,
          limit: data.limit,
        },
        { arrayFormat: "repeat", skipNulls: true, addQueryPrefix: true }
      )}`
    );
  }
};

// get community list
export const getCommunityList = (data: {
  category: string;
  sort: string;
  title: string;
  content: string;
  page: number;
  limit: number;
}) => {
  return http.get<any>(
    `/community/list/${data.category}${qs.stringify(
      {
        sort: data.sort,
        title: data.title,
        content: data.content,
        page: data.page,
        limit: data.limit,
      },
      { arrayFormat: "repeat", skipNulls: true, addQueryPrefix: true }
    )}`
  );
};

// ------------------------------- detail -------------------------------

// get detail
export const getDetail = (data: { path: string; postId: string }) => {
  return http.get<any>(
    `${data.path === "community" ? "" : "/news"}/${data.path}/detail${qs.stringify(
      {
        postId: data.postId && data.postId,
      },
      { arrayFormat: "repeat", skipNulls: true, addQueryPrefix: true }
    )}`
  );
};

// ------------------------------- new post / patch -------------------------------

// post new article
export const postNewArticle = (data: {
  category: string;
  board: string;
  title: string;
  content: string;
  thumbnailImageUrl: string;
}) => {
  const requestData = {
    board: data.board,
    title: data.title,
    content: data.content,
    thumbnailImageUrl: data.thumbnailImageUrl,
  };

  return http.post<any, null>(`/community/post/${data.category}`, requestData);
};

// put article
export const putArticle = (data: { postId: string; title: string; content: string; thumbnailImageUrl: string }) => {
  return http.put<any, null>(`/community/update`, data);
};

// ------------------------------- delete -------------------------------

// delete article
export const deleteArticle = (data: { postId: string }) => {
  return http.delete<any>(
    `/community/delete${qs.stringify(
      {
        postId: data.postId,
      },
      { arrayFormat: "repeat", skipNulls: true, addQueryPrefix: true }
    )}`
  );
};

// ------------------------------- like -------------------------------

// post like detail
export const postLike = (data: { entityType: string; entityId: string }) => {
  return http.post<any, null>(`/community/like`, data);
};

// ------------------------------- comment -------------------------------

// get comment list
export const getCommentList = (data: { postId: string }) => {
  return http.get<any>(
    `/community/comment${qs.stringify(
      { postId: data.postId },
      { arrayFormat: "repeat", skipNulls: true, addQueryPrefix: true }
    )}`
  );
};

// post comment
export const postComment = (data: { postId: string; content: string }) => {
  return http.post<any, null>(`/community/comment`, data);
};

// patch comment
export const patchComment = (data: { commentId: string; content: string }) => {
  return http.patch<any, null>(`/community/comment`, data);
};

// delete comment
export const deleteComment = (data: { commentId: string }) => {
  return http.delete<any>(
    `/community/comment${qs.stringify(
      {
        commentId: data.commentId,
      },
      { arrayFormat: "repeat", skipNulls: true, addQueryPrefix: true }
    )}`
  );
};

// ------------------------------- reply -------------------------------

// get reply list
export const getReplyList = (data: { commentId: string }) => {
  return http.get<any>(
    `/community/reply${qs.stringify(
      { commentId: data.commentId },
      { arrayFormat: "repeat", skipNulls: true, addQueryPrefix: true }
    )}`
  );
};

// post reply
export const postReply = (data: { postId: string; commentId: string; content: string }) => {
  return http.post<any, null>(`/community/reply`, data);
};

// patch reply
export const patchReply = (data: { replyId: string; content: string }) => {
  return http.patch<any, null>(`/community/reply`, data);
};

// delete reply
export const deleteReply = (data: { replyId: string }) => {
  return http.delete<any>(
    `/community/reply${qs.stringify(
      {
        replyId: data.replyId,
      },
      { arrayFormat: "repeat", skipNulls: true, addQueryPrefix: true }
    )}`
  );
};

// ------------------------------- report -------------------------------

// post reply
export const postReport = (data: { entityType: string; entityId: string; reason: string; content: string }) => {
  return http.post<any, null>(`/community/complain`, data);
};
