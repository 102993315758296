import { useState, useCallback } from "react";

const useHttp = () => {
  const [isLoading, setIsLoading] = useState(false);
  // const [error, setError] = useState<unknown>(null);
  // TODO: 공통 에러처리 나중에 하기

  const sendRequest = useCallback(async (applyData: any, param: any) => {
    setIsLoading(true);
    // setError(null);

    try {
      // console.log(param);
      const data = await applyData(param);
      // console.log(data);
      return data;
    } catch (err: unknown) {
      // console.error(err);
      // setError(err || "Something went wrong!");
      return null;
    } finally {
      setIsLoading(false);
    }
  }, []);

  return {
    isLoading,
    // error,
    sendRequest,
  };
};

export default useHttp;
