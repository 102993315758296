import Icon from "@shared/Icon";
import { useState } from "react";

const RecentKeyword = () => {
  const [showItem, setShowItem] = useState(true);

  return (
    <>
      {showItem && (
        <li>
          <div className="recent-keyword-wrap">
            <div className="timer-icon">
              <Icon icon="timer" size="100%" color="#98A2B3" />
            </div>
            <span>이전 검색어</span>
          </div>
          <div className="cancel-icon">
            <Icon
              icon="x"
              size="100%"
              color="#101828"
              onClick={() => {
                setShowItem(false);
              }}
            />
          </div>
        </li>
      )}
    </>
  );
};

export default RecentKeyword;
