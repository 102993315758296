import { Outlet, useLocation, useParams } from "react-router-dom";
import { KioskHeader } from "@components/index";

import "./kioskLayout.scss";
import { useEffect, useState } from "react";

const KioskLayout = () => {
  const location = useLocation();
  const { id } = useParams();
  const [headerType, setHeaderType] = useState("title");

  useEffect(() => {
    if (window.location.pathname === "/kiosk") {
      setHeaderType("title");
    } else if (window.location.pathname === "/kiosk/order") {
      // 다른 경로에 따라 다른 headerType을 설정할 수 있음
      setHeaderType("back");
    } else if (window.location.pathname === "/kiosk/complete") {
      setHeaderType("close");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <div className="webview kiosk container">
      <KioskHeader type={headerType} />
      <Outlet />
    </div>
  );
};

export default KioskLayout;
