import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useGoogleLogin } from "@react-oauth/google";

import { loginSetting, profileSetting } from "@store/index";
import { setCookie, REACT_APP_COOKIE } from "@utils/index";
import { usehttp } from "@hooks/index";
import { postLogin, getProfile } from "@apis/login";

import "./loginButton.scss";
import { AUTH_COOKIE } from "@shared/constants";

const GoogleLoginButton = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { sendRequest } = usehttp();

  // @react-oauth -> google login
  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      _postLogin(tokenResponse.access_token);
    },
    onError: (errorResponse) => console.log("error", errorResponse),
  });

  // after google login -> get token
  const _postLogin = async (accessToken) => {
    const { data: result } = await sendRequest(postLogin, {
      // client: "WEB",
      platform: 1, // facebook = 1, google = 2, apple = 3 -> 이전 버전(개발) // google = 1, apple = 2, facebook = 3 -> 현재(로컬)
      access_token: accessToken,
    });

    console.log(result);

    if (result?.error) {
      window.alert(result.code);
      return;
    } else {
      setCookie(AUTH_COOKIE, result?.token, 1);
      if (result?.signUp) {
        navigate(`/register`);
      } else {
        _getProfile();
      }
    }
  };

  const _getProfile = async () => {
    const { data: result } = await sendRequest(getProfile, {});

    console.log(result);

    if (result?.error) {
      window.alert(result.code);
      document.cookie = `${AUTH_COOKIE}=;expires = Thu, 01 Jan 1970 00:00:00 UTC;path=/;domain=${REACT_APP_COOKIE};`;
      return;
    } else {
      dispatch(
        profileSetting({
          nickname: result.nickname,
          picture: result.profileImageUrl,
          sns: result.platform === 1 ? "google" : result.platform === 2 ? "apple" : "facebook",
          email: result.email,
          id: result.id,
        })
      );
      dispatch(loginSetting(true));
      navigate(-1);
    }
  };

  return (
    <div className="login-button google-login" onClick={() => googleLogin()}>
      <i className="icon google"></i>
      <p>{t("login_sign_with")}</p>
    </div>
  );
};

export default GoogleLoginButton;
