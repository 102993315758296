import { Outlet, useLocation } from "react-router-dom";
import { KioskHeader } from "@components/index";
import { useEffect, useState } from "react";
import "../KioskLayout/kioskLayout.scss";
import "./hotelLayout.scss";

const HotelLayout = () => {
  const location = useLocation();
  const [headerType, setHeaderType] = useState("title");

  useEffect(() => {
    if (window.location.pathname === "/hotel") {
      setHeaderType("hotel-title");
    } else if (window.location.pathname === "/hotel/order") {
      setHeaderType("hotel-order");
    } else if (window.location.pathname === "/hotel/detail") {
      setHeaderType("hotel-detail");
    } else if (window.location.pathname === "/hotel/complete") {
      setHeaderType("close");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <div className="webview kiosk container">
      <KioskHeader type={headerType} />
      <Outlet />
    </div>
  );
};

export default HotelLayout;
