import {Outlet} from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { MypageHeader, WithdrawModal } from '@components/index';

import { RootState, ModalState, AlertModalState, TermsState, modalOpenSetting } from "@store/index";

const MypageLayout = () => {

  const modal:ModalState = useSelector((state: RootState) => state.modal);

  return (
    <>
      { /* content Header */ }
      <MypageHeader />

      {/* content Body */}
      <Outlet />
      
    </>
  )
}

export default MypageLayout;