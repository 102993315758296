const GNB_SETTING = "gnb/GNB_SETTING" as const;

type GNBAction = ReturnType<typeof gnbSetting>;

export const gnbSetting = (diff: boolean) => ({
  type: GNB_SETTING,
  payload: diff,
});

export type GNBState = {
  open: boolean;
};

const initialState: GNBState = {
  open: false,
};

function gnb(state: GNBState = initialState, action: GNBAction): GNBState {
  switch (action.type) {
    case GNB_SETTING:
      return { ...state, open: action.payload };
    default:
      return state;
  }
}

export default gnb;
