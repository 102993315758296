const MYPAGE_GNB_SETTING = 'gnb/MYPAGE_GNB_SETTING' as const;

type MypageGnbAction =
  | ReturnType<typeof mypageGnbSetting>
;

export const mypageGnbSetting = (diff: boolean) => ({
  type: MYPAGE_GNB_SETTING,
  payload: diff
});

export type MypageGnbState = {
  open: boolean;
};

const initialState: MypageGnbState = {
  open: false,
};

function mypageGnb(
  state: MypageGnbState = initialState,
  action: MypageGnbAction
): MypageGnbState {
  switch (action.type) {
    case MYPAGE_GNB_SETTING:
      return { ...state, open: action.payload };
    default:
      return state;
  }
}

export default mypageGnb;