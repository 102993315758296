import { useSelector, useDispatch } from "react-redux";

import { useTranslation } from "react-i18next";

import { RootState, AlertModalState, alertModalSetting } from "@store/index";

import AppleLogin from "react-apple-login";

import "./loginButton.scss";

const AppleLoginButton = () => {
  const { t } = useTranslation();

  const alert: AlertModalState = useSelector((state: RootState) => state.alert);
  const dispatch = useDispatch();

  return (
    <div
      className="login-button apple-login"
      onClick={() => {
        dispatch(alertModalSetting({ open: !alert.open, title: "준비중입니다." }));
      }}
    >
      <i className="icon apple"></i>
      <p>Sign in with Apple</p>

      {/* <AppleLogin
        clientId={"com.react.apple.login"}
        redirectURI={"https://redirectUrl.com"}
        responseType={"code"}
        responseMode={"query"}
        usePopup
      /> */}
    </div>
  );
};

export default AppleLoginButton;
