import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { PageTop, NormalButton, InputBox } from "@components/index";
import {
  RootState,
  AlertModalState,
  alertModalSetting,
  LoginState,
  profileSetting,
  modalSetting,
  ModalState,
  modalRegisterSetting,
  modalOpenSetting,
} from "@store/index";
import { usehttp } from "@hooks/index";
import { capitalize } from "@utils/index";
import { getImgUpload } from "@apis/img";
import { getProfile, putProfile } from "@apis/login";

import "./settingProfile.scss";

/**
 * setting-profile - 계정 설정 - 프로필 설정
 */

const SettingProfile = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const login: LoginState = useSelector((state: RootState) => state.login);
  const modal: ModalState = useSelector((state: RootState) => state.modal);
  const alert: AlertModalState = useSelector((state: RootState) => state.alert);
  const { sendRequest } = usehttp();

  // const SocialList = [
  //   { id: 0, title: "소셜 선택" },
  //   { id: 1, title: "유튜브", link: "https://www.youtube.com/" },
  //   { id: 2, title: "인스타", link: "https://www.instagram.com/" },
  //   { id: 3, title: "틱톡", link: "https://www.tiktok.com/" },
  // ];

  // 프로필 사진
  const [profileImg, setProfileImg] = useState("");
  const [profileImgUrl, setProfileImgUrl] = useState("");
  const [profileFile, setProfileFile] = useState<File>();

  // 배너 이미지
  const [bannerState, setBannerState] = useState(1); // 0 일 경우 이미지 직접 등록, 1일 경우 컬러(default = white)
  const [bannerImgUrl, setBannerImgUrl] = useState("");
  const [bannerFile, setBannerFile] = useState<File>();
  const [bannerFileName, setBannerFileName] = useState("");

  const [color, setColor] = useState("#ffffff");
  const [colorString, setColorString] = useState("ffffff");

  const [nickname, setNickname] = useState("");
  // const [useAvatar, setUseAvatar] = useState(false);
  const [email, setEmail] = useState("");
  const [summary, setSummary] = useState("");

  // SNS
  const [sns, setSns] = useState({ youtube: "", instagram: "", tiktok: "" });

  // 프로필 사진 바꿨을 떄
  const saveProfileImg = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.currentTarget;
    const files = (target.files as FileList)[0];
    setProfileImg(URL.createObjectURL(files)); // blob
    setProfileFile(files); // file
    // _getProfileUpload();
    e.target.value = "";
  };

  const saveBannerImg = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.currentTarget;
    const files = (target.files as FileList)[0];
    // setBannerImg(URL.createObjectURL(files)); //이미지 미리보기일 경우
    setBannerFile(files);
    setBannerFileName(files.name);
    // _handleBannerUpsload();
    e.target.value = "";
  };

  // _getProfileUpload/_handleBannerUpload를 통해 나온 S3 주소에 이미지를 업로드한다.
  const uploadImageToS3 = async (url: string, file: File, type: string) => {
    await axios
      .put(url, file)
      .then((response) => {
        console.log(`response 확인 >>>>>>>`, response);
        if (response.status === 200) {
          if (type === "profile") {
            setProfileImgUrl(url.substring(0, url.indexOf("?")));
          } else if (type === "background") {
            setBannerImgUrl(url.substring(0, url.indexOf("?")));
          }
        }
      })
      .catch((error) => console.error(error));
  };

  // get profile upload - 프로필 저장 시 uploadImageToS3를 통해 이미지를 S3로 올려 url을 얻는다.
  const _getProfileUpload = async () => {
    const { data: result } = await sendRequest(getImgUpload, {
      type: "profile",
    });

    console.log(`_getProfileUpload`, result);

    if (result?.error) {
      window.alert(result.code);
    } else {
      if (profileFile) {
        uploadImageToS3(result?.url, profileFile, "profile");
      }
    }
  };

  // get banner upload - 배너 이미지 저장 시 uploadImageToS3를 통해 이미지를 S3로 올려 url을 얻는다.
  const _getBannerUpload = async () => {
    const { data: result } = await sendRequest(getImgUpload, {
      type: "background",
    });

    console.log(`_getBannerUpload`, result);

    if (result?.error) {
      window.alert(result.code);
    } else {
      if (bannerFile) {
        uploadImageToS3(result?.url, bannerFile, "background");
      }
    }
  };

  // 프로필 변경 후 저장하기
  const _putProfile = async () => {
    const { data: result } = await sendRequest(putProfile, {
      nickname: nickname,
      profileImageUrl: profileImgUrl && profileImgUrl.toString(),
      summary: summary,
      backgroundColor: color,
      backgroundImageUrl: bannerImgUrl && bannerImgUrl.toString(),
      background: bannerState === 0 ? "IMAGE" : "COLOR", // COLOR : 색상 사용, IMAGE : 이미지 사용
      sns: {
        youtube: sns.youtube,
        instagram: sns.instagram,
        tiktok: sns.tiktok,
      },
    });

    console.log(`_putProfile`, result);

    if (result?.error) {
      window.alert(result.code);
    } else {
      dispatch(alertModalSetting({ open: !alert.open, title: t("프로필이 수정 되었습니다.") }));
      setTimeout(() => {
        dispatch(alertModalSetting({ open: false, title: "" }));
      }, 2000);
      dispatch(modalOpenSetting(false));
      _getProfile();
    }
  };

  // 초기화 및 프로필 변경 후 프로필 다시 불러오기
  const _getProfile = async () => {
    const { data: result } = await sendRequest(getProfile, {
      type: "background",
    });

    console.log(`_getProfile`, result);

    if (result?.error) {
      window.alert(result.code);
    } else {
      dispatch(
        profileSetting({
          nickname: result?.nickname,
          picture: result?.profileImageUrl,
          sns: result?.platform === 1 ? "google" : result?.platform === 2 ? "apple" : "facebook",
          email: result?.email,
          id: result?.id,
        })
      );
      setBannerState(result?.backgroundType === "COLOR" ? 1 : 0);
      if (
        result?.backgroundColor !== null &&
        result?.backgroundColor !== undefined &&
        result?.backgroundColor !== "undefined" &&
        result?.backgroundColor !== ""
      ) {
        setColor(result?.backgroundColor);
        setColorString(String(result?.backgroundColor).substring(1, 7));
      } else {
        setColor(`#ffffff`);
        setColorString(`ffffff`);
      }
      setProfileImg(result?.profileImageUrl);
      setBannerFileName(result?.backgroundImageUrl);
      setNickname(result?.nickname);
      setEmail(result?.email);
      setSummary(result?.summary);
      if (result?.sns) {
        setSns({ youtube: result?.sns.youtube, instagram: result?.sns.instagram, tiktok: result?.sns.tiktok });
      }
    }
  };

  // 프로필 변경 저장시 활성화
  const _handleProfileUpdate = () => {
    if (profileFile) {
      _getProfileUpload();
    }
    if (bannerFile) {
      _getBannerUpload();
    }
    _putProfile();

    console.log(`profile 정보`, login);
    // navigate(-1);
  };

  useEffect(() => {
    _getProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let a = colorString.padStart(7, `#`);
    setColor(a);
  }, [colorString]);

  return (
    <div className="container setting-profile">
      <div className="setting-profile__inner">
        <PageTop title={t("mypage_title")} />

        <div className="content1">
          <div className="left">
            <p className="title">프로필</p>
            <div className="bottom">
              <img
                className="profile"
                id="profileImg"
                src={
                  profileImg !== undefined && profileImg !== null && profileImg !== `undefined` && profileImg !== ""
                    ? profileImg
                    : `/images/avatar2.png`
                }
                alt=""
              />
              <div className="right">
                <p>프로필사진</p>
                <div className="button-upload">
                  <label className="button" htmlFor="file">
                    {t("mypage_table_button")}
                  </label>
                  <input className="hide" type="file" accept="image/*" onChange={saveProfileImg} id="file" />
                </div>
              </div>
            </div>
          </div>
          <div className="right">
            <p className="title">배너이미지</p>
            <div className="bottom">
              <div
                className={classNames("box-select", { on: bannerState === 0 })}
                onClick={() => {
                  setBannerState(0);
                }}
              >
                <div className="top">
                  <div className="button-radio"></div>
                  <div className="right">
                    <p className="title">직접등록</p>
                    <p className="description">마이페이지 대시보드 상단에 표시됩니다.</p>
                  </div>
                </div>
                <div className="bottom">
                  <p>가로 2048px 세로 1152px 이상, 3MB 이하의 jpg, png, gif 이미지를 사용하세요.</p>
                  <div className="box">
                    <p>{bannerFileName}</p>
                    <div className="right">
                      <div
                        className={classNames("button--upload", {
                          empty: bannerFileName === null || bannerFileName === undefined || bannerFileName === "",
                        })}
                      >
                        <label className="button" htmlFor="bannerFile">
                          {bannerFileName === null || bannerFileName === undefined || bannerFileName === ""
                            ? "찾아보기"
                            : "변경"}
                        </label>
                        <input
                          className="hide"
                          type="file"
                          accept="image/*"
                          onChange={(e) => saveBannerImg(e)}
                          id="bannerFile"
                        />
                      </div>
                      {!(bannerFileName === null || bannerFileName === undefined || bannerFileName === "") && (
                        <NormalButton
                          title="삭제"
                          size="sm"
                          hierarchy="secondary_gray"
                          buttonStyle="round"
                          onClick={() => {
                            setBannerFile(null);
                            setBannerFileName("");
                            setBannerImgUrl("");
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={classNames("box-select", { on: bannerState === 1 })}
                onClick={() => {
                  setBannerState(1);
                }}
              >
                <div className="top">
                  <div className="button-radio"></div>
                  <div className="right">
                    <p className="title">컬러</p>
                    <p className="description">마이페이지 대시보드 상단에 표시됩니다.</p>
                  </div>
                </div>
                <div className="bottom">
                  <input
                    id="primary_color"
                    type="color"
                    value={color}
                    onChange={(e) => {
                      // setColor(e.target.value);
                      setColorString(e.target.value.substring(1, 7));
                    }}
                  />
                  <p>#</p>
                  <input
                    type="text"
                    value={colorString}
                    onChange={(e) => {
                      setColorString(e.target.value);
                    }}
                    maxLength={6}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="content2">
          <div className="box">
            <p className="title">닉네임</p>
            <InputBox
              name="nickname"
              value={nickname}
              onChange={(e) => {
                setNickname(e.target.value);
              }}
              placeholder={t("mypage_placeholder_nickname")}
              maxLength={10}
            />
            {/* <CheckBox
              name="terms-modal"
              onChange={() => setUseAvatar(!useAvatar)}
              checked={useAvatar}
              terms={useAvatar}
              label="프로필 대표 아바타 사용"
            /> */}
          </div>
          <div className="box">
            <p className="title">아이디</p>
            <InputBox
              name="nickname"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={t("mypage_placeholder_email")}
              disabled
            />
            <div className={classNames("box_platform", login.sns)}>
              <div className="icon"></div>
              <p>
                {capitalize(login.sns)}
                {t("mypage_logged_in")}
              </p>
            </div>
          </div>
        </div>

        <div className="content3">
          <p className="title">한 줄 소개</p>
          <input
            type="text"
            value={summary}
            onChange={(e) => setSummary(e.target.value)}
            placeholder="등록된 나만의 한줄 소개가 노출됩니다."
            maxLength={40}
          />
        </div>

        <div className="content4">
          <p className="title">소셜 채널 등록</p>
          {/* <div className="list-social add">
            <SelectBox name="category" data={SocialList} selectData={selectSocial} classification />
            <div className="box-url">
              <p>{selectSocial.value.link}</p>
              <input type="text" placeholder="ID를 입력해 주세요." />
            </div>
            <NormalButton title={"추가"} size="md" hierarchy="primary_line" buttonStyle="box" onClick={() => {}} />
          </div> */}
          {["유튜브", "인스타그램", "틱톡"].map((data: any, idx: number) => (
            <div className="list-social" key={idx}>
              <p className="social">{data}</p>
              <div className="box-url">
                <p>
                  {idx === 0
                    ? "https://www.youtube.com/"
                    : idx === 1
                    ? "https://www.instagram.com/"
                    : "https://www.tiktok.com/"}
                </p>
                <input
                  type="text"
                  placeholder="ID를 입력해 주세요."
                  value={idx === 0 ? sns.youtube : idx === 1 ? sns.instagram : sns.tiktok}
                  onChange={(e) =>
                    idx === 0
                      ? setSns({ ...sns, youtube: e.target.value })
                      : idx === 1
                      ? setSns({ ...sns, instagram: e.target.value })
                      : setSns({ ...sns, tiktok: e.target.value })
                  }
                />
              </div>
            </div>
          ))}
        </div>

        <div className="content5">
          <NormalButton
            title={"회원 탈퇴"}
            size="md"
            hierarchy="secondary_color"
            buttonStyle="round"
            onClick={() => {
              dispatch(modalRegisterSetting({ open: !modal.open, type: "withdraw" }));
            }}
          />
          <p className="description">회원 탈퇴 시 재미버스 홈페이지 회원 계정이 탈퇴 됩니다.</p>
        </div>

        <div className="content6">
          <NormalButton
            title={t("mypage_button2")}
            size="md"
            hierarchy="secondary_gray"
            buttonStyle="box"
            onClick={() => {
              navigate(-1);
            }}
          />
          <NormalButton
            title={t("mypage_button1")}
            size="md"
            hierarchy="primary"
            buttonStyle="box"
            onClick={() => {
              dispatch(
                modalSetting({
                  open: !modal.open,
                  type: "success",
                  title: t("mypage_save"),
                  description: t("mypage_confirm_save"),
                  confirmButton: t("mypage_save"),
                  confirmStyle: "primary",
                  onConfirm: _handleProfileUpdate,
                  alert: false,
                })
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SettingProfile;
