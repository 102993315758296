import { useState } from "react";
import useHttp from "@hooks/usehttp";
import { getWebviewDetail } from "@apis/webview";
import { ENTER_LAND_DETAIL, TRAVEL_LAND_DETAIL } from "@apis/placeholder-data";

const useWebviewDetail = () => {
  const { isLoading, sendRequest } = useHttp();
  const [webviewDetail, setWebviewDetail] = useState({});

  const fetchWebviewDetail = async ({ id }) => {
    if (!isLoading) {
      if (id === "123") {
        setWebviewDetail(ENTER_LAND_DETAIL);
      } else if (id === "124") {
        setWebviewDetail(TRAVEL_LAND_DETAIL);
      } else {
        const data = await sendRequest(getWebviewDetail, { id });
        const detailResult = data?.data;
        if (detailResult) {
          setWebviewDetail(detailResult);
        }
      }
    }
  };
  return { fetchWebviewDetail, isLoading, webviewDetail };
};

export default useWebviewDetail;
