import { useSelector, useDispatch } from "react-redux";

import { RootState, AlertModalState, alertModalSetting } from "@store/index";
import FacebookLogin from "react-facebook-login";
// import TiSocialFacebookCircular from 'react-icons/lib/ti/social-facebook-circular';

import "./loginButton.scss";

const responseFacebook = (response) => {
  // console.log(response);
};

const FacebookLoginButton = () => {
  const alert: AlertModalState = useSelector((state: RootState) => state.alert);
  const dispatch = useDispatch();

  return (
    <div
      className="login-button facebook-login"
      onClick={() => {
        dispatch(alertModalSetting({ open: !alert.open, title: "준비중입니다." }));
      }}
    >
      <i className="icon facebook"></i>
      <p>Sign in with Facebook</p>
      {/* <FacebookLogin
        appId="1088597931155576"
        autoLoad={true}
        fields="name,email,picture"
        callback={responseFacebook}
        cssClass="my-facebook-button-class"
      /> */}
    </div>
  );
};

export default FacebookLoginButton;
