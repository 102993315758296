import { useEffect, useState } from "react";
import classNames from "classnames";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import axios from "axios";
import { REACT_APP_SERVER_URL } from "@shared/utils";

import {
  RootState,
  PathState,
  ModalState,
  LoginState,
  modalSetting,
  alertModalSetting,
  AlertModalState,
  modalOpenSetting,
  modalReportSetting,
} from "@store/index";
import { PageTop, BoardList, NormalButton, CommentList, CommentBox } from "@components/index";
import { boardTitle, getIdPath } from "@shared/utils";
import { usehttp } from "@hooks/index";
import { deleteArticle, getCommentList, getDetail, postComment, postLike } from "@apis/board";

import "@toast-ui/editor/dist/toastui-editor-viewer.css";
import { Viewer } from "@toast-ui/react-editor";

import "./detail.scss";

const Detail = () => {
  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { sendRequest } = usehttp();

  const [inputValue, setInputValue] = useState("");
  const [likeOn, setLikeOn] = useState(false);
  const [like, setLike] = useState(0);
  const [pageId, setPageId] = useState("");
  const [detailData, setDetailData] = useState<any>([]);
  const [commentList, setCommentList] = useState([]);

  const path: PathState = useSelector((state: RootState) => state.path);
  const modal: ModalState = useSelector((state: RootState) => state.modal);
  const alert: AlertModalState = useSelector((state: RootState) => state.alert);
  const login: LoginState = useSelector((state: RootState) => state.login);

  let pathType = ["notice", "notice2", "update", "event"].includes(path.nowPath)
    ? path.nowPath
    : ["free-board", "tip-board", "screenshot-board"].includes(path.nowPath)
    ? `community`
    : ``;

  // get detail
  const _getDetail = async () => {
    const { data: result } = await sendRequest(getDetail, {
      path: pathType === "update" || pathType === "notice2" ? "notice" : pathType,
      postId: pageId,
    });

    console.log(`_getDetail`, result);

    if (result?.error) {
      window.alert(result.code);
    } else {
      setDetailData(result);
      setLike(result?.likeByMe);
    }
  };

  // like detail
  const _postLike = async () => {
    const { data: result } = await sendRequest(postLike, {
      entityType: "POST",
      entityId: pageId,
    });

    console.log(`_postLike`, result);

    if (result?.error) {
      window.alert(result.code);
    } else {
      _getCommentList();
      _getDetail();
    }
  };

  // get Comment List
  const _getCommentList = async () => {
    const { data: result } = await sendRequest(getCommentList, {
      postId: pageId,
    });

    console.log(`_getCommentList`, result);

    if (result?.error) {
      window.alert(result.code);
    } else {
      setCommentList(result?.comments);
    }
  };

  // after google login -> get token
  const _postComment = async () => {
    const { data: result } = await sendRequest(postComment, {
      postId: pageId,
      content: inputValue,
    });

    console.log(`_postComment`, result);

    if (result?.error) {
      window.alert(result.code);
    } else {
      setInputValue("");
      _getCommentList();
    }
  };

  // Delete Article
  const _deleteArticle = async () => {
    dispatch(modalOpenSetting(false));
    const { data: result } = await sendRequest(deleteArticle, {
      postId: pageId,
    });

    console.log(result);

    if (result?.error) {
      window.alert(result.code);
    } else {
      dispatch(alertModalSetting({ open: !alert.open, title: "게시물이 삭제되었습니다." }));
      setTimeout(() => {
        dispatch(alertModalSetting({ open: false, title: "" }));
      }, 2000);
      navigate(-1);
    }
  };

  useEffect(() => {
    setPageId(getIdPath(location.pathname));
    console.log(`detailData.user.id`, detailData.user?.id);
    console.log(`login.id`, login?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (pageId && (pageId !== "write" || pageId !== null || pageId !== undefined)) {
      _getDetail();
      if (pathType === `community`) {
        // getLikeInfo();
        _getCommentList();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageId]);

  return (
    <div className={classNames("detail", path.nowPath)}>
      <div className="detail__inner">
        {/* page title */}
        <PageTop title={boardTitle(path.nowPath === "notice2" ? "notice" : path.nowPath)} />

        {/* title */}
        {detailData && <BoardList data={detailData} key={detailData.id} background="white" detail />}

        {/* board */}
        <div className="board border">
          {/* 여기에 에디터에서 받아온 내용 껴넣기 */}
          <div className="board__inner">
            {detailData?.content && (
              <Viewer
                initialValue={detailData.content || ""} // 유튜브 삽입 및 미리보기 를 위한 설정(iframe)
                customHTMLRenderer={{
                  htmlBlock: {
                    iframe(node) {
                      return [
                        {
                          type: "openTag",
                          tagName: "iframe",
                          outerNewLine: true,
                          attributes: node.attrs,
                        },
                        { type: "html", content: node.childrenHTML },
                        { type: "closeTag", tagName: "iframe", outerNewLine: false },
                      ];
                    },
                  },
                }}
              />
            )}
          </div>

          {/* //여기에 에디터에서 받아온 내용 껴넣기 */}

          {/* board 하단 like/report 버튼 */}
          {["free-board", "tip-board", "screenshot-board"].includes(path.nowPath) &&
            login.login &&
            !detailData?.notice && (
              <div className="board__buttons">
                <NormalButton
                  title={String(detailData?.likeCount)}
                  buttonStyle="round"
                  size="md"
                  hierarchy="secondary_gray"
                  icon="leading"
                  iconType="heart-fill"
                  iconColor={like ? "#F04438" : "#D0D5DD"}
                  iconSize={20}
                  onClick={() => {
                    _postLike();
                  }}
                  buttonClassName={classNames("like", { on: likeOn })}
                />
                {detailData.user?.id !== login.id && (
                  <NormalButton
                    title="신고"
                    buttonStyle="round"
                    size="xs"
                    hierarchy="tertiary_gray"
                    icon="leading"
                    iconType="flag-fill"
                    iconSize={16}
                    iconColor={detailData.complainByMe ? "#F04438" : "#98A2B3"}
                    onClick={() => {
                      dispatch(
                        modalReportSetting({
                          entityType: "POST",
                          entityId: detailData.id,
                          open: !modal.open,
                          type: "report",
                          userId: detailData.user.nickname,
                          date: new Date(),
                        })
                      );
                    }}
                    buttonClassName={classNames("report")}
                  />
                )}
              </div>
            )}
        </div>

        {/* comment */}
        {(path.nowPath === "free-board" ||
          path.nowPath === "tip-board" ||
          path.nowPath === "screenshot-board" ||
          path.nowPath === "inquiry") && (
          <div className="comment">
            <CommentBox
              title={
                path.nowPath === "inquiry"
                  ? "문의 댓글"
                  : "댓글 " +
                    (detailData?.comment === null || detailData?.comment === undefined ? "" : detailData?.comment)
              }
              value={inputValue}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setInputValue(e.target.value);
              }}
              placeHolder="저작권 등 다른 사람의 권리를 침해하거나 명예를 훼손하는 게시물은 이용약관 및 관련 법률에 의해 제재를 받을 수 있습니다.&#13;&#10;건전한 토론 문화와 양질의 댓글 문화를 위해, 타인에게 불쾌감을 주는 욕설 또는 특정 계층/민족, 종교 등을 비하하는 단어들은 표시가 제한 됩니다."
              limit={200}
              loggedInUser={login.login}
              onClick={() => {
                // console.log(`comment test >>>>>>`, inputValue);
                if (inputValue !== null && inputValue !== undefined && inputValue !== "") {
                  _postComment();
                } else {
                  window.alert(`내용을 작성해주세요.`);
                }
              }}
            />
            {commentList && commentList.length > 0 && (
              <div className="comment__board">
                {commentList.map((data: any, idx: number) => (
                  <CommentList commentData={data} getCommentList={_getCommentList} postId={pageId} key={idx} />
                ))}
              </div>
            )}
          </div>
        )}

        {/* detail bottom */}
        <div className="detail__inner__bottom">
          {detailData?.user?.nickname === login.nickname && (
            <NormalButton
              title={t("delete")}
              buttonStyle="box"
              size="md"
              hierarchy="secondary_gray"
              onClick={() => {
                dispatch(
                  modalSetting({
                    open: !modal.open,
                    type: "danger",
                    title: "삭제하기",
                    description: "정말로 글을 삭제하시겠습니까?",
                    confirmButton: "삭제하기",
                    confirmStyle: "red",
                    onConfirm: _deleteArticle,
                    alert: false,
                  })
                );
              }}
            />
          )}

          <div className="right">
            {detailData?.user?.nickname === login.nickname && (
              <NormalButton
                title={t("edit")}
                buttonStyle="box"
                size="md"
                hierarchy="primary_line"
                onClick={() => {
                  navigate(`/community/${path.nowPath}/write`, { state: { id: detailData.id } });
                }}
              />
            )}
            <NormalButton
              title={t("list")}
              buttonStyle="box"
              size="md"
              hierarchy="primary"
              onClick={() => {
                navigate(-1);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Detail;
