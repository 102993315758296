import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Icon from "../../../../shared/Icon";

import { PageTop, UpdateList, Loading } from "@components/index";
import { getNewsList } from "@apis/board";
import { LoadingState, RootState } from "@store/index";
import { usehttp } from "@hooks/index";

import "./update.scss";
import classNames from "classnames";

export interface YearBoxProps {
  year: number;
  setYear: Function;
  handlePreviousYear: Function;
  handleNextYear: Function;
}

const YearBox = ({ year, setYear, handlePreviousYear, handleNextYear }: YearBoxProps) => {
  return (
    <div className="year_box">
      <div
        className="prev"
        onClick={() => {
          handlePreviousYear();
        }}
      >
        <Icon className="chevron-left" icon="chevron-left" size={24} color="#98A2B3" />
      </div>
      <p className="year">{year}</p>
      <div
        className="next"
        onClick={() => {
          handleNextYear();
        }}
      >
        <Icon className="chevron-right" icon="chevron-right" size={24} color="#98A2B3" />
      </div>
    </div>
  );
};

const UpdatePage = () => {
  const { t } = useTranslation();

  const { isLoading, sendRequest } = usehttp();

  const [listData, setListData] = useState([]); // Axios로 받아온 데이터 저장
  const [year, setYear] = useState(2023);

  const loading: LoadingState = useSelector((state: RootState) => state.loading);

  // get update list
  const _getUpdateList = async () => {
    const { data: result } = await sendRequest(getNewsList, {
      path: "update",
      year: year,
      page: 1,
      limit: 20,
    });

    console.log(`_getUpdateList`, result.updates);

    if (result?.error) {
      window.alert(result.code);
    } else {
      setListData(result?.updates);
    }
  };

  useEffect(() => {
    const date = new Date();
    const currentYear = date.getFullYear();
    setYear(currentYear);
  }, []);

  const handleNextYear = () => {
    const nextYear = year + 1;
    const date = new Date();
    const currentYear = date.getFullYear();
    setYear(Math.min(nextYear, currentYear));
  };

  const handlePreviousYear = () => {
    const previousYear = year - 1;
    const date = new Date();
    const currentYear = date.getFullYear();
    const minYear = currentYear - 0;
    setYear(Math.max(minYear, previousYear));
  };

  useEffect(() => {
    _getUpdateList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year]);

  return (
    <div className="container update">
      <div className="update__inner">
        <PageTop title={t("update_title")} />
        <YearBox
          year={year}
          setYear={setYear}
          handleNextYear={handleNextYear}
          handlePreviousYear={handlePreviousYear}
        />
        <ul
          className={classNames("board", {
            no_list: listData.length <= 0 || listData === null || listData === undefined,
          })}
        >
          {loading.isLoading ? (
            <Loading />
          ) : listData === null || listData === undefined || listData.length <= 0 ? (
            <div className="no_list">
              <div className="img"></div>
              <p className="title">{t("nolist_title")}</p>
              <p className="description">{t("nolist_description")}</p>
            </div>
          ) : (
            listData.map((data, idx) => <UpdateList data={data} key={idx} />)
          )}
        </ul>
      </div>
    </div>
  );
};

export default UpdatePage;
