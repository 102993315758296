import { http } from "./http";
import qs from "qs";

// get world list
export const getWorldList = (data: { category: string; page: number; limit: number; sort: string; select: string }) => {
  return http.get<any>(
    `/world/list${qs.stringify(
      { category: data.category, page: data.page, limit: data.limit, sort: data.sort, select: data.select },
      { arrayFormat: "repeat", skipNulls: true, addQueryPrefix: true }
    )}`
  );
};

// get world detail
export const getWorldDetail = (data: { postId: string }) => {
  return http.get<any>(
    `/world/detail${qs.stringify(
      { postId: data.postId },
      { arrayFormat: "repeat", skipNulls: true, addQueryPrefix: true }
    )}`
  );
};

// post like world
export const postLikeWorld = (data: { entityType: string; entityId: string }) => {
  return http.post<any>(`/world/like`, data);
};

// post favorite world
export const postFavoriteWorld = (data: { postId: string }) => {
  return http.post<any>(`/world/favorite`, data);
};

// post play world
export const postPlayeWorld = (data: { postId: string }) => {
  return http.post<any>(`/world/play`, data);
};
