import { useState } from "react";
import classNames from "classnames";
import kioskData from "../../../shared/kioskData.json";
import imgList from "./imgData";
import { useNavigate } from "react-router-dom";
import { numberWithCommas } from "@utils/numberWithCommas";

const Main = () => {
  const navigate = useNavigate();
  const category = [
    { id: 0, title: "전체" },
    { id: 1, title: "COFFEE" },
    { id: 2, title: "JUICE&DRINK" },
    { id: 3, title: "TEA&ADE" },
    { id: 4, title: "BREAD" },
  ];
  const [selected, setSelected] = useState(0);
  return (
    <>
      <ul className="menu-category">
        {category.map((data: { id: number; title: string }, idx: number) => (
          <li
            className={classNames({ on: data.id === selected })}
            onClick={() => {
              setSelected(data.id);
            }}
            key={idx}
          >
            {data.title}
          </li>
        ))}
      </ul>
      <div className="box-kiosk-list">
        {kioskData.map((data: any, idx: number) => (
          <div
            className="list-kiosk"
            onClick={() => {
              navigate(`/kiosk/order`, { state: { id: data.id } });
            }}
            key={idx}
          >
            <div className="box-img">
              <img src={imgList[idx]} alt="" />
            </div>
            <p className="title-en">{data.nameEn}</p>
            <p className="title-ko">{data.nameKo}</p>
            <p className="price">{String(numberWithCommas(data.price))}원</p>
          </div>
        ))}
      </div>
    </>
  );
};

export default Main;
