import { useState } from "react";

import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import classNames from "classnames";

import { useSelector, useDispatch } from "react-redux";
import Icon from "../../../../shared/Icon";

import { REACT_APP_URL_STUDIO } from "../../../../shared/utils";

import { RootState, gnbSetting, AlertModalState, LoginState, alertModalSetting, GNBState } from "@store/index";
import { NormalButton, Footer } from "@components/index";

import "./contentHeader.scss";

const ContentHeader = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const alert: AlertModalState = useSelector((state: RootState) => state.alert);
  const login: LoginState = useSelector((state: RootState) => state.login);
  const gnb: GNBState = useSelector((state: RootState) => state.gnb);

  const [open, setOpen] = useState({
    open: false,
    id: 0,
  });

  const zemiMenu = [
    { id: 1, title: t("gnb_21"), link: "/", icon: "home" },
    // { id: 2, title: t("gnb_22"), link: "/world", icon: "esports-fill" },
    { id: 3, title: t("gnb_23"), link: "/studio", icon: "brush-fill" },
    { id: 4, title: t("gnb_24"), link: "/market", icon: "store-fill" },
    { id: 5, title: t("gnb_4-4"), link: "/club", icon: "people-fill" },
    { id: 6, title: t("gnb_4-5"), link: "/download", icon: "download2" },
    {
      id: 7,
      children: [
        { id: 71, title: t("gnb_6"), link: "/news/notice" },
        { id: 72, title: t("gnb_7"), link: "/news/update" },
        { id: 73, title: t("gnb_8"), link: "/news/event" },
      ],
      title: t("gnb_5"),
      link: "/news/notice",
      icon: "paper-plane",
    },
    {
      id: 8,
      children: [
        { id: 81, title: t("gnb_10"), link: "/community/free-board" },
        { id: 82, title: t("gnb_11"), link: "/community/tip-board" },
        { id: 83, title: t("gnb_12"), link: "/community/screenshot-board" },
      ],
      title: t("gnb_9"),
      link: "/community/free-board",
      icon: "message-circle-fill",
    },
    {
      id: 9,
      children: [
        { id: 91, title: t("gnb_14"), link: "/customer-service/faq" },
        { id: 92, title: t("gnb_15"), link: "/customer-service/inquiry" },
      ],
      title: t("gnb_13"),
      link: "/customer-service/faq",
      icon: "question-mark-circle-fill",
    },
  ];

  // const snsMenu = [
  //   { id: 1, sns: "instagram", url: "https://www.instagram.com" },
  //   { id: 2, sns: "twitter", url: "https://www.twitter.com" },
  //   { id: 3, sns: "youtube", url: "https://www.youtube.com" },
  //   { id: 4, sns: "tiktok", url: "https://www.tiktok.com" },
  // ];

  const checkOn = (data: any) => {
    if (!data.children) {
      return data.link === location.pathname ? "on" : "off";
    } else {
      return data.children.some((d: any) => d.link === location.pathname) ? "on" : "off";
    }
  };

  // zemiMenu map 용
  const Nav = (data: any, index: number) => {
    return (
      <li
        key={index}
        className={classNames(
          `nav_li_1deps`,
          checkOn(data.data),
          {
            open:
              checkOn(data.data) === "on" || // 현재 열린 페이지거나
              (checkOn(data.data) === "off" && // 현재 열린 페이지는 아니지만 현재 열어놓은 메뉴가 일치할 때
                open.open &&
                ((data.data.title === t("gnb_5") && open.id === 1) ||
                  (data.data.title === t("gnb_9") && open.id === 2) ||
                  (data.data.title === t("gnb_13") && open.id === 3))),
          },
          {
            close:
              checkOn(data.data === "off") ||
              (checkOn(data.data === "on") &&
                (!open.open ||
                  (data.data.title === t("gnb_5") && open.id === 1) ||
                  (data.data.title === t("gnb_9") && open.id === 2) ||
                  (data.data.title === t("gnb_13") && open.id === 3))),
          }
        )}
        onClick={() => {
          if (data.data.title === t("gnb_23")) {
            window.open(REACT_APP_URL_STUDIO, `_blank`);
          } else if (data.data.title === t("gnb_24")) {
            dispatch(alertModalSetting({ open: !alert.open, title: t("modal_alert") }));
          } else if (
            data.data.title === t("gnb_5") ||
            data.data.title === t("gnb_9") ||
            data.data.title === t("gnb_13")
          ) {
            if (checkOn(data.data) === "on") {
              if (data.data.title === t("gnb_5")) {
                if (open.id === 1) {
                  setOpen({ open: !open.open, id: 1 });
                }
              } else if (data.data.title === t("gnb_9")) {
                if (open.id === 2) {
                  setOpen({ open: !open.open, id: 2 });
                }
              } else if (data.data.title === t("gnb_13")) {
                if (open.id === 3) {
                  setOpen({ open: !open.open, id: 3 });
                }
              }
            } else {
              if (data.data.title === t("gnb_5")) {
                if (open.id === 1) {
                  setOpen({ open: !open.open, id: 1 });
                } else {
                  setOpen({ open: true, id: 1 });
                }
              } else if (data.data.title === t("gnb_9")) {
                if (open.id === 2) {
                  setOpen({ open: !open.open, id: 2 });
                } else {
                  setOpen({ open: true, id: 2 });
                }
              } else if (data.data.title === t("gnb_13")) {
                if (open.id === 3) {
                  setOpen({ open: !open.open, id: 3 });
                } else {
                  setOpen({ open: true, id: 3 });
                }
              }
            }
          } else {
            dispatch(gnbSetting(false));
            navigate(`${data.data.link}`);
            setOpen({ open: false, id: 0 });
          }
        }}
      >
        <Icon
          className={data.data.icon}
          icon={data.data.icon}
          size={24}
          color={
            checkOn(data.data) === "on" && (checkOn(data.data) === "on" || (open.open && checkOn(data.data) === "off"))
              ? "#6941C6"
              : "#667085"
          }
        />
        {data.data.title}
        {data.data.children && (
          <ul className={classNames(`nav_ul_2deps`)}>
            {data.data.children.map((d: any, idx: number) => (
              <li
                key={idx}
                className={classNames(`nav_li_2deps`, checkOn(d))}
                onClick={(e) => {
                  dispatch(gnbSetting(false));
                  navigate(`${d.link}`);
                }}
              >
                <Icon className="corner-down-right" icon="corner-down-right" size={10} color="#d0d5dd" />
                {d.title}
              </li>
            ))}
          </ul>
        )}
      </li>
    );
  };

  return (
    <div
      className={classNames("dim__header", { open: gnb.open })}
      onClick={() => {
        dispatch(gnbSetting(false));
      }}
    >
      <div className={classNames("content_header", { open: gnb.open })} onClick={(e) => e.stopPropagation()}>
        <div className="top">
          {/* 상단 메뉴 */}
          <ul className="gnb__main">
            {zemiMenu.slice(0, 5).map((data: any, idx: number) => (
              <Nav data={data} id={idx} key={idx} />
            ))}
          </ul>

          <div className="divider"></div>

          {/* 플레이 리스트 : 로그인 해야만 보임 */}
          {login.login && (
            <>
              <p className="title__play">플레이</p>
              <ul className="list__play">
                {[t("play_list_1"), t("play_list_2"), t("play_list_3"), t("play_list_4"), t("play_list_5")].map(
                  (data: any, idx: number) => (
                    <li
                      key={idx}
                      onClick={() => {
                        dispatch(alertModalSetting({ open: !alert.open, title: t("modal_alert") }));
                      }}
                    >
                      <Icon className="play-circle-fill" icon="play-circle-fill" size={24} color="#344054" />
                      {data}
                    </li>
                  )
                )}
              </ul>
            </>
          )}

          <NormalButton
            title={t("play_start")}
            icon="leading"
            iconType="play-fill"
            iconSize={20}
            iconColor="#FFF"
            buttonStyle="box"
            size="md"
            hierarchy="primary"
            onClick={() => {
              navigate("/download");
            }}
          />

          <div className="divider"></div>

          {/* 게시판 */}
          <ul className="gnb__board">
            {zemiMenu.slice(5, 8).map((data: any, idx: number) => (
              <Nav data={data} id={idx} key={idx} />
            ))}
          </ul>

          <div className="divider"></div>
        </div>

        {/* footer */}
        <Footer />
      </div>
    </div>
  );
};

export default ContentHeader;
