import { HOTEL_DATA } from "./placeholder-data";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";
import { updateDateToString } from "@utils/updateDate";
import { numberWithCommas } from "@utils/numberWithCommas";
import "@components/layout/KioskLayout/kioskLayout.scss";
import { useTranslation } from "react-i18next";

const HotelComplete = () => {
  const { i18n } = useTranslation();
  const [data, setData] = useState<any>();
  const navigate = useNavigate();
  const location = useLocation();
  const { id, headcount, checkInDate, checkOutDate } = location.state;

  useEffect(() => {
    setData(HOTEL_DATA.find((data) => data.id === id));
  }, [id]);

  return (
    <div className="hotel-complete-wrapper">
      <div className="hotel-complete">
        <div className="hotel-complete-img">
          <img src={data?.imgUrl} alt={data?.nameEn} />
        </div>
        <div className="hotel-complete-info">
          <p className="hotel-complete-title">{data?.nameEn}</p>
          <p className="hotel-complete-label">예약이 완료되었습니다.</p>

          <div className={classNames("hotel-info", "hotel-complete-description")}>
            <div className="hotel-info-label">
              <ul>
                <li>· 체크인</li>
                <li>· 체크아웃</li>
                <li>· 예약 인원</li>
                <li>· 결제 금액</li>
              </ul>
            </div>
            <div className="hotel-info-content">
              <ul>
                <li>{updateDateToString(new Date(checkInDate), "yyyy년 MM월 dd일 (E요일)", i18n.language)}</li>
                <li>{updateDateToString(new Date(checkOutDate), "yyyy년 MM월 dd일 (E요일)", i18n.language)}</li>
                <li>{headcount} 명</li>
                <li>{numberWithCommas(data?.price)}원</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <button
        onClick={() => {
          navigate("/hotel");
        }}
        className="hotel-complete-btn"
      >
        예약내역 보기
      </button>
    </div>
  );
};

export default HotelComplete;
