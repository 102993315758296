import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  RootState,
  AlertModalState,
  gnbSetting,
  alertModalSetting,
  LoginState,
  loginSetting,
  mypageGnbSetting,
  profileSetting,
  mymenuSetting,
  MYMENUState,
} from "@store/index";
import { numberWithCommas, REACT_APP_COOKIE } from "@utils/index";
import classNames from "classnames";
import Icon from "@shared/Icon";
import { AUTH_COOKIE } from "@shared/constants";

const data = [
  {
    category: "zemi",
    amount: 0,
  },
  {
    category: "tok",
    amount: 0,
  },
  {
    category: "point",
    amount: 0,
  },
];

const MyMenu = () => {
  const [myMenuOpen, setMyMenuOpen] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const login: LoginState = useSelector((state: RootState) => state.login);
  const mymenu: MYMENUState = useSelector((state: RootState) => state.mymenu);
  const alert: AlertModalState = useSelector((state: RootState) => state.alert);

  return (
    <div className="avatar">
      {login.picture && (
        <img
          className="profile"
          src={login.picture}
          alt=""
          onClick={() => {
            // setMyMenuOpen(!myMenuOpen);
            dispatch(mypageGnbSetting(false));
            dispatch(gnbSetting(false));
            dispatch(mymenuSetting(!mymenu.open));
          }}
        />
      )}
      {!login.picture && (
        <div
          className="profile none"
          onClick={() => {
            // setMyMenuOpen(!myMenuOpen);
            dispatch(mymenuSetting(!mymenu.open));
          }}
        />
      )}
      <div className={classNames("dim", { on: mymenu.open })}>
        <div
          className={classNames("my_menu_box", { on: mymenu.open })}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="my_menu_top">
            <p className="nickname">{login.nickname}</p>
            <p className="email">{login.email}</p>
            <p className="sns">{login.sns} 로그인</p>
          </div>

          <div className="box__avatar">
            <div className="box-avatar__inner">
              <img src={login.picture} alt="아바타 이미지" />
              <div className="right">
                <p className="title">대표 아바타</p>
                <p className="description">대표아바타닉네임</p>
              </div>
            </div>

            <div
              className="button__avatar"
              onClick={() => {
                // dispatch(alertModalSetting({ open: !alert.open, title: t("modal_alert2") }));
                navigate(`/mypage/setting/avatar`);
              }}
            >
              <img src={login.picture} alt="아바타 이미지" />
              <p className="title">대표 아바타 연동</p>
            </div>
          </div>

          <div className="box__wallet">
            {data.map((data: any, idx: number) => (
              <div className={classNames("box__wallet__zemi", data.category)} key={idx}>
                <p className={classNames("category", data.category)}>{data.category}</p>
                <p className="money">{numberWithCommas(data.amount)}</p>
              </div>
            ))}
          </div>

          <div className="divider"></div>

          <ul className="menu">
            {["대시보드", "My 컬렉션", "My 샵", "거래 내역", "클럽", "결재/정산"].map((data: any, idx: number) => (
              <li
                key={idx}
                onClick={() => {
                  if (idx === 0) {
                    // navigate(`/mypage`);
                  } else if (idx === 1) {
                    // navigate(`/mypage/collection`);
                  } else if (idx === 2) {
                    // navigate(`/mypage/shop`);
                  } else if (idx === 3) {
                    // navigate(`/mypage/transaction`);
                  } else if (idx === 4) {
                    // navigate(`/mypage/club`);
                  } else if (idx === 5) {
                    // navigate(`/mypage/calculate/earn`);
                  }
                  dispatch(alertModalSetting({ open: !alert.open, title: "개발 중입니다." }));
                  setTimeout(() => {
                    dispatch(alertModalSetting({ open: false, title: "" }));
                  }, 2000);
                  setMyMenuOpen(!myMenuOpen);
                  dispatch(mymenuSetting(!mymenu.open));
                }}
              >
                <Icon
                  icon={
                    idx === 0
                      ? "user-circle"
                      : idx === 1
                      ? "folder"
                      : idx === 2
                      ? "Shop"
                      : idx === 3
                      ? "shopping-cart"
                      : idx === 4
                      ? "users"
                      : "coins"
                  }
                  size={16}
                  color="#101828"
                />
                {data}
              </li>
            ))}

            <div className="divider"></div>

            {["계정 설정", "1:1문의", "로그아웃"].map((data: any, idx: number) => (
              <li
                key={idx}
                onClick={() => {
                  if (idx === 0) {
                    navigate(`/mypage/setting/profile`);
                    setMyMenuOpen(!myMenuOpen);
                    dispatch(mymenuSetting(!mymenu.open));
                  } else if (idx === 1) {
                    navigate(`/customer-service/inquiry`);
                    setMyMenuOpen(!myMenuOpen);
                    dispatch(mymenuSetting(!mymenu.open));
                  } else {
                    dispatch(alertModalSetting({ open: !alert.open, title: "로그아웃되었습니다." }));
                    document.cookie = `${AUTH_COOKIE}=;expires = Thu, 01 Jan 1970 00:00:00 UTC;path=/;domain=${REACT_APP_COOKIE};`;
                    dispatch(profileSetting({ nickname: "", picture: "", sns: "", email: "", id: "" }));
                    dispatch(loginSetting(false));
                    setMyMenuOpen(!myMenuOpen);
                    dispatch(mymenuSetting(!mymenu.open));
                    navigate("/");
                    setTimeout(() => {
                      dispatch(alertModalSetting({ open: false, title: "" }));
                    }, 2000);
                  }
                }}
              >
                <Icon
                  icon={idx === 0 ? "settings" : idx === 1 ? "message-circle" : "log-out"}
                  size={16}
                  color="#101828"
                />
                {data}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MyMenu;
