import classNames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { RootState, ModalState, modalOpenSetting } from "@store/index";

import { Hierarchy } from "@components/common/NormalButton/NormalButton";
import NormalButton from "@components/common/NormalButton/NormalButton";

import "../modal.scss";
import { useEffect } from "react";

type ConfirmModalType = "success" | "warning" | "danger" | "info";

interface ConfirmModalProps {
  type?: ConfirmModalType | string | undefined;
  title: string | undefined;
  description?: string;
  cancel: string;
  confirmButton: string | undefined;
  confirmStyle?: Hierarchy | string | undefined;
  alert?: boolean;
}

const ConfirmModal = ({ type, title, description, cancel, confirmButton, confirmStyle, alert }: ConfirmModalProps) => {
  const dispatch = useDispatch();
  const modal: ModalState = useSelector((state: RootState) => state.modal);

  const handleConfirmClick = () => {
    modal.onConfirm();
    if (alert === false) {
      dispatch(modalOpenSetting(!modal.open));
    }
  };

  return (
    <div className="dim">
      <div className={classNames("modal confirm", type, { alert: alert })}>
        <div className="icon_box">
          <div className="icon"></div>
        </div>

        <p className="title">{title}</p>
        <p className="description">{description}</p>

        <div className="button_box">
          {modal.alert === false && (
            <NormalButton
              title={cancel}
              size="lg"
              buttonStyle="box"
              hierarchy="secondary_gray"
              onClick={() => {
                dispatch(modalOpenSetting(!modal.open));
              }}
            />
          )}

          <NormalButton
            title={confirmButton}
            size="lg"
            buttonStyle="box"
            hierarchy={confirmStyle ? confirmStyle : "secondary_gray"}
            onClick={() => {
              handleConfirmClick();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
