import "./concertLayout.scss";
import Icon from "@shared/Icon";
import classNames from "classnames";
import { sendMessageExit } from "@components/Webview/unitySender";
import { useTranslation } from "react-i18next";
import { updateDateToString } from "@utils/updateDate";

const ConcertLayout = () => {
  const { i18n, t } = useTranslation();

  const handleOnCancel = () => {
    console.log("exit");
    if (window.vuplex) {
      sendMessageExit();
    } else {
      window.addEventListener("vuplexready", sendMessageExit);
    }
  };

  return (
    <div className="concert-wrapper">
      <header className="concert-header">
        <h1>콘서트 예매내역</h1>
        <div className="concert-x-icon">
          <Icon className="x" icon="x" size="100%" color="#344054" onClick={handleOnCancel} />
        </div>
      </header>

      <div className="concert-content">
        <div className="concert-thumbnail" />
        <h2 className="concert-subtitle">예매 내역</h2>
        <ul className="concert-list">
          <li className="concert-item">
            <div className="concert-item-label">공연명</div>
            <p className="concert-item-info">BLACKPINK Winter Concert</p>
          </li>

          <li className="concert-item">
            <div className="concert-item-label">예매자</div>
            <p className="concert-item-info">전주천</p>
          </li>

          <li className="concert-item">
            <div className="concert-item-label">예약번호</div>
            <p className="concert-item-info">CON2023-1228-1632</p>
          </li>

          <li className="concert-item">
            <div className="concert-item-label">장소</div>
            <p className="concert-item-info">야외 콘서트장</p>
          </li>

          <li className="concert-item">
            <div className="concert-item-label">일시</div>
            <p className="concert-item-info">
              {updateDateToString(new Date(), "yyyy.MM.dd", i18n.language)}
            </p>
          </li>

          <li className="concert-item">
            <div className="concert-item-label">좌석</div>
            <p className="concert-item-info">자유석</p>
          </li>
        </ul>

        <h2 className="concert-subtitle">결제 내역</h2>
        <ul className="concert-list">
          <li className="concert-item">
            <div className="concert-item-label">예매일</div>
            <p className={classNames("concert-item-info", "ir-accent")}>2023.12.15</p>
          </li>

          <li className="concert-item">
            <div className="concert-item-label">결제 수단</div>
            <p className="concert-item-info">Point</p>
          </li>

          <li className="concert-item">
            <div className="concert-item-label">총 결제 금액</div>
            <p className={classNames("concert-item-info", "ir-accent")}>30,000p</p>
          </li>
        </ul>
      </div>
      <button className="concert-cancel-btn" onClick={handleOnCancel}>
        닫기
      </button>
    </div>
  );
};

export default ConcertLayout;
