const ALERT_MODAL_OPEN = 'alert/ALERT_MODAL_OPEN' as const;

type AlertModalAction =
  | ReturnType<typeof alertModalSetting>
;

export const alertModalSetting = (diff: {open : boolean, title: string, icon?: string, iconSize?: number, iconColor?: string}) => ({
  type: ALERT_MODAL_OPEN,
  payload: diff
});

export type AlertModalState = {
  open: boolean;
  title: string;
  icon?: string;
  iconSize?: number;
  iconColor?: string;
};

const initialState: AlertModalState = {
  open: false,
  title: "",
  icon: "",
  iconSize: 24,
  iconColor: "",
};

function alert(
  state: AlertModalState = initialState,
  action: AlertModalAction
): AlertModalState {
  switch (action.type) {
    case ALERT_MODAL_OPEN:
      return { ...state, open: action.payload.open, title: action.payload.title, icon: action.payload.icon, iconSize: action.payload.iconSize, iconColor: action.payload.iconColor };
    default:
      return state;
  }
}

export default alert;