// CommentBox.tsx

import classNames from "classnames";

import { NormalButton } from "@components/index";

interface CommentBoxProps {
  title: string;
  value: string;
  onChange?: any;
  placeHolder: string;
  limit: number;
  reply?: boolean;
  loggedInUser: boolean;
  onClick?: Function;
}

const CommentBox = ({ title, value, onChange, placeHolder, limit, reply, loggedInUser, onClick }: CommentBoxProps) => {
  if (loggedInUser) {
    return (
      <div className={classNames("comment__write", { reply: reply })}>
        <p className="comment__write__title">{title}</p>
        <div className="comment__write__box">
          <div className="comment__write__box__inner">
            <textarea
              style={{ resize: "none" }}
              value={value}
              onChange={onChange}
              placeholder={placeHolder}
              maxLength={limit}
            />
            <p className="limit">
              {value.length}/{limit}
            </p>
          </div>

          <NormalButton title="등록" buttonStyle="box" size="txl" hierarchy="secondary_gray" onClick={onClick} />
        </div>
      </div>
    );
  } else {
    return false;
  }
};

export default CommentBox;
