import { useRef, useState } from "react";
import useHttp from "@hooks/usehttp";
// import { getWebviewList } from "@apis/webview";
import { getWorldList } from "@apis/world";

const LIMIT = 10;

const useWebviewList = () => {
  const { isLoading, sendRequest } = useHttp();
  const [webviewItems, setWebviewItems] = useState([]);

  const pageRef = useRef(1);
  const isEndRef = useRef(false);

  const fetchWebviewList = async () => {
    if (isEndRef.current) return;
    if (!isLoading) {
      const data = await sendRequest(getWorldList, {
        page: pageRef.current,
        limit: LIMIT,
      });
      const newWebviewItems = data.data.posts;
      if (!newWebviewItems) return [];
      if (newWebviewItems.length === 0) {
        isEndRef.current = true;
        return;
      }
      pageRef.current = pageRef.current + 1;
      setWebviewItems((prevItems) => [...prevItems, ...newWebviewItems]);
    }
  };
  return { fetchWebviewList, isLoading, webviewItems };
};

export default useWebviewList;
