import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";

import { RootState, AlertModalState, alertModalSetting } from "@store/index";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/scss/autoplay";
import Icon from "../../../../shared/Icon";

import "./download.scss";
import classNames from "classnames";

import imgfile2 from "../../../../assets/images/download/content2_img2.png";
import imgfile3 from "../../../../assets/images/download/content2_img3.png";
import imgfile4 from "../../../../assets/images/download/content2_img4.png";
import imgfile5 from "../../../../assets/images/download/content2_img5.png";

const Download = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const alert: AlertModalState = useSelector((state: RootState) => state.alert);

  return (
    <div className="container download">
      <div className="download__inner">
        {/* content1 */}
        <div className="content1">
          <p className="title">{t("download_content1_title")}</p>
          <p className="description">{t("download_content1_description")}</p>
          <div className="boxes">
            {[
              "google",
              // , "apple",
              "window",
            ].map((data: string, idx: number) => (
              <div
                className={classNames("button", data)}
                onClick={() => {
                  if (data === "google") {
                    window.open("https://play.google.com/store/apps/details?id=com.nextor.zemiverse", "_blank");
                  }
                  // else if (data === "apple") {
                  //   dispatch(alertModalSetting({ open: !alert.open, title: t("modal_alert") }));
                  //   setTimeout(() => {
                  //     dispatch(alertModalSetting({ open: false, title: "" }));
                  //   }, 2000);
                  // }
                  else if (data === "window") {
                    window.open(
                      `https://webdata2.s3.ap-northeast-2.amazonaws.com/apk/ZemiverseInstaller.exe`,
                      `_blank`
                    );
                    // window.open(`http://61.74.186.145:8721/downloadwindow/ZemiverseInstaller.exe`, `_blank`);
                  }
                }}
                key={idx}
              ></div>
            ))}
          </div>
        </div>

        {/* content2 */}
        <div className="content2">
          <Swiper modules={[Pagination, Navigation]} slidesPerView={1} initialSlide={0} centeredSlides navigation loop>
            <SwiperSlide>
              <div className="box"></div>
              <ReactPlayer
                url={`https://youtu.be/UbJUrAIBbzc`}
                className="player"
                style={{ objectFit: "cover" }}
                playing
                muted
                loop
                controls
                // width={1700}
              />
            </SwiperSlide>
            <SwiperSlide>
              <img src={imgfile2} alt="다운로드 스와이퍼 이미지 2" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={imgfile3} alt="다운로드 스와이퍼 이미지 3" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={imgfile4} alt="다운로드 스와이퍼 이미지 4" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={imgfile5} alt="다운로드 스와이퍼 이미지 5" />
            </SwiperSlide>
          </Swiper>
        </div>

        {/* content3 */}
        <div className="content3">
          <p className="title">{t("download_content3_title")}</p>
          <p className="description">{t("download_content3_description")}</p>

          <div className="content3__inner">
            <div className="box">
              <p className="title">{t("download_content3_box1_title")}</p>
              <div
                className="logo"
                onClick={() => {
                  // dispatch(connectModalSetting(!modalOpen));
                  window.open(`https://nextor.ai`, `_blank`);
                }}
              ></div>
            </div>
            <div className="box">
              <p className="title">{t("download_content3_box2_title")}</p>
              <div
                className="box__inner"
                onClick={() => {
                  // dispatch(connectModalSetting(!modalOpen));
                  window.open(`https://nextor.ai`, `_blank`);
                }}
              >
                <p className="description">nextor.ai</p>
                <Icon className="external-link-in" icon="external-link-in" size={20} color="#98A2B3" />
              </div>
            </div>
            <div className="box">
              <p className="title">{t("download_content3_box3_title")}</p>
              <p className="description">{t("download_content3_box3_description")}</p>
            </div>
            <div className="box">
              <p className="title">{t("download_content3_box4_title")}</p>
              <div className="box__inner">
                {[
                  // "apple",
                  "google",
                  "window",
                ].map((data: any, idx: number) => (
                  <div
                    className={classNames("icon", data)}
                    onClick={() => {
                      if (data === "google") {
                        window.open("https://play.google.com/store/apps/details?id=com.nextor.zemiverse", "_blank");
                      } else if (data === "apple") {
                        dispatch(alertModalSetting({ open: !alert.open, title: t("modal_alert") }));
                        setTimeout(() => {
                          dispatch(alertModalSetting({ open: false, title: "" }));
                        }, 2000);
                      } else if (data === "window") {
                        window.open(`http://61.74.186.145:8721/downloadwindow/ZemiversePC.zip`, `_blank`);
                      }
                    }}
                    key={idx}
                  ></div>
                ))}
              </div>
            </div>
            <div className="box">
              <p className="title">{t("download_content3_box5_title")}</p>
              <p className="description">{t("download_content3_box5_description")}</p>
            </div>
          </div>
        </div>

        {/* content4 */}
        <div className="content4">
          <p className="title">ZEMIVERSE Core Service</p>
          <div className="content4__inner">
            {[...Array(4)].map((a: any, idx: number) => (
              <div className="box" key={idx}>
                <div className="image"></div>
                <div className="box__inner">
                  <p className="title">{t(`download_content4_box${idx + 1}_title`)}</p>
                  <p className="description">{t(`download_content4_box${idx + 1}_description`)}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Download;
