import { Outlet, useLocation, useParams } from "react-router-dom";
import { KioskHeader } from "@components/index";
import "../KioskLayout/kioskLayout.scss";
import "./goodsLayout.scss";

import { useEffect, useState } from "react";

const GoodsLayout = () => {
  const location = useLocation();
  const [headerType, setHeaderType] = useState("title");

  useEffect(() => {
    if (window.location.pathname === "/goods") {
      setHeaderType("goods-title");
    } else if (window.location.pathname === "/goods/order") {
      setHeaderType("goods-order");
    } else if (window.location.pathname === "/goods/detail") {
      // 다른 경로에 따라 다른 headerType을 설정할 수 있음
      setHeaderType("back");
    } else if (window.location.pathname === "/goods/complete") {
      setHeaderType("close");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <div className="webview kiosk container">
      <KioskHeader type={headerType} />
      <Outlet />
    </div>
  );
};

export default GoodsLayout;
