import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { NormalButton } from "@components/index";
import { REACT_APP_URL_STUDIO } from "@utils/index";
import Icon from "../../../shared/Icon";

import "./registerDone.scss";

/**
 * /register/done - 가입 완료 페이지
 */

const RegisterDone = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  // window width 사이즈
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  return (
    <div className="content register-done">
      <div className="register-done__inner">
        <div className="register-done__header">
          <div className="logo"></div>
          <div className="log-wrapper">
            <Icon className="check" icon="check" size={24} color="#12B76A" />
            <p>{t("done_complate")}</p>
          </div>
        </div>
        <div className="register-done__description">{t("done_description")}</div>
        <div className="register-done-button__wrapper">
          <NormalButton
            title={t("재미버스")}
            hierarchy="primary"
            onClick={() => {
              navigate(`/`);
            }}
            buttonStyle="round"
            size={windowSize > 1402 ? "md" : windowSize > 760 ? "lg" : "xl"}
            iconColor="#fff"
            icon="trailing"
          />
          <NormalButton
            title={t("done_studio")}
            hierarchy="primary"
            onClick={() => {
              navigate(`/`);
              window.location.assign(REACT_APP_URL_STUDIO);
            }}
            buttonStyle="round"
            size={windowSize > 1402 ? "md" : windowSize > 760 ? "lg" : "xl"}
            iconColor="#fff"
            icon="trailing"
          />
          <NormalButton
            title={t("done_mypage")}
            hierarchy="secondary_gray"
            onClick={() => {
              navigate(`/mypage/setting/profile`);
            }}
            buttonStyle="round"
            size={windowSize > 1402 ? "md" : windowSize > 760 ? "lg" : "xl"}
            iconColor="#344054"
            icon="trailing"
          />
          <NormalButton
            title={t("done_download")}
            hierarchy="secondary_gray"
            onClick={() => {
              navigate(`/download`);
            }}
            buttonStyle="round"
            size={windowSize > 1402 ? "md" : windowSize > 760 ? "lg" : "xl"}
            iconType="download"
            iconColor="#344054"
            icon="trailing"
          />
        </div>
      </div>
    </div>
  );
};

export default RegisterDone;
