import classNames from "classnames";
import { useSelector, useDispatch } from "react-redux";

import Icon from "../../../shared/Icon";
import { RootState, AlertModalState, alertModalSetting } from "@store/index";

import '../modal.scss';
import { useTranslation } from "react-i18next";

const AlertModal = () => {

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const alert:AlertModalState = useSelector((state: RootState) => state.alert);

  return (
    <div 
      className="dim"
      onClick={() => {
        dispatch(alertModalSetting({open : !alert.open, title: t("modal_alert")}));
      }}
    >
      <div 
        className={classNames("modal alert")}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <div className="left">
          {alert.icon ? (
            <Icon className={alert.icon} icon={alert.icon} size={alert.iconSize ? alert.iconSize : 24} color={alert.iconColor ? alert.iconColor : "#2E90FA"} />
          ) : <Icon className="alert-circle" icon="alert-circle" size={24} color="#2E90FA" />}
          <p className="description">{alert.title}</p>
        </div>
        <div 
          className="close_button"
          onClick={() => {
            dispatch(alertModalSetting({open : !alert.open, title: t("modal_alert")}));
          }}
        ></div>
      </div>
    </div>
  );
}

export default AlertModal;