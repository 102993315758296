import { http } from "./http";

// get img upload(profile/banner)
export const getImgUpload = (data: { type: string }) => {
  return http.get<any>(`/file/signedUrl/image/${data.type}`);
};

// get img upload
export const getFileUpload = (data: {}) => {
  return http.get<any>(`/file/signedUrl/image?files[0]=image`);
};
