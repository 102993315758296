import { useNavigate } from "react-router-dom";
import Icon from "../../shared/Icon";
import { sendMessageExit } from "../Webview/unitySender";
import classNames from "classnames";

type KioskHeaderType = {
  type: "title" | "back" | "close" | string;
};

const KioskHeader = ({ type }: KioskHeaderType) => {
  const handleOnPrev = () => {
    // console.log("exit");
    if (window.vuplex) {
      sendMessageExit();
    } else {
      window.addEventListener("vuplexready", sendMessageExit);
    }
  };
  const navigate = useNavigate();
  return (
    <div className="webview-header sticky">
      <div className="header-prev">
        {type === "title" && <p className="header-title">주문하기</p>}
        {type === "goods-title" && <p className="header-title">GOODS PRODUCTS</p>}
        {type === "restaurant-title" && <p className="header-title">예약 정보</p>}
        {type === "hotel-title" && <p className="header-title">ROOM LIST</p>}
        {type === "hotel-detail" && (
          <div className="goods-order-title">
            <div className="icon-wrapper">
              <Icon
                className="chevron-right-small"
                icon="chevron-right-small"
                size="100%"
                color="#98A2B3"
                onClick={() => {
                  navigate(-1);
                }}
              />
            </div>
            <p className="header-title">객실 정보</p>
          </div>
        )}
        {type === "hotel-order" && (
          <div className="goods-order-title">
            <div className="icon-wrapper">
              <Icon
                className="chevron-right-small"
                icon="chevron-right-small"
                size="100%"
                color="#98A2B3"
                onClick={() => {
                  navigate(-1);
                }}
              />
            </div>
            <p className="header-title">객실 예약</p>
          </div>
        )}
        {type === "goods-order" && (
          <div className="goods-order-title">
            <div className="icon-wrapper">
              <Icon
                className="chevron-right-small"
                icon="chevron-right-small"
                size="100%"
                color="#98A2B3"
                onClick={() => {
                  navigate(-1);
                }}
              />
            </div>
            <p className="header-title">주문/결제</p>
          </div>
        )}

        {type === "back" && (
          <div className="icon-wrapper">
            <Icon
              className="chevron-right-small"
              icon="chevron-right-small"
              size="100%"
              color="#98A2B3"
              onClick={() => {
                navigate(-1);
              }}
            />
          </div>
        )}
      </div>
      <div className="header-search">
        <Icon
          className="x"
          icon="x"
          size="100%"
          color="#344054"
          onClick={() => {
            if (
              type === "title" ||
              type === "close" ||
              type === "goods-title" ||
              type === "hotel-detail" ||
              type === "hotel-title" ||
              type === "hotel-order" ||
              type === "restaurant-title"
            ) {
              handleOnPrev();
            } else if (type === "back") {
              navigate(-1);
            }
          }}
        />
      </div>
    </div>
  );
};

export default KioskHeader;
