import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GOODS_DATA } from "./placehoder-data";
import { numberWithCommas } from "@utils/numberWithCommas";
import { updateDateToString } from "@utils/updateDate";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

const GoodsComplete = () => {
  const { i18n, t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { id, total } = location.state;
  const [data, setData] = useState<any>();

  useEffect(() => {
    setData(GOODS_DATA.find((data) => data.id === id));
  }, [id]);

  return (
    <>
      <div className="box-complete">
        <p className="complete">상품 주문이 완료되었습니다.</p>
        <p className="date">{updateDateToString(new Date(), "yyyy.MM.dd hh:mm", i18n.language)}</p>
      </div>
      <div className="box-complete-list">
        <div className="list-order">
          <div className="box-img">
            <img src={data?.imgUrl} alt="" />
          </div>
          <div className="right">
            <div className="top">
              <p className="temperature">{data?.nameEn}</p>
              <p className="title-ko">{data?.nameKo}</p>
            </div>
            <p className="price">{numberWithCommas(data?.price)}원</p>
          </div>
        </div>
      </div>
      <div className={classNames("box-total", "pb-300")}>
        <p className="total-count">총 결제 금액&#40;{total / data?.price}개&#41;</p>
        {/* <p className="total-price">{numberWithCommas(data?.price * location.state?.quantity)}원</p> */}
        <p className="total-price">{numberWithCommas(total)}원</p>
      </div>
      <div
        className={classNames("good-complete-btn")}
        onClick={() => {
          navigate(`/goods`);
        }}
      >
        <p>주문내역 보기</p>
      </div>
    </>
  );
};

export default GoodsComplete;
