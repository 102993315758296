import { HOTEL_DATA } from "./placeholder-data";
import { useNavigate } from "react-router-dom";
import { numberWithCommas } from "@utils/numberWithCommas";
import classNames from "classnames";
const HotelList = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="box-kiosk-list">
        {HOTEL_DATA.map((data: any, idx: number) => (
          <div
            className="list-kiosk"
            onClick={() => {
              navigate(`/hotel/detail`, { state: { id: data.id } });
            }}
            key={idx}
          >
            <div className={classNames("box-img", "hotel-img")}>
              <img src={data.imgUrl} alt="" />
            </div>
            <p className={classNames("title-ko", "hotel-list-title")}>{data.nameEn}</p>
            <p className={classNames("price", "hotel-list-price")}>
              {String(numberWithCommas(data.price))}원 ~
            </p>
          </div>
        ))}
      </div>
    </>
  );
};

export default HotelList;
