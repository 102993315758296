import { sendMessageMapGUID } from "@components/Webview/unitySender";
import "./introLayout.scss";

const IntroLayout = () => {
  const handleOnPlay = (mapGUID) => {
    console.log(mapGUID);
    if (window.vuplex) {
      sendMessageMapGUID(mapGUID);
    } else {
      window.addEventListener("vuplexready", sendMessageMapGUID);
    }
  };

  return (
    <div className="intro-outer">
      <div className="intro-inner">
        <div className="intro-wrapper">
          <p className="intro-title">Entertainment Land</p>
          <button className="intro-button" onClick={() => handleOnPlay("111")}>
            Play
          </button>
        </div>
        <div className="intro-wrapper">
          <p className="intro-title">Travel Land</p>
          <button className="intro-button" onClick={() => handleOnPlay("112")}>
            Play
          </button>
        </div>
      </div>
    </div>
  );
};

export default IntroLayout;
