// Comment.tsx

import { useEffect, useState, Dispatch, SetStateAction } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import Icon from "../../../../shared/Icon";

import { NormalButton, CommentBox } from "@components/index";
import { deleteComment, deleteReply, patchComment, patchReply } from "@apis/board";
import {
  RootState,
  LoginState,
  AlertModalState,
  alertModalSetting,
  modalSetting,
  ModalState,
  modalReportSetting,
} from "@store/index";
import { updateDateToString } from "@shared/utils";
import { usehttp } from "@hooks/index";

interface CommentProps {
  commentData: any;
  reply?: boolean;
  reReply?: boolean;
  value?: string;
  setValue?: Dispatch<SetStateAction<string>>;
  onClick?: Function;
  commentOpen?: boolean;
  setCommentOpen?: Dispatch<SetStateAction<boolean>>;
}

const Comment = ({
  commentData,
  reply,
  reReply,
  onClick,
  value,
  setValue,
  commentOpen,
  setCommentOpen,
}: CommentProps) => {
  const { i18n } = useTranslation();

  const [editValue, setEditValue] = useState("");
  const [edit, setEdit] = useState(false);
  const [open, setOpen] = useState(false);

  const login: LoginState = useSelector((state: RootState) => state.login);
  const alert: AlertModalState = useSelector((state: RootState) => state.alert);
  const modal: ModalState = useSelector((state: RootState) => state.modal);
  const { sendRequest } = usehttp();

  const dispatch = useDispatch();

  // Patch Comment
  const _patchComment = async () => {
    const { data: result } = await sendRequest(patchComment, {
      commentId: commentData.id,
      content: editValue,
    });

    console.log(result);

    if (result?.error) {
      window.alert(result.code);
    } else {
      dispatch(alertModalSetting({ open: !alert.open, title: "댓글이 수정되었습니다." }));
      setTimeout(() => {
        dispatch(alertModalSetting({ open: false, title: "" }));
      }, 2000);
    }
  };

  // Patch Reply
  const _patchReply = async () => {
    const { data: result } = await sendRequest(patchReply, {
      replyId: commentData.id,
      content: editValue,
    });

    console.log(result);
    if (result?.error) {
      window.alert(result.code);
    } else {
      dispatch(alertModalSetting({ open: !alert.open, title: "댓글이 수정되었습니다." }));
      setTimeout(() => {
        dispatch(alertModalSetting({ open: false, title: "" }));
      }, 2000);
    }
  };

  // Delete Comment
  const _deleteComment = async () => {
    console.log(`commentId`, commentData.id);
    const { data: result } = await sendRequest(deleteComment, {
      commentId: commentData.id,
    });

    console.log(result);

    if (result?.error) {
      window.alert(result.code);
    } else {
      dispatch(alertModalSetting({ open: !alert.open, title: "댓글이 삭제되었습니다." }));
      setTimeout(() => {
        dispatch(alertModalSetting({ open: false, title: "" }));
      }, 2000);
    }
  };

  // Delete Reply
  const _deleteReply = async () => {
    console.log(`replyId`, commentData.id);
    const { data: result } = await sendRequest(deleteReply, {
      replyId: commentData.id,
    });

    console.log(result);

    if (result?.error) {
      window.alert(result.code);
    } else {
      dispatch(alertModalSetting({ open: !alert.open, title: "댓글이 삭제되었습니다." }));
      setTimeout(() => {
        dispatch(alertModalSetting({ open: false, title: "" }));
      }, 2000);
    }
  };

  useEffect(() => {
    setEditValue(commentData.content);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentData]);

  // useEffect(() => {
  //   var a = /#[^\s#]+/;
  // }, [value]);

  return (
    <>
      <div
        className={classNames(
          "comment__board__list",
          { reply: reply },
          { re_reply: reReply },
          { logged_in: login.login }
        )}
      >
        <div className="top">
          <div className="user">
            {(reply || reReply) && (
              <Icon className="corner-down-right" icon="corner-down-right" size={20} color="#475467" />
            )}
            {commentData.user?.profileImageUrl === "" ||
            commentData.user?.profileImageUrl === null ||
            commentData.user?.profileImageUrl === undefined ? (
              <div className="user__profile none"></div>
            ) : (
              <img className="user__profile" src={commentData.user?.profileImageUrl} alt="user profile" />
            )}

            <p
              className="user__id"
              // onClick={() => {
              //   if (!open && !reply) {
              //     setOpen(!open);
              //   } else if (open && !reply) {
              //   }
              //   var a = value.concat(`@${commentData.user?.nickname} `);
              //   setValue(a);
              // }}
            >
              {commentData.user?.nickname}
            </p>
          </div>
          <div className="box">
            <p className="date">{updateDateToString(commentData.createdAt, "yyyy-MM-dd", i18n.language)}</p>
            {login.login && commentData.user?.id !== login.id && (
              <NormalButton
                title="신고"
                hierarchy="tertiary_gray"
                icon="leading"
                iconType="flag-fill"
                iconSize={16}
                iconColor={commentData?.complainByMe ? "#F04438" : "#98A2B3"}
                buttonStyle="round"
                size="xs"
                onClick={() => {
                  dispatch(
                    modalReportSetting({
                      entityType: reply ? "REPLY" : "COMMENT",
                      entityId: commentData.id,
                      open: !modal.open,
                      type: "report",
                      userId: commentData.user?.nickname,
                      date: new Date(),
                    })
                  );
                }}
              />
            )}
          </div>
        </div>
        {edit ? (
          <textarea
            style={{ resize: "none" }}
            value={editValue}
            onChange={(e: any) => {
              setEditValue(e.target.value);
            }}
            placeholder={
              "저작권 등 다른 사람의 권리를 침해하거나 명예를 훼손하는 게시물은 이용약관 및 관련 법률에 의해 제재를 받을 수 있습니다.&#13;&#10;건전한 토론 문화와 양질의 댓글 문화를 위해, 타인에게 불쾌감을 주는 욕설 또는 특정 계층/민족, 종교 등을 비하하는 단어들은 표시가 제한 됩니다."
            }
            maxLength={200}
          />
        ) : (
          <p className="description">{editValue}</p>
        )}
        <div className="bottom">
          {!reReply && (
            <>
              <div className="bottom__right">
                {login.login && !reply && (
                  <NormalButton
                    title={open ? "취소" : "답글작성"}
                    buttonStyle="box"
                    size="xs"
                    hierarchy={open ? "secondary_gray" : "primary"}
                    onClick={() => {
                      setOpen(!open);
                    }}
                  />
                )}

                {commentData.replyCount > 0 && (
                  <NormalButton
                    title={`답글 ${commentData.replyCount}개`}
                    buttonStyle="round"
                    size="xs"
                    icon="trailing"
                    iconType="arrow-up-fill"
                    iconSize={20}
                    iconColor="#7F56D9"
                    hierarchy="tertiary_color"
                    onClick={() => {
                      setCommentOpen(!commentOpen);
                    }}
                  />
                )}
              </div>
              {commentData.user?.nickname === login.nickname && (
                <div className="bottom__left">
                  <NormalButton
                    title={edit ? "저장" : "수정"}
                    buttonStyle="box"
                    size="xs"
                    hierarchy="secondary_gray"
                    onClick={() => {
                      if (edit) {
                        if (reply) {
                          _patchReply();
                        } else if (reReply) {
                        } else {
                          _patchComment();
                        }
                      }
                      setEdit(!edit);
                    }}
                  />
                  <NormalButton
                    title="삭제"
                    buttonStyle="box"
                    size="xs"
                    hierarchy="quaternary"
                    onClick={() => {
                      if (reply) {
                        dispatch(
                          modalSetting({
                            open: !modal.open,
                            type: "danger",
                            title: "삭제하기",
                            description: "댓글을 삭제하시겠습니까?",
                            confirmButton: "삭제하기",
                            confirmStyle: "red",
                            onConfirm: _deleteReply,
                            alert: false,
                          })
                        );
                      } else if (reReply) {
                      } else {
                        dispatch(
                          modalSetting({
                            open: !modal.open,
                            type: "danger",
                            title: "삭제하기",
                            description: "댓글을 삭제하시겠습니까?",
                            confirmButton: "삭제하기",
                            confirmStyle: "red",
                            onConfirm: _deleteComment,
                            alert: false,
                          })
                        );
                      }
                    }}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {open && (
        <CommentBox
          title="답글 작성"
          value={value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setValue(e.target.value);
          }}
          placeHolder="저작권 등 다른 사람의 권리를 침해하거나 명예를 훼손하는 게시물은 이용약관 및 관련 법률에 의해 제재를 받을 수 있습니다.&#13;&#10;건전한 토론 문화와 양질의 댓글 문화를 위해, 타인에게 불쾌감을 주는 욕설 또는 특정 계층/민족, 종교 등을 비하하는 단어들은 표시가 제한 됩니다."
          limit={200}
          reply={reply}
          loggedInUser={login.login}
          onClick={() => {
            if (value !== null && value !== undefined && value !== "") {
              onClick();
              setOpen(false);
            } else {
              window.alert(`내용을 작성해주세요.`);
            }
          }}
        />
      )}
    </>
  );
};

export default Comment;
