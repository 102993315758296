import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, ModalState, termsSettings, TermsState } from '@store/index';
import Icon from "../../../shared/Icon";
import './termsModal.scss';

const TermsModal = (props: any) => {
  const dispatch = useDispatch();
  const modal: ModalState = useSelector((state:RootState)=> state.modal);
  const termsRedux: TermsState = useSelector((state:RootState)=> state.terms);
  const [originData,setOriginData] = useState<any>({terms:props.terms, pp: props.pp})

  const _closeHandler = async() => {
    if(modal.type === "terms" && !termsRedux.terms) dispatch(termsSettings({terms: false, pp: termsRedux.pp}));
    else if(modal.type === "pp" && !termsRedux.pp) dispatch(termsSettings({terms: termsRedux.terms, pp: false}));
    else dispatch(termsSettings(originData));
    await props.close();
  }

  return (
    <div className="dim terms-modal">
      <div className="modal-container">
        {props.close && 
          <Icon className="modal-close x" icon="x" size={24} color="#101828" onClick={() => {
            _closeHandler();
          }}/>
        }
        {props.content && props.content}
      </div>
    </div>
  );
};

export default TermsModal;