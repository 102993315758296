import {
  MainLayout,
  MetaTag,
  NotFound,
  SubLayout,
  MypageLayout,
  WebviewLayout,
  KioskLayout,
  IntroLayout,
  HotelLayout,
  GoodsLayout,
  RestaurantLayout,
  ConcertLayout,
  MilestoneLayout,
} from "@components/index";
import {
  EventEnd,
  EventPage,
  Detail,
  EventProceeding,
  EventWinnerAnnouncement,
  FAQ,
  CommunityBoard,
  InquiryPage,
  InquiryTemporaryPage,
  Login,
  NoticePage,
  Register,
  RegisterDone,
  UpdatePage,
  InquiryWrite,
  Write,
  TermsPage,
  PersonalPolicy,
  SettingProfile,
  SettingAvatar,
  World,
  Club,
  Download,
  Home,
  WorldDetail,
  Mypage,
  MyCollection,
  MyShop,
  MyTransaction,
  MyClub,
  MyInquiry,
  EarnedZemi,
  ChargeZemi,
  ExchangeZemi,
  WebviewWorldDetail,
  WebviewWorld,
  KioskMain,
  KioskOrder,
  KioskOrderComplete,
} from "@pages/index";
import { RouteObject } from "react-router-dom";
import GoodsList from "@pages/Webview/Goods/GoodsList";
import GoodsComplete from "@pages/Webview/Goods/GoodsComplete";
import GoodsDetail from "@pages/Webview/Goods/GoodsDetail";
import GoodsOrder from "@pages/Webview/Goods/GoodsOrder";
import RestaurantComplete from "@pages/Webview/Restaurant/RestaurantComplete";
import RestaurantOrder from "@pages/Webview/Restaurant/RestaurantOrder";
import HotelComplete from "@pages/Webview/hotel/HotelComplete";
import HotelDetail from "@pages/Webview/hotel/HotelDetail";
import HotelList from "@pages/Webview/hotel/HotelList";
import HotelOrder from "@pages/Webview/hotel/HotelOrder";
import Travel from "@pages/Webview/Milestone/Travel";
import Place from "@pages/Webview/Milestone/Place";
import PlaceDetail from "@pages/Webview/Milestone/PlaceDetail";

const ir = [
  {
    path: "concert",
    element: <ConcertLayout />,
  },
  {
    path: "hotel",
    element: <HotelLayout />,
    children: [
      { path: "", element: <HotelList /> },
      { path: "detail", element: <HotelDetail /> },
      { path: "order", element: <HotelOrder /> },
      { path: "complete", element: <HotelComplete /> },
    ],
  },
  {
    path: "restaurant",
    element: <RestaurantLayout />,
    children: [
      { path: "", element: <RestaurantOrder /> },
      { path: "complete", element: <RestaurantComplete /> },
    ],
  },
  {
    path: "intro",
    element: <IntroLayout />,
  },
  {
    path: "goods",
    element: <GoodsLayout />,
    children: [
      { path: "", element: <GoodsList /> },
      { path: "detail", element: <GoodsDetail /> },
      { path: "order", element: <GoodsOrder /> },
      { path: "complete", element: <GoodsComplete /> },
    ],
  },
  {
    path: "milestone",
    element: <MilestoneLayout />,
    children: [
      { path: "travel", element: <Travel /> },
      {
        path: "place",
        element: <Place />,
      },
      { path: "detail", element: <PlaceDetail /> },
    ],
  },
];

const path: RouteObject[] = [
  {
    path: "",
    element: <MainLayout />,
    children: [
      {
        path: "",
        element: <SubLayout />,
        children: [
          { path: "", element: <Home /> },
          // { path: "world", element: <World /> },
          { path: "world/:id", element: <WorldDetail /> },
          { path: "club", element: <Club /> },
          { path: "download", element: <Download /> },
          {
            path: "news",
            children: [
              { path: "notice", element: <NoticePage /> },
              { path: "notice/:id", element: <Detail /> },
              { path: "update", element: <UpdatePage /> },
              { path: "update/:id", element: <Detail /> },
              { path: "event", element: <EventPage /> },
              { path: "event/:id", element: <Detail /> },
              // { path: "event/proceeding", element: <EventProceeding /> },
              // { path: "event/end", element: <EventEnd /> },
              // { path: "event/announcement", element: <EventWinnerAnnouncement /> },
            ],
          },
          {
            path: "community",
            children: [
              { path: "free-board", element: <CommunityBoard /> },
              { path: "free-board/:id", element: <Detail /> },
              { path: "free-board/write", element: <Write /> },
              { path: "tip-board", element: <CommunityBoard /> },
              { path: "tip-board/:id", element: <Detail /> },
              { path: "tip-board/write", element: <Write /> },
              { path: "screenshot-board", element: <CommunityBoard /> },
              { path: "screenshot-board/:id", element: <Detail /> },
              { path: "screenshot-board/write", element: <Write /> },
            ],
          },
          {
            path: "customer-service",
            children: [
              { path: "faq", element: <FAQ /> },
              { path: "inquiry", element: <InquiryTemporaryPage /> },
            ],
          },
          { path: "terms", element: <TermsPage /> },
          { path: "policy", element: <PersonalPolicy /> },
          { path: "*", element: <NotFound /> },
        ],
      },
    ],
  },
  {
    path: "",
    children: [
      { path: "login", element: <Login /> },
      { path: "register", element: <Register /> },
      { path: "register/done", element: <RegisterDone /> },
      { path: "terms2", element: <TermsPage /> },
      { path: "policy2", element: <PersonalPolicy /> },
      { path: "news/notice2", element: <NoticePage /> },
      { path: "news/notice2/:id", element: <Detail /> },
    ],
  },
  {
    path: "webview",
    element: <WebviewLayout />,
    children: [
      { path: "worlds", element: <WebviewWorld /> },
      { path: "worlds/:id", element: <WebviewWorldDetail /> },
    ],
  },
  {
    path: "webview",
    element: <WebviewLayout />,
    children: [
      { path: "ir-worlds", element: <WebviewWorld /> },
      { path: "ir-worlds/:id", element: <WebviewWorldDetail /> },
    ],
  },
  {
    path: "kiosk",
    element: <KioskLayout />,
    children: [
      { path: "", element: <KioskMain /> },
      { path: "order", element: <KioskOrder /> },
      { path: "complete", element: <KioskOrderComplete /> },
    ],
  },
  ...ir,
];

const loginPath: RouteObject[] = [
  {
    path: "",
    element: <MainLayout />,
    children: [
      {
        path: "",
        element: <SubLayout />,
        children: [
          { path: "", element: <Home /> },
          // { path: "world", element: <World /> },
          { path: "world/:id", element: <WorldDetail /> },
          { path: "club", element: <Club /> },
          { path: "download", element: <Download /> },
          {
            path: "news",
            children: [
              { path: "notice", element: <NoticePage /> },
              { path: "notice/:id", element: <Detail /> },
              { path: "update", element: <UpdatePage /> },
              { path: "update/:id", element: <Detail /> },
              { path: "event", element: <EventPage /> },
              { path: "event/:id", element: <Detail /> },
            ],
          },
          {
            path: "community",
            children: [
              { path: "free-board", element: <CommunityBoard /> },
              { path: "free-board/:id", element: <Detail /> },
              { path: "free-board/write", element: <Write /> },
              { path: "tip-board", element: <CommunityBoard /> },
              { path: "tip-board/:id", element: <Detail /> },
              { path: "tip-board/write", element: <Write /> },
              { path: "screenshot-board", element: <CommunityBoard /> },
              { path: "screenshot-board/:id", element: <Detail /> },
              { path: "screenshot-board/write", element: <Write /> },
            ],
          },
          {
            path: "customer-service",
            children: [
              { path: "faq", element: <FAQ /> },
              { path: "inquiry", element: <InquiryTemporaryPage /> },
              // 차후 업데이트 필요함
              // { path: "inquiry/:id", element: <Detail /> },
              // { path: "inquiry/write", element: <InquiryWrite /> }
            ],
          },
          { path: "terms", element: <TermsPage /> },
          { path: "policy", element: <PersonalPolicy /> },
          // { path: "register/done", element: <RegisterDone /> },
          { path: "*", element: <NotFound /> },
        ],
      },
      {
        path: "mypage",
        element: <MypageLayout />,
        children: [
          { path: "", element: <Mypage /> },
          { path: "collection", element: <MyCollection /> },
          { path: "shop", element: <MyShop /> },
          { path: "transaction", element: <MyTransaction /> },
          { path: "club", element: <MyClub /> },
          {
            path: "calculate",
            children: [
              { path: "earn", element: <EarnedZemi /> },
              { path: "charge", element: <ChargeZemi /> },
              { path: "exchange", element: <ExchangeZemi /> },
            ],
          },
          {
            path: "setting",
            children: [
              { path: "profile", element: <SettingProfile /> },
              { path: "avatar", element: <SettingAvatar /> },
            ],
          },
          { path: "inquiry", element: <MyInquiry /> },
        ],
      },
      // { path: "mypage/:id", element: <MypageDetail />}
    ],
  },
  {
    path: "",
    children: [
      { path: "register/done", element: <RegisterDone /> },
      { path: "terms2", element: <TermsPage /> },
      { path: "policy2", element: <PersonalPolicy /> },
      { path: "news/notice2", element: <NoticePage /> },
      { path: "news/notice2/:id", element: <Detail /> },
    ],
  },
  {
    path: "webview",
    element: <WebviewLayout />,
    children: [
      { path: "worlds", element: <WebviewWorld /> },
      { path: "worlds/:id", element: <WebviewWorldDetail /> },
    ],
  },
  {
    path: "kiosk",
    element: <KioskLayout />,
    children: [
      { path: "", element: <KioskMain /> },
      { path: "order", element: <KioskOrder /> },
      { path: "complete", element: <KioskOrderComplete /> },
    ],
  },
  ...ir,
];

const boardHeader = [
  {
    path: "notice",
    boardTitle: [
      { id: 0, title: "순서" },
      { id: 1, title: "구분" },
      { id: 2, title: "제목" },
      { id: 3, title: "작성일" },
      { id: 4, title: "조회수" },
    ],
    boardCategory: [
      { id: 0, title: "전체" },
      { id: 1, title: "공지" },
      { id: 2, title: "점검" },
      { id: 3, title: "상점" },
    ],
  },
  {
    path: "update",
    boardTitle: [],
    boardCategory: [],
  },
  {
    path: "event",
    boardTitle: [],
    boardCategory: [
      { id: 0, title: "전체" },
      { id: 1, title: "진행" },
      { id: 2, title: "예정" },
      { id: 3, title: "종료" },
    ],
  },
  {
    path: "free-board",
    boardTitle: [
      { id: 0, title: "순서" },
      { id: 1, title: "제목" },
      { id: 2, title: "작성자" },
      { id: 3, title: "작성일" },
      { id: 4, title: "댓글수" },
      { id: 5, title: "좋아요" },
      { id: 6, title: "신고" },
      { id: 7, title: "조회수" },
    ],
    boardCategory: [
      { id: 0, title: "전체" },
      { id: 1, title: "조회수" },
      { id: 2, title: "좋아요수" },
      { id: 3, title: "댓글수" },
    ],
  },
  {
    path: "tip-board",
    boardTitle: [
      { id: 0, title: "순서" },
      { id: 1, title: "제목" },
      { id: 2, title: "작성자" },
      { id: 3, title: "작성일" },
      { id: 4, title: "댓글수" },
      { id: 5, title: "좋아요" },
      { id: 6, title: "신고" },
      { id: 7, title: "조회수" },
    ],
    boardCategory: [
      { id: 0, title: "전체" },
      { id: 1, title: "조회수" },
      { id: 2, title: "좋아요수" },
      { id: 3, title: "댓글수" },
    ],
  },
  {
    path: "screenshow-board",
    boardTitle: [],
    boardCategory: [
      { id: 0, title: "전체" },
      { id: 1, title: "조회수" },
      { id: 2, title: "좋아요수" },
      { id: 3, title: "댓글수" },
    ],
  },
  {
    path: "report",
    boardTitle: [
      { id: 0, title: "순서" },
      { id: 1, title: "구분" },
      { id: 2, title: "신고 원문" },
      { id: 3, title: "작성자" },
      { id: 4, title: "신고 횟수" },
      { id: 5, title: "처리상태" },
    ],
    boardCategory: [],
  },
  {
    path: "faq",
    boardTitle: [],
    boardCategory: [
      { id: 0, title: "전체" },
      { id: 1, title: "ZEMIVERSE" },
      { id: 2, title: "Studio" },
    ],
  },
  {
    path: "inquiry",
    boardTitle: [
      { id: 0, title: "순서" },
      { id: 1, title: "구분" },
      { id: 2, title: "제목" },
      { id: 3, title: "작성자" },
      { id: 4, title: "작성일" },
      { id: 5, title: "처리상태" },
    ],
    boardCategory: [
      { id: 0, title: "전체" },
      { id: 1, title: "문의" },
      { id: 2, title: "버그 제보" },
      { id: 3, title: "신고" },
    ],
  },
  {
    path: "list",
    boardTitle: [
      { id: 0, title: "순서" },
      { id: 1, title: "E-mail" },
      { id: 2, title: "소셜" },
      { id: 3, title: "닉네임" },
      { id: 4, title: "계정 연동" },
      { id: 5, title: "연락처" },
      { id: 6, title: "최종 접속일" },
      { id: 7, title: "접속 횟수" },
      { id: 8, title: "상태" },
    ],
    boardCategory: [],
  },
  {
    path: "account",
    boardTitle: [
      { id: 0, title: "구분" },
      { id: 1, title: "ID" },
      { id: 2, title: "담당자명" },
      { id: 3, title: "최근접속일" },
      { id: 4, title: "관리" },
    ],
    boardCategory: [],
  },
];

export { path, loginPath, boardHeader };
