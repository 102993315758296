import classNames from "classnames";
import { categories } from "./constant";

const WebviewCategory = ({ category, setCategory }) => {
  return (
    <div className="webview-category sticky">
      <ul className="category__inner">
        {Object.entries(categories).map(([path, name]: any) => (
          <li
            key={path}
            className={classNames({ on: category === path })}
            onClick={() => {
              setCategory(path);
            }}
          >
            {name}
          </li>
        ))}
      </ul>
    </div>
  );
};
export default WebviewCategory;
