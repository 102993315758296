import { REACT_APP_COOKIE } from "./config";

export const setCookie = (name: string, value: string, exdays: number | null) => {
  let expires = `expires=Thu, 01 Jan 1970 00:00:00 UTC;`;

  if (exdays) {
    const now = new Date();
    now.setTime(now.getTime() + exdays * 24 * 60 * 60 * 1000);
    expires = `expires=${now.toUTCString()}`;
  }

  document.cookie = `${name}=${value};${expires};path=/;domain=.${REACT_APP_COOKIE};`;
  // console.log(`${name}=${value};${expires};path=/;domain=.${REACT_APP_COOKIE}`);
};

export default setCookie;
