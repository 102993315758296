import { useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import { WebviewHeader, RecentKeyword } from "@components/Webview";
import "./webviewLayout.scss";

const WebviewLayout = () => {
  const { id } = useParams();
  const [activeSearch, setActiveSearch] = useState(false);
  const hasRecentSearch = true;

  const recentKeywordContent = (
    <ul className="webview-recent_keyword">
      <RecentKeyword key={1} />
      <RecentKeyword key={2} />
      <RecentKeyword key={3} />
    </ul>
  );

  return (
    <div className="webview container">
      {!id && <WebviewHeader activeSearch={activeSearch} setActiveSearch={setActiveSearch} />}
      <main>{activeSearch && hasRecentSearch ? recentKeywordContent : <Outlet />}</main>
    </div>
  );
};

export default WebviewLayout;
