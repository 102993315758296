import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { NormalButton } from '@components/index';

import './notFound.scss';

const NotFound = () => {

  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <div className="content not-found">
      <div className="image"></div>
      <p className="title">{t("not_found_title")}</p>
      <p className="description">{t("not_found_description")}</p>
      <div className="box-buttons">
        <NormalButton
          title={t("not_found_button1")}
          hierarchy="primary_line"
          onClick={() => {
            navigate(-1);
          }}
          buttonStyle="round"
          size="xl"
        />
        <NormalButton
          title={t("not_found_button2")}
          hierarchy="primary"
          onClick={() => {
            navigate(`/`);
          }}
          buttonStyle="round"
          size="xl"
        />
      </div>
    </div>
  );
};

export default NotFound;