import { useState } from "react";
import classNames from "classnames";
import { GOODS_DATA } from "./placehoder-data";
import { useNavigate } from "react-router-dom";
import { numberWithCommas } from "@utils/numberWithCommas";
import { ReactComponent as CartIcon } from "./icon/cart.svg";

const GoodsList = () => {
  const navigate = useNavigate();
  const category = [
    { id: 0, title: "TOTAL" },
    { id: 1, title: "MUSIC" },
    { id: 2, title: "PHOTO" },
    { id: 3, title: "CONCERT" },
    { id: 4, title: "LIVING" },
    { id: 5, title: "BEAUTY" },
  ];
  const [selected, setSelected] = useState(0);

  return (
    <>
      <ul className="menu-category">
        {category.map((data: { id: number; title: string }, idx: number) => (
          <li
            className={classNames({ on: data.id === selected }, "goods-category-color")}
            onClick={() => {
              setSelected(data.id);
            }}
            key={idx}
          >
            {data.title}
          </li>
        ))}
      </ul>
      <div className="box-kiosk-list">
        {GOODS_DATA.map((data: any, idx: number) => (
          <div
            className={classNames("goods-relative", "list-kiosk")}
            onClick={() => {
              navigate(`/goods/detail`, { state: { id: data.id } });
            }}
            key={idx}
          >
            <div className={classNames("goods-border", "box-img")}>
              <img src={data.imgUrl} alt="" />
              <div className="cart-icon">
                <CartIcon width="40" height="40" />
              </div>
            </div>
            <p className={classNames("title-en", "goods-singer")}>{data.nameEn}</p>
            <p className={classNames("title-ko", "goods-text")}>{data.nameKo}</p>
            <p className={classNames("price", "goods-singer")}>
              {String(numberWithCommas(data.price))}원
            </p>
          </div>
        ))}
      </div>
    </>
  );
};

export default GoodsList;
