import { RootState, SearchModalState, searchModalSetting } from "@store/index";
import { useSelector, useDispatch } from "react-redux";

import Icon from "../../../shared/Icon";

interface SearchListProps {
  deleteSearchValue?: any;
  data: any;
  setSearchValue: any;
}

const SearchList = ({ data, deleteSearchValue, setSearchValue }: SearchListProps) => {
  const dispatch = useDispatch();

  const searchModal: SearchModalState = useSelector((state: RootState) => state.searchModal);

  return (
    <div className="search__list">
      <div
        className="start"
        onClick={() => {
          setSearchValue(data.text);
          dispatch(searchModalSetting(!searchModal.open));
        }}
      >
        <Icon className="icon clock" icon="clock" size={16} color="#98A2B3" />
        <p>{data.text}</p>
      </div>

      <Icon
        className="x delete__button"
        icon="x"
        size={24}
        color="#D0D5DD"
        onClick={() => {
          deleteSearchValue(data.id);
        }}
      />
    </div>
  );
};

export default SearchList;
