import { useState } from "react";

import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { PageTop, CategoryBox } from "@components/index";

import './faq.scss';

const FAQ = () => {

  const { t } = useTranslation();

  const [open, setOpen] = useState(-1);
  const [category, setCategory] = useState(0);

  const CategoryListData = [
    { id: 0, title: t("faq_category1") },
    { id: 1, title: t("faq_category2") },
    { id: 2, title: t("faq_category3") },
    { id: 3, title: t("faq_category4") },
  ];

  const BoardList = (data: any, idx: number) => {
    return(
      <li className={classNames("board__list", {on : data.idx === open})}>
        <p 
          className={classNames("title", {on : data.idx === open})}
          onClick={() => {
            if (data.idx === open) {
              setOpen(0);
            } else {
              setOpen(data.idx);
            }
          }}
        >Q. {t(`faq_content${data.idx + 1}_title`)}</p>
        <p className="description">A. {t(`faq_content${data.idx + 1}_description`)}</p>
      </li>
    );
  }

  return (
    <div className="container faq">
      <div className="faq__inner">
        <PageTop title={t('faq_title')} />
        <CategoryBox data={CategoryListData} category={category} setCategory={setCategory} />
        <ul className="board">
          {[...Array(17)].map((data: any, idx: number) => (
            <BoardList data={data} idx={idx} key={idx} />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default FAQ;