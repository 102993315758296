import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isBrowser } from "react-device-detect";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { UseAvatar, CheckBox, NormalButton, Loading, LocationLogo } from "@components/index";
import { patchNickname, getAvatarList, postMainAvatar, getNicknameDuplication } from "@apis/login";
import {
  RootState,
  LoadingState,
  termsSettings,
  TermsState,
  modalRegisterSetting,
  loginSetting,
  profileSetting,
  loadingSetting,
} from "@store/index";
import { checkNickname } from "@utils/index";
import { usehttp, useSelect } from "@hooks/index";

import "./register.scss";

/**
 * /register - 가입 페이지
 */
const Register = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [errorLog, setErrorLog] = useState("");

  const [nickname, setNickname] = useState("");
  const [log, setLog] = useState(0); // 1) 사용불가 2) 사용중 3) 사용가능

  const [isCheck, setIsCheck] = useState(false);
  const [avatarList, setAvatarList] = useState([]);

  const selectData = useSelect(
    avatarList === null || avatarList === undefined || avatarList?.length <= 0 ? null : avatarList[0]
  );

  const { isLoading, sendRequest } = usehttp();

  const loading: LoadingState = useSelector((state: RootState) => state.loading);
  const terms: TermsState = useSelector((state: RootState) => state.terms);

  const termsLabel = (
    <p onClick={() => _checkboxHandler(`terms`)}>
      <span>{t("register_terms")}</span>
      {t("register_agreed")}
    </p>
  );
  const ppLabel = (
    <p onClick={() => _checkboxHandler(`pp`)}>
      <span>{t("register_pp")}</span>
      {t("register_agreed")}
    </p>
  );

  const _patchNickname = async () => {
    const { data: result } = await sendRequest(patchNickname, {
      nickname: nickname,
    });

    console.log(result);

    if (result?.error) {
      return;
    } else {
      _postMainAvatar();
      dispatch(
        profileSetting({
          nickname: result.data.nickname,
          picture: result.data.profileImageUrl,
          sns: result.data.platform === 1 ? "google" : result.data.platform === 2 ? "apple" : "facebook",
          email: result.data.email,
          id: result.data.id,
        })
      );
      dispatch(loginSetting(true));
      navigate(`/register/done`);
    }
  };

  const _getAvatarList = async () => {
    dispatch(loadingSetting(true));
    const { data: result } = await sendRequest(getAvatarList, {});

    console.log(result);

    if (result?.error) {
      window.alert(result.code);
      return;
    } else {
      setAvatarList(result?.data?.list);
    }
    dispatch(loadingSetting(false));
  };

  const _postMainAvatar = async () => {
    const { data: result } = await sendRequest(postMainAvatar, {
      CHGUID: selectData.value?.CHGUID,
    });

    console.log(result);

    if (result?.error) {
      window.alert(result.code);
      return;
    } else {
      // setAvatarList(result?.data?.list);
    }
  };

  const _checkboxHandler = (type: string) => {
    console.log(`terms >>>>>`, terms);
    if (type === "terms") {
      terms.terms
        ? dispatch(termsSettings({ ...terms, terms: !terms.terms }))
        : dispatch(modalRegisterSetting({ open: true, type: "terms" }));
    } else if (type === "pp") {
      terms.pp
        ? dispatch(termsSettings({ ...terms, pp: !terms.pp }))
        : dispatch(modalRegisterSetting({ open: true, type: "pp" }));
    }
  };

  const _handleCheckNickname = () => {
    // console.log("checkNickname(nickname)", checkNickname(nickname));
    if (nickname === "" || nickname === null || nickname === undefined) {
      setLog(0);
    } else {
      if (checkNickname(nickname)) {
        // -를 뺀 특수문자 사용하면 안됨
        _getNicknameDuplication();
      } else {
        setLog(1);
      }
    }
  };

  // get nickname duplication 닉네임 중복 확인
  const _getNicknameDuplication = async () => {
    const { data: result } = await sendRequest(getNicknameDuplication, {
      nickname: nickname,
    });

    console.log(`_getNicknameDuplication`, result);

    if (result?.error) {
      window.alert(result.code);
      return;
    } else {
      if (result?.result) {
        setLog(2);
      } else {
        setLog(3);
      }
    }
  };

  useEffect(() => {
    if (terms.terms && terms.pp) {
      if (nickname !== "" || nickname !== undefined || nickname !== null) {
        if (log === 3) {
          setIsCheck(true);
        } else {
          setIsCheck(false);
        }
      } else {
        setIsCheck(false);
      }
    } else {
      setIsCheck(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [terms, nickname, log]);

  useEffect(() => {
    // _getAvatarList();
    dispatch(termsSettings({ terms: false, pp: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="container register">
        <div className="register__inner">
          <div className="register__content">
            <div className="register__header">
              <div
                className="logo"
                onClick={() => {
                  navigate(`/`);
                }}
              ></div>
              <p>{t("register_almost_done")}</p>
            </div>
            <UseAvatar avatarList={avatarList} selectData={selectData} />
            <div className="divider"></div>
            <div className="nickname__container">
              <div className="search_input">
                <input
                  type="text"
                  maxLength={10}
                  value={nickname}
                  placeholder="닉네임을 입력해주세요."
                  onChange={(e) => {
                    setNickname(e.target.value);
                  }}
                  onBlur={() => _handleCheckNickname()}
                />
              </div>
              {log !== 0 && (
                <p className={classNames("log", log === 1 ? "error" : log === 2 ? "warn" : "info")}>
                  {log === 1
                    ? "입력된 닉네임은 사용불가 합니다."
                    : log === 2
                    ? "입력된 닉네임은 사용중입니다."
                    : "입력된 닉네임은 사용가능 합니다."}
                </p>
              )}
              <div className="nickname__explanation">
                <p>{t("register_check1")}</p>
                <p>{t("register_check2")}</p>
              </div>
            </div>
          </div>
          <div className="register__footer">
            <CheckBox
              name="terms-modal"
              onChange={() => _checkboxHandler("terms")}
              checked={terms.terms}
              label={termsLabel}
              terms={terms}
            />
            <CheckBox
              name="pp-modal"
              onChange={() => _checkboxHandler("pp")}
              checked={terms.pp}
              label={ppLabel}
              terms={terms}
            />
          </div>
          {errorLog && <p className="register__error">{errorLog}</p>}
          <NormalButton
            title={t("register_continue")}
            hierarchy="primary"
            buttonStyle="round"
            size="md"
            width={isBrowser ? "160px" : "100%"}
            // height="40px"
            disable={!isCheck || nickname === null || nickname === undefined || nickname === ""}
            onClick={() => {
              if (isCheck) {
                _patchNickname();
              }
            }}
          />
        </div>
      </div>
      {loading.isLoading ? (
        <div className="dim check-account">
          <div className="modal">
            <Loading />
            <LocationLogo />
            <p className="title">계정 연동 정보 확인중입니다.</p>
            <p className="description">
              재미버스 회원이 되시면
              <br />
              모든 서비스를 이용하실 수 있습니다.
            </p>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Register;
