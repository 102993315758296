export const HOTEL_DATA = [
  {
    id: 1,
    nameEn: "오션브릿지 더블",
    price: 150000,
    imgUrl: require("./images/1.png"),
  },
  {
    id: 2,
    nameEn: "오션브릿지 패밀리 트윈",
    price: 200000,
    imgUrl: require("./images/2.png"),
  },
  {
    id: 3,
    nameEn: "오션스카이 더블",
    price: 175000,
    imgUrl: require("./images/3.png"),
  },
  {
    id: 4,
    nameEn: "오션테라스 더블",
    price: 150000,
    imgUrl: require("./images/4.png"),
  },
  {
    id: 5,
    nameEn: "오션시티 더블",
    price: 175000,
    imgUrl: require("./images/5.png"),
  },
  {
    id: 6,
    nameEn: "오션시티 패밀리 트윈",
    price: 200000,
    imgUrl: require("./images/6.png"),
  },
];
