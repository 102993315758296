const LOGIN_SETTING = "login/LOGIN_SETTING" as const;
const PROFILE_SETTING = "login/PROFILE_SETTING" as const;
const PROFILE_IMG_SETTING = "login/PROFILE_IMG_SETTING" as const;

type LoginAction =
  | ReturnType<typeof loginSetting>
  | ReturnType<typeof profileSetting>
  | ReturnType<typeof profileImgSetting>;

export const loginSetting = (diff: boolean) => ({
  type: LOGIN_SETTING,
  payload: diff,
});

export const profileSetting = (diff: {
  nickname: string;
  picture: string;
  sns: string;
  email: string;
  id: string;
}) => ({
  type: PROFILE_SETTING,
  payload: diff,
});

export const profileImgSetting = (diff: string) => ({
  type: PROFILE_IMG_SETTING,
  payload: diff,
});

export type LoginState = {
  login: boolean;
  sns: string;
  email: string;
  nickname: string;
  picture: string;
  accessToken: string;
  id: string;
};

const initialState: LoginState = {
  login: false,
  sns: "",
  email: "",
  nickname: "",
  picture: "",
  accessToken: "",
  id: "",
};

function login(state: LoginState = initialState, action: LoginAction): LoginState {
  switch (action.type) {
    case LOGIN_SETTING:
      return { ...state, login: action.payload };
    case PROFILE_SETTING:
      return {
        ...state,
        nickname: action.payload.nickname,
        picture: action.payload.picture,
        sns: action.payload.sns,
        email: action.payload.email,
        id: action.payload.id,
      };
    case PROFILE_IMG_SETTING:
      return { ...state, picture: action.payload };
    default:
      return state;
  }
}

export default login;
