// page > Zemiverse > Detail > WorldDetail > WorldDetail.tsx
export const ENTER_LAND_DETAIL = {
  category: "OFFICIAL",
  createdAt: "2024-01-04T06:38:13.704Z",
  updatedAt: "2024-01-04T06:38:13.704Z",
  id: "123",
  likeByMe: false,
  likeCount: 0,
  mapGUID: "111",
  playCount: 0,
  status: "ALIVE",
  summary: "스타와 팬들이 함께 즐기는 다양한 콘텐츠와 현실과 가상의 여행이 연결되는 드림랜드입니다.",
  thumbnailImageUrl: ["https://zemiverse.s3.ap-northeast-2.amazonaws.com/images/2023-10-31/1698719027854_105"],
  title: "엔터 랜드",
  content: `
	[소개]
	- 엔터테인먼트 : 스타와 팬이 함께 할 수 있는 공연장, 팬미팅, Goods Store 등 다양한 콘텐츠가 제공됩니다.
	
	
	[엔터의 특징]
	- 공연장 : 스타의 공연을 관람할 수 있습니다. 
	- 팬미팅 : 공연이 끝나고 스타와 팬들이 싸인, 기념촬영 등 다양한 함께 상호작용을 할 수 있습니다.
	- Store : 스타와 관련된 현실의 상품과 가상의 아이템을 구매할 수 있습니다 `,
  user: {
    id: "176bd9cc-5117-4b36-855b-745f60a96d5f",
    nickname: "재미버스",
    profileImageUrl: null,
  },
};

export const TRAVEL_LAND_DETAIL = {
  category: "OFFICIAL",
  createdAt: "2024-01-04T06:38:13.704Z",
  updatedAt: "2024-01-04T06:38:13.704Z",
  id: "124",
  likeByMe: false,
  likeCount: 0,
  mapGUID: "112",
  playCount: 0,
  status: "ALIVE",
  summary: "스타와 팬들이 함께 즐기는 다양한 콘텐츠와 현실과 가상의 여행이 연결되는 드림랜드입니다.",
  thumbnailImageUrl: ["https://zemiverse.s3.ap-northeast-2.amazonaws.com/images/2023-10-31/1698719027854_105"],
  title: "트레블 랜드",
  content: `
	[소개]
	- 여행 : 현실의 여행지를 그대로 구현해 놓은 가상의 여행지를 직접 탐험할 수 있으며 현실 여행시 필요한 숙소, 음식점을 선택하여 주문/예약 할 수 있습니다.
	
	[여행의 특징] 
	- 관광지 : 현실의 광안리 해변에서 즐길 수 있는 다양한 놀거리를 드림랜드에 구현되어 미리 체험해 볼 수 있습니다. 
	- 카페 : 메타버스에서 받은 보상 재화로 현실의 커피를 주문할 수 있습니다. 
	- 음식점 : 여행지에 있는 대표 음식점을 둘러보고 여행일자에 맞춰 미리 체험해보고 예약할 수 있습니다. 
	- 숙소 : 현실의 숙소를 직접 둘러보고 여행일자에 맞춰 예약할 수 있습니다.`,
  user: {
    id: "176bd9cc-5117-4b36-855b-745f60a96d5f",
    nickname: "재미버스",
    profileImageUrl: null,
  },
};

// pages > Zemiverse > Pages > Home > Home.tsx
// page > Webview > WebviewWorld.tsx
export const WORLD_TEMP_DATA = [
  {
    category: "OFFICIAL",
    createAt: "2024-01-04",
    id: "123",
    likeByMe: false,
    likeCount: 0,
    mapGUID: "111",
    playCount: 0,
    summary: "스타와 팬들이 함께 즐기는 다양한 콘텐츠와 현실과 가상의 여행이 연결되는 드림랜드입니다.",
    thumbnailImageUrl: ["https://zemiverse.s3.ap-northeast-2.amazonaws.com/images/2023-10-31/1698719027854_105"],
    title: "엔터 랜드",
    user: {
      id: "176bd9cc-5117-4b36-855b-745f60a96d5f",
      nickname: "재미버스",
      profileImageUrl: null,
    },
  },
  {
    category: "OFFICIAL",
    createAt: "2024-01-04",
    id: "124",
    likeByMe: false,
    likeCount: 0,
    mapGUID: "112",
    playCount: 0,
    summary: "스타와 팬들이 함께 즐기는 다양한 콘텐츠와 현실과 가상의 여행이 연결되는 드림랜드입니다.",
    thumbnailImageUrl: ["https://zemiverse.s3.ap-northeast-2.amazonaws.com/images/2023-10-31/1698719027854_105"],
    title: "트레블 랜드",
    user: {
      id: "176bd9cc-5117-4b36-855b-745f60a96d5f",
      nickname: "재미버스",
      profileImageUrl: null,
    },
  },
];
