import { ReactComponent as LocationPin } from "@assets/images/location-pin.svg";
import { NormalButton } from "@components/common";
import Icon from "@shared/Icon";
import { useNavigate } from "react-router-dom";
import { sendMessageMapGUID } from "./unitySender";

type WorldItemType = {
  category?: string;
  // category?: "recommend" | "popular" | "favorite" | "recent";
  rank?: number;
  title?: string;
  likeCount?: number;
  playCount?: number;
  thumbnailImageUrl?: string[];
  mapGUID?: string;
  id: string;
  currentLocation?: boolean;
};

const WorldItem = ({
  category,
  rank,
  title,
  likeCount,
  playCount,
  id,
  thumbnailImageUrl,
  mapGUID,
  currentLocation,
}: WorldItemType) => {
  // const [favorite, setFavorite] = useState(false);
  // const [like, setLike] = useState(false);
  const hasCancelBtn = category === "favorite" || category === "recent";
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(`/webview/worlds/${id}`, { state: { id } }); // param 없애기
  };

  const handleOnPlay = () => {
    if (window.vuplex) {
      sendMessageMapGUID(mapGUID);
    } else {
      window.addEventListener("vuplexready", sendMessageMapGUID);
    }
  };

  return (
    <div className="thumb">
      {category === "popular" && (
        <div className="popular-rank">
          <span>{rank}</span>
        </div>
      )}
      {currentLocation && (
        <div className="current-location">
          <div className="icon-wrapper">
            <LocationPin fill="#fff" stroke="#fff" />
          </div>
          <span>현재 위치</span>
        </div>
      )}
      {hasCancelBtn && (
        <div className="cancel-button">
          <Icon icon="x" size="100%" color="#fff" />
        </div>
      )}
      <div className="thumb-img" onClick={handleOnClick}>
        <img
          src={thumbnailImageUrl?.length > 0 ? thumbnailImageUrl[0] : ""}
          alt="world thumbnail"
          className={`${currentLocation ? "border-green" : ""}`}
        />
      </div>
      <div className="thumb-content">
        {category === "recent" && <p className="recent-date">1시간 전 참여</p>}
        <p className="world-title ellipsis">{title}</p>
        {category === "popular" && <p className="nickname ellipsis">크리에이터닉네임</p>}
        <div className="thumb-info">
          <div className="heart-count">
            <div className="icon-wrapper">
              <Icon className="icon-heart-fill" icon="heart-fill" size="100%" color="#98A2B3" />
            </div>
            <span>{likeCount}</span>
          </div>
          <div className="play-count">
            <div className="icon-wrapper">
              <Icon className="icon-play_fill" icon="play_fill" size="100%" color="#98A2B3" />
            </div>
            <span>{playCount}</span>
          </div>
        </div>
        <div className="thumb-buttons">
          {/* {category !== "favorite" && (
            <NormalButton
              hierarchy="app-secondary"
              icon="leading"
              iconType={`star${favorite ? "-fill" : ""}`}
              iconSize={16}
              iconColor={favorite ? "#FEC84B" : "#101828"}
              buttonStyle="box"
              size="sm"
              responsible
              onClick={() => {
                console.log("fav");
                setFavorite((prevFavorite) => !prevFavorite);
              }}
            />
          )}
          <NormalButton
            hierarchy="app-secondary"
            icon="leading"
            iconType={`heart${like ? "-fill-L" : ""}`}
            iconSize={16}
            iconColor={like ? "#F04438" : "#101828"}
            buttonStyle="box"
            size="xs"
            responsible
            onClick={() => {
              console.log("like");
              setLike((prevLike) => !prevLike);
            }}
          /> */}
          <NormalButton
            title="Play"
            hierarchy="app-primary"
            iconSize={16}
            iconColor="#1c72b8"
            buttonStyle="box"
            buttonClassName="play-button"
            size="xs"
            responsible
            onClick={() => {
              handleOnPlay();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default WorldItem;
