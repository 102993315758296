import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import kioskData from "../../../shared/kioskData.json";
import { numberWithCommas } from "@utils/numberWithCommas";
import imgList from "./imgData";
import classNames from "classnames";
import Icon from "../../../shared/Icon";

const Order = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pageId = location.state?.id;
  const [times, setTimes] = useState(1);
  const [temperature, setTemperature] = useState(0);

  const [data, setData] = useState<any>({
    id: 1,
    nameEn: "Americano",
    nameKo: "아메리카노",
    price: 2500,
    description:
      "[진함 고소함] 견과류 풍미와 초콜릿처럼 달콤 쌉싸름한 맛이 밸런스 있게 어우러진 균형잡힌 바디감의 커피",
  });

  useEffect(() => {
    if (pageId !== null || pageId !== undefined) {
      setData(kioskData.find((data) => data.id === pageId));
    }
  }, [pageId]);

  return (
    <>
      <div className="top-order">
        <div className="box-img">
          <img src={imgList[pageId - 1]} alt="" />
        </div>
        <div className="right">
          <p className="title-ko">{data?.nameKo}</p>
          <p className="title-en">{data?.nameEn}</p>
          <textarea value={data?.description + data?.description} readOnly />
          <p className="price">{String(numberWithCommas(data?.price))}원</p>
        </div>
      </div>

      <div className="box-buttons">
        <div
          className={classNames("button hot", { on: temperature === 0 })}
          onClick={() => {
            setTemperature(0);
          }}
        >
          <p>HOT</p>
        </div>
        <div
          className={classNames("button iced", { on: temperature === 1 })}
          onClick={() => {
            setTemperature(1);
          }}
        >
          <p>ICED</p>
        </div>
      </div>

      <div className="bottom-order">
        <div className="box-count">
          <div className="left">
            <div className="box-icon">
              <Icon
                className="minus"
                icon="minus"
                size="100%"
                color="#344054"
                onClick={() => {
                  if (times > 1) {
                    setTimes(times - 1);
                  }
                }}
              />
            </div>
            <p>{times}</p>
            <div className="box-icon">
              <Icon
                className="plus"
                icon="plus"
                size="100%"
                color="#344054"
                onClick={() => {
                  if (times < 10) {
                    setTimes(times + 1);
                  }
                }}
              />
            </div>
          </div>
          <p className="total-price">{numberWithCommas(data?.price * times)}원</p>
        </div>
        <div className="box-buttons">
          <div className="button-cart">
            <p>담기</p>
          </div>
          <div
            className="button-order"
            onClick={() => {
              const now = new Date();
              navigate(`/kiosk/complete`, {
                state: { id: data.id, temperature: temperature, times: times, now: now },
              });
            }}
          >
            <p>바로 주문</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Order;
