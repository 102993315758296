import { InputBox, NormalButton, SearchList } from "@components/index";
import { RootState, SearchModalState, searchModalSetting } from "@store/index";
import { useSelector, useDispatch } from "react-redux";

import "../modal.scss";

interface WorldSearchMobileModalProps {
  searchValueList?: any[];
  searchValue?: string | undefined;
  setSearchValue: Function;
  handleOnKeyPress?: React.KeyboardEventHandler<HTMLInputElement> | undefined;
  deleteAllSearchValue?: Function;
  deleteSearchValue?: Function;
}

const WorldSearchMobileModal = ({
  searchValueList,
  searchValue,
  setSearchValue,
  handleOnKeyPress,
  deleteAllSearchValue,
  deleteSearchValue,
}: WorldSearchMobileModalProps) => {
  const dispatch = useDispatch();

  const searchModal: SearchModalState = useSelector((state: RootState) => state.searchModal);

  return (
    <div className="dim search__modal world">
      <div className="top">
        <div
          className="button_back"
          onClick={() => {
            dispatch(searchModalSetting(!searchModal.open));
          }}
        ></div>
        <div className="box">
          <InputBox
            value={searchValue}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSearchValue(e.target.value);
            }}
            placeholder="월드를 입력하세요."
            onKeyPress={handleOnKeyPress}
            icon
            iconLocation="back"
            iconSize={24}
            iconName="globe-search"
            style="round"
          />
          {/* <div
            className={classNames("delete__searchvalue", {
              on: searchValue !== "" && searchValue !== undefined && searchValue !== null,
            })}
            onClick={() => {
              setSearchValue(``);
            }}
          ></div> */}
        </div>
      </div>
      <div className="bottom">
        <div className="top">
          {/* {searchValueList.length !== 0 && (
            <NormalButton
              title="전체삭제"
              size="sm"
              hierarchy="link_gray"
              buttonStyle="round"
              onClick={() => {
                deleteAllSearchValue();
              }}
            />
          )} */}
        </div>
        <div className="box">
          {searchValueList.length <= 0 ? (
            <p className="no_list">최근 검색 내역이 없습니다.</p>
          ) : (
            <>
              {searchValueList.map((data: any, id: number) => (
                <SearchList
                  key={id}
                  data={data}
                  deleteSearchValue={deleteSearchValue}
                  setSearchValue={setSearchValue}
                />
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default WorldSearchMobileModal;
