import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import classNames from "classnames";

import { useSelector, useDispatch } from "react-redux";

import { RootState, AlertModalState, alertModalSetting, GNBState, gnbSetting } from "@store/index";
import { LanguageBox } from "@components/index";

import "./footer.scss";

const Footer = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const alert: AlertModalState = useSelector((state: RootState) => state.alert);
  const gnb: GNBState = useSelector((state: RootState) => state.gnb);

  const snsMenu = [
    { id: 1, sns: "instagram", url: "https://www.instagram.com" },
    { id: 2, sns: "twitter", url: "https://www.twitter.com" },
    { id: 3, sns: "youtube", url: "https://www.youtube.com" },
    { id: 4, sns: "tiktok", url: "https://www.tiktok.com" },
  ];

  const footerMenu = [
    { id: 1, title: t("footer_menu1"), url: "/terms" },
    { id: 2, title: t("footer_menu2"), url: "/policy" },
    { id: 3, title: t("footer_menu3"), url: "/" },
    { id: 4, title: t("footer_menu4"), url: "/customer-service/inquiry" },
  ];

  return (
    <div className="footer">
      {/* 언어 */}
      <LanguageBox />

      {/* sns */}
      <ul className="sns_box">
        {snsMenu.map((data: any, idx: number) => (
          <li
            className={classNames("sns_button", data.sns)}
            onClick={() => {
              // window.open(data.url, `_blank`);
              dispatch(gnbSetting(false));
              dispatch(alertModalSetting({ open: !alert.open, title: t("modal_alert") }));
            }}
            key={idx}
          >
            {data.sns}
          </li>
        ))}
      </ul>

      {/* footer-menu */}
      <ul className="footer_menu">
        {footerMenu.map((data: any, idx: number) => (
          <li
            onClick={() => {
              if (data.id !== 3) {
                navigate(data.url);
              } else {
                dispatch(alertModalSetting({ open: !alert.open, title: t("modal_alert") }));
              }
            }}
            key={idx}
          >
            {data.title}
          </li>
        ))}
      </ul>

      {/* copyright */}
      <p className="copyright">{t("footer_copy")}</p>
    </div>
  );
};

export default Footer;
