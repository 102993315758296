import { useEffect, useState } from "react";
import { WorldItem, WebviewCategory } from "@components/Webview";
import useInfinityScroll from "@hooks/useInfinityScroll";
import useWebviewList from "@hooks/webview/useWebviewList";
import { getCategoryPath } from "@components/Webview/constant";
import { WORLD_TEMP_DATA } from "@apis/placeholder-data";
import { useLocation } from "react-router-dom";

const WebviewWorld = () => {
  const location = useLocation();
  const [filteredItems, setFilteredItems] = useState<any>([]);
  const [category, setCategory] = useState("recommend");
  const { fetchWebviewList, isLoading, webviewItems } = useWebviewList();
  const isIR = location.pathname === "/webview/ir-worlds";

  let popularRank = 0;
  let isFirstRecommend = true;

  useEffect(() => {
    if (webviewItems?.length === 0) return;
    const filteredData = webviewItems.filter((item) => getCategoryPath(item.category) === category);
    if (isIR) setFilteredItems(() => WORLD_TEMP_DATA);
    else setFilteredItems(() => filteredData);
  }, [category, webviewItems]);

  const observeRef = useInfinityScroll({
    callback: fetchWebviewList,
  });

  const itemsHTML = filteredItems.map((item, index) => {
    const setCurrentLocation = item.category === "recommend" && isFirstRecommend;

    if (item.category === "popular") {
      popularRank += 1;
      return <WorldItem key={item.id} category={item.category} rank={popularRank} {...item} />;
    }

    if (setCurrentLocation) {
      isFirstRecommend = false;
      return <WorldItem key={item.id} category={item.category} currentLocation={true} {...item} />;
    }
    return <WorldItem key={item.id} category={item.category} {...item} />;
  });

  return (
    <>
      <WebviewCategory category={category} setCategory={setCategory} />
      <div className="webview-list">
        {itemsHTML}
        <div ref={observeRef} className="observer" />
      </div>
    </>
  );
};

export default WebviewWorld;
