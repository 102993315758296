import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { updateDateToString } from "@shared/utils/index";

import "./updateList.scss";

export interface UpdateDataProps {
  data: any;
}

/**
 * 업데이트 리스트만 모양이 매우 달라 따로 컴포넌트화 함
 * @data list data (any)
 */
const UpdateList = ({ data }: UpdateDataProps) => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  return (
    <li
      className="board__list"
      onClick={() => {
        navigate(`/news/update/${data.id}`, { state: { id: data.id } });
      }}
    >
      <p className="date">{updateDateToString(data.assignedAt, "MM.dd", i18n.language)}</p>
      <div className="img__wrapper">
        <img src={`${data.thumbnailImageUrl}`} alt={data.title} />
      </div>
      <p>
        <span className="title">{data.title}</span>
        {data.summary}
      </p>
    </li>
  );
};

export default UpdateList;
