import { useNavigate } from "react-router-dom";
import { sendMessageExit } from "@components/Webview/unitySender";
import Icon from "@shared/Icon";
import classNames from "classnames";

const Place = () => {
  const navigate = useNavigate();

  const handleOnCancel = () => {
    console.log("exit");
    if (window.vuplex) {
      sendMessageExit();
    } else {
      window.addEventListener("vuplexready", sendMessageExit);
    }
  };

  return (
    <>
      <div className="milestone-header">
        <h1>여행지를 선택해주세요.</h1>
        <div className="concert-x-icon">
          <Icon className="x" icon="x" size="100%" color="#344054" onClick={handleOnCancel} />
        </div>
      </div>
      <div className="intro-outer">
        <div className="intro-inner">
          <div className={classNames("intro-wrapper", "kwanganlee")}>
            <p className="intro-title">광안리 체험</p>
            <button className="intro-button" onClick={() => navigate("/milestone/detail")}>
              Play
            </button>
          </div>
          <div className={classNames("intro-wrapper", "kimchon")}>
            <p className="intro-title">김천문화 마을</p>
            <button className="intro-button">Play</button>
          </div>
        </div>
        <div className="intro-inner">
          <div className={classNames("intro-wrapper", "haedong")}>
            <p className="intro-title">해동용궁사</p>
            <button className="intro-button">Play</button>
          </div>
          <div className={classNames("intro-wrapper", "file_festival")}>
            <p className="intro-title">BIFF 영화의 전당</p>
            <button className="intro-button">Play</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Place;
