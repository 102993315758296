import "@assets/css/foundation/date-picker.scss";
import { updateDateToString } from "@utils/updateDate";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { sendMessageExit } from "@components/Webview/unitySender";

const RestaurantComplete = () => {
  const { i18n, t } = useTranslation();
  const location = useLocation();
  const { selectedDate, headCount, selectedTime } = location.state;

  const handleOnCancel = () => {
    console.log("exit");
    if (window.vuplex) {
      sendMessageExit();
    } else {
      window.addEventListener("vuplexready", sendMessageExit);
    }
  };

  return (
    <>
      <div className="ir-restaurant-container">
        <p className="ir-restaurant-no">No.185200748</p>
        <h1 className="ir-restaurant-title">레스토랑 광안리점</h1>
        <div className="ir-restaurant-label">
          <span>예약 완료 / 이용 대기</span>
        </div>

        <h2 className="restaurant-subtitle">예약 정보</h2>
        <ul className="concert-list">
          <li className="concert-item">
            <div className="restaurant-item-label">일정</div>
            <p className="restaurant-item-info">
              {`${updateDateToString(
                new Date(selectedDate),
                "yyyy. MM. dd (E)",
                i18n.language
              )} ${selectedTime}`}
            </p>
          </li>

          <li className="concert-item">
            <div className="restaurant-item-label">인원</div>
            <p className="restaurant-item-info">{`${headCount}명`}</p>
          </li>
        </ul>

        <h2 className="restaurant-subtitle">예약자 정보</h2>
        <ul className="concert-list">
          <li className="concert-item">
            <div className="restaurant-item-label">이름</div>
            <p className="restaurant-item-info">전주천</p>
          </li>

          <li className="concert-item">
            <div className="restaurant-item-label">연락처</div>
            <p className="restaurant-item-info">010-1234-5678</p>
          </li>

          <li className="concert-item">
            <div className="restaurant-item-label">이메일</div>
            <p className="restaurant-item-info">Support@nextor.ai</p>
          </li>
        </ul>
      </div>

      <button className="restaurant-confirm-btn" onClick={handleOnCancel}>
        확인
      </button>
    </>
  );
};

export default RestaurantComplete;
