import { useEffect, useState } from "react";
import { useRoutes, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { PpModalPage, TermsModalPage } from "@pages/index";
import { TermsModal, ConfirmModal, ReportModal, StudioItemModal, AlertModal, WithdrawModal } from "@components/index";
import { getProfile } from "@apis/login";
import { usehttp } from "@hooks/index";
import { REACT_APP_COOKIE, getCookie } from "@utils/index";
import { getLastPath, getFirstPath } from "@shared/utils/index";
import { path, loginPath } from "@shared/config/router/path";
import {
  pathSetting,
  pathFirstSetting,
  LoginState,
  RootState,
  loginSetting,
  profileSetting,
  windowBottomSetting,
  windowWidthSetting,
  windowTopSetting,
  ModalState,
  modalOpenSetting,
  TermsState,
  AlertModalState,
} from "@store/index";

import { debounce } from "lodash";

import AOS from "aos";

import "aos/dist/aos.css";
import { AUTH_COOKIE } from "@shared/constants";

declare global {
  interface Window {
    // ⚠️ notice that "Window" is capitalized here
    vuplex: any;
  }
}

const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const alert: AlertModalState = useSelector((state: RootState) => state.alert);
  const login: LoginState = useSelector((state: RootState) => state.login);
  const modal: ModalState = useSelector((state: RootState) => state.modal);
  const terms: TermsState = useSelector((state: RootState) => state.terms);

  const { sendRequest } = usehttp();

  const [checkLogin, setcheckLogin] = useState(path);
  const [cookies, setCookies] = useState(getCookie(AUTH_COOKIE));

  const _modalClose = () => {
    dispatch(modalOpenSetting(false));
  };

  // 무한 로딩 방지하고 1초에 한번씩 사이즈 측정
  const handleResize = debounce(() => {
    // setWindowSize(window.innerWidth);
    document.body.style.overflow = "unset";
    dispatch(windowWidthSetting(window.innerWidth));
  }, 1000);

  const handleModeButton = () => {
    if (window.scrollY === 0) {
      dispatch(windowTopSetting(true));
    } else if (window.scrollY === document.documentElement.scrollHeight - document.documentElement.clientHeight) {
      dispatch(windowTopSetting(false));
      dispatch(windowBottomSetting(true));
      return;
    } else {
      dispatch(windowTopSetting(false));
      dispatch(windowBottomSetting(false));
      return;
    }
  };

  // 기존 유저일 경우 정보 저장
  const _getProfile = async () => {
    const { data: result } = await sendRequest(getProfile, {});

    console.log(result);

    if (result?.error) {
      document.cookie = `${AUTH_COOKIE}=;expires = Thu, 01 Jan 1970 00:00:00 UTC;path=/;domain=${REACT_APP_COOKIE};`;
    } else {
      if (result.setNickname === false) {
        // sessionStorage.setItem(`id`, data.response.data.id);
      } else {
        dispatch(
          profileSetting({
            nickname: result.nickname,
            picture: result.profileImageUrl,
            sns: result.platform === 1 ? "google" : result.platform === 2 ? "apple" : "facebook",
            email: result.email,
            id: result.id,
          })
        );
        dispatch(loginSetting(true));
      }
    }
  };

  useEffect(() => {
    // console.log("확인 >>>>>", getCookie(AUTH_COOKIE));
    if (getCookie(AUTH_COOKIE) !== null && getCookie(AUTH_COOKIE) !== undefined && getCookie(AUTH_COOKIE) !== "") {
      setCookies(getCookie(AUTH_COOKIE));
    } else {
      // navigate(`/login`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getCookie(AUTH_COOKIE)) {
      _getProfile();
    } else {
      dispatch(
        profileSetting({
          nickname: "",
          picture: "",
          sns: "",
          email: "",
          id: "",
        })
      );
      dispatch(loginSetting(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies]);

  useEffect(() => {
    const vh = window.innerHeight / 100;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }, []);

  useEffect(() => {
    dispatch(pathSetting(String(getLastPath(location.pathname))));
    dispatch(pathFirstSetting(String(getFirstPath(location.pathname))));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(pathSetting(String(getLastPath(location.pathname))));
    dispatch(pathFirstSetting(String(getFirstPath(location.pathname))));
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // mode button 스크롤시 위치
  useEffect(() => {
    window.addEventListener("scroll", handleModeButton);
    return () => {
      window.removeEventListener("scroll", handleModeButton); //clean up
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(windowWidthSetting(window.innerWidth));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.innerWidth]);

  // window width 사이즈 변경 감지
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (login.login) {
      setcheckLogin(loginPath);
    } else {
      setcheckLogin(path);
    }
  }, [login]);

  useEffect(() => {
    AOS.init();
  });

  let element = useRoutes(checkLogin);

  return (
    <>
      {element}

      {modal.open && modal.type === "terms" ? (
        <TermsModal
          close={_modalClose}
          header="이용약관"
          terms={terms.terms}
          pp={terms.pp}
          content={<TermsModalPage terms={terms.terms} pp={terms.pp} header="이용약관" ok={_modalClose} />}
        />
      ) : modal.open && modal.type === "pp" ? (
        <TermsModal
          close={_modalClose}
          header="개인정보처리방침"
          terms={terms.terms}
          pp={terms.pp}
          content={<PpModalPage terms={terms.terms} pp={terms.pp} header="개인정보처리방침" ok={_modalClose} />}
        />
      ) : null}

      {/* Modal */}
      {modal.open &&
        (modal.type === "warning" || modal.type === "danger" || modal.type === "success" || modal.type === "info") && (
          <ConfirmModal
            title={modal.title}
            description={modal.description}
            cancel="취소"
            type={modal.type}
            confirmButton={modal.confirmButton}
            confirmStyle={modal.confirmStyle}
          />
        )}
      {modal.open && modal.type === "report" && <ReportModal />}
      {alert.open && <AlertModal />}
      {modal.open && modal.type === "createItem" && <StudioItemModal />}
      {modal.open && modal.type === "withdraw" && <WithdrawModal />}
    </>
  );
};

export default App;
