export const boardTitle = (nowPath: string) => {

  switch (nowPath) {
    case "notice":
      return "공지사항";
    case "update":
      return "업데이트";
    case "event":
      return "이벤트";
    case "free-board":
      return "자유게시판";
    case "tip-board":
      return "팁 공유 게시판";
    case "screenshot-board":
      return "스크린샷 게시판";
    case "inquiry":
      return "1:1문의";
    default:
      return "none";
  }
}

