import Icon from "../../shared/Icon";
import WebviewSearch from "./WebviewSearch";
import { sendMessageExit } from "./unitySender";

const WebviewHeader = ({ activeSearch, setActiveSearch }) => {
  const handleOnPrev = () => {
    console.log("exit");
    if (window.vuplex) {
      sendMessageExit();
    } else {
      window.addEventListener("vuplexready", sendMessageExit);
    }
  };

  return (
    <>
      {activeSearch ? (
        <WebviewSearch setActiveSearch={setActiveSearch} />
      ) : (
        <div className="webview-header sticky">
          <div className="header-prev">
            <div className="icon-wrapper">
              <Icon
                className="chevron-right-small"
                icon="chevron-right-small"
                size="100%"
                color="#98A2B3"
                onClick={() => {
                  handleOnPrev();
                }}
              />
            </div>
            <span className="header-title">월드</span>
          </div>
          <div className="header-search">
            <Icon
              className="icon-search"
              icon="search"
              size="100%"
              color="#344054"
              onClick={() => {
                setActiveSearch(true);
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default WebviewHeader;
