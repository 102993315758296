import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Pagination from "react-js-pagination";

import {
  CategoryBox,
  BoardList,
  PageTop,
  InputBox,
  SelectBox,
  NormalButton,
  Loading,
  SearchMobileModal,
} from "@components/index";
import { LoadingState, RootState, SearchModalState, searchModalSetting, worldSearchModalSetting } from "@store/index";
import useSelect from "@hooks/useSelect";
import Icon from "../../../../shared/Icon";
import "./notice.scss";
import { useTranslation } from "react-i18next";
import { usehttp } from "@hooks/index";
import { getNewsList } from "@apis/board";

interface keyInterface {
  id: number;
  text: string;
}

const NoticePage = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const CategoryList = [
    { id: 0, title: t("notice_category_2_1") },
    { id: 1, title: t("notice_category_2_2") },
    { id: 2, title: t("notice_category_2_3") },
  ];

  const CategoryListData = [
    { id: 0, title: t("notice_category_1_1") },
    { id: 1, title: t("notice_category_1_2") },
    { id: 2, title: t("notice_category_1_3") },
    { id: 3, title: t("notice_category_1_4") },
  ];
  const { isLoading, sendRequest } = usehttp();

  const [listData, setListData] = useState([]); // Axios로 받아온 데이터 저장
  const [page, setPage] = useState(1); // 현재 페이지
  const handlePageChange = (page: number) => {
    setPage(page);
  };
  const [listPerPage] = useState(12); // 페이지 당 리스트 개수

  const [category, setCategory] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const selectData = useSelect(CategoryList[0]);

  const loading: LoadingState = useSelector((state: RootState) => state.loading);
  const searchModal: SearchModalState = useSelector((state: RootState) => state.searchModal);

  // get notice list
  const _getNoticeList = async () => {
    const { data: result } = await sendRequest(getNewsList, {
      path: "notice",
      category:
        category === 0
          ? "ALL" // 전체
          : category === 1
          ? "NOTICE" // 공지
          : category === 2
          ? "MAINTENANCE" // 점검
          : "MARKET",
      title: selectData.value.id !== 1 && searchValue ? searchValue : null,
      content: selectData.value.id !== 0 && searchValue ? searchValue : null,
      page: page,
      limit: listPerPage,
    });

    console.log(`_getNoticeList`, result);

    if (result?.error) {
      window.alert(result.code);
    } else {
      setListData(result?.notices);
    }
  };

  const handleOnKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleAddKeyword(searchValue);
      if (searchValue !== "" && searchModal.open) {
        dispatch(searchModalSetting(!searchModal.open));
      }
      _getNoticeList();
      setPage(1);
    }
  };

  const [keywords, setKeywords] = useState<keyInterface[]>([]);

  // 검색어 추가
  const handleAddKeyword = (text: string) => {
    if (text === null || text === undefined || text === "") {
    } else {
      const newKeyword = {
        id: Date.now(),
        text: text,
      };
      setKeywords([newKeyword, ...keywords]);
    }
  };

  // 단일 검색어 삭제
  const handleRemoveKeyword = (id: number) => {
    const nextKeyword = keywords.filter((keyword) => {
      return keyword.id !== id;
    });
    setKeywords(nextKeyword);
  };

  //검색어 전체 삭제
  const handleClearKeywords = () => {
    setKeywords([]);
  };

  useEffect(() => {
    setSearchValue("");
    _getNoticeList();
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  // ① window 즉, 브라우저가 모두 렌더링된 상태에서 해당 함수를 실행할 수 있도록 작업
  useEffect(() => {
    if (typeof window !== "undefined") {
      const result = localStorage.getItem("keywords") || "[]";
      setKeywords(JSON.parse(result));
    }
  }, []);

  // ② keywords 객체를 의존하여, 변경될 경우 새롭게 localStroage의 아이템 'keywords'를 세팅한다
  useEffect(() => {
    localStorage.setItem("keywords", JSON.stringify(keywords));
  }, [keywords]);

  return (
    <>
      <div className="container notice">
        <div className="notice__inner">
          <PageTop title={t("notice_title")} />
          <div className="board">
            <div className="board__top">
              <CategoryBox data={CategoryListData} category={category} setCategory={setCategory} />
              <div className="right">
                <SelectBox name="category" data={CategoryList} selectData={selectData} classification />
                <InputBox
                  value={searchValue}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setSearchValue(e.target.value);
                  }}
                  placeholder={t("notice_placeholder")}
                  onKeyPress={handleOnKeyPress}
                  icon
                  iconLocation="back"
                  iconSize={22}
                  iconColor="#344054"
                  iconName="search"
                />
                <Icon
                  className="icon-search"
                  icon="search"
                  size={40}
                  color="#344054"
                  onClick={() => {
                    dispatch(worldSearchModalSetting({ open: !searchModal.open, type: "search" }));
                  }}
                />
              </div>
            </div>
            <div className="board__body">
              {loading.isLoading ? (
                <Loading />
              ) : listData.length <= 0 || listData === null || listData === undefined ? (
                <div className="no_list">
                  <div className="img"></div>
                  <p className="title">{t("nolist_title")}</p>
                  <p className="description">{t("nolist_description")}</p>
                </div>
              ) : (
                listData.map((data, idx) => {
                  return <BoardList data={data} key={idx} />;
                })
              )}
            </div>
            <div className="board__bottom">
              {listData.length <= 0 || listData === null || listData === undefined ? null : (
                <>
                  <Pagination
                    activePage={page}
                    itemsCountPerPage={listPerPage}
                    totalItemsCount={listData === null || listData === undefined ? 0 : listData.length}
                    pageRangeDisplayed={10}
                    prevPageText={"‹"}
                    nextPageText={"›"}
                    onChange={handlePageChange}
                    innerClass="board__pagination"
                    activeClass="selected"
                    itemClassFirst="normal_button box sm secondary_gray"
                    itemClassPrev="normal_button box sm secondary_gray"
                    itemClassNext="normal_button box sm secondary_gray"
                    itemClassLast="normal_button box sm secondary_gray"
                  />
                  <div className="mobile_pagination">
                    <NormalButton
                      title="<"
                      size="sm"
                      hierarchy="secondary_gray"
                      buttonStyle="box"
                      onClick={() => {
                        if (page > 1) {
                          handlePageChange(page - 1);
                        }
                      }}
                    />
                    {listData === null || listData === undefined || listData.length <= 0 ? null : (
                      <p className="page">
                        Page {page} of{" "}
                        {listData.length / listPerPage < 1 ? "1" : Math.ceil(listData.length / listPerPage)}
                      </p>
                    )}

                    <NormalButton
                      title=">"
                      size="sm"
                      hierarchy="secondary_gray"
                      buttonStyle="box"
                      onClick={() => {
                        if (page < Math.ceil(listData.length / listPerPage)) {
                          handlePageChange(page + 1);
                        }
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {searchModal.open && searchModal.type === "search" && (
        <SearchMobileModal
          searchValueList={keywords}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          handleOnKeyPress={handleOnKeyPress}
          deleteAllSearchValue={handleClearKeywords}
          deleteSearchValue={handleRemoveKeyword}
        />
      )}
    </>
  );
};

export default NoticePage;
