import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { PageTop, NormalButton, AvatarList } from "@components/index";
import { getAvatarList, getAvatarMain, postAvatarMain } from "@apis/avatar";
import { RootState, AlertModalState, alertModalSetting } from "@store/index";
import { REACT_APP_URL_ZEMIVERSE } from "@utils/index";
import { usehttp } from "@hooks/index";

import "./settingAvatar.scss";

/**
 * setting-avatar - 계정 설정 - 아바타 설정
 */
const SettingAvatar = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const [selected, setSelected] = useState("");
  const [detail, setDetail] = useState([]);

  const alert: AlertModalState = useSelector((state: RootState) => state.alert);
  const { isLoading, sendRequest } = usehttp();

  // get avatar list
  const _getAvatarList = async () => {
    const { data: result } = await sendRequest(getAvatarList, {});

    console.log(`_getAvatarList`, result);

    if (result?.error) {
      window.alert(result.code);
    } else {
      setDetail(result?.list);
    }
  };

  // get avatar list
  const _getAvatarMain = async () => {
    const { data: result } = await sendRequest(getAvatarMain, {});

    console.log(`_getAvatarMain`, result);

    if (result?.error) {
      window.alert(result.code);
    } else {
      setSelected(result?.CHGUID);
    }
  };

  const _postAvatarMain = async () => {
    const { data: result } = await sendRequest(postAvatarMain, {
      CHGUID: selected,
    });

    console.log(`_postAvatarMain`, result);

    if (result?.error) {
      window.alert(result.code);
    } else {
      dispatch(alertModalSetting({ open: !alert.open, title: "대표 아바타가 변경되었습니다." }));
      setTimeout(() => {
        dispatch(alertModalSetting({ open: false, title: "" }));
      }, 2000);
    }
  };

  useEffect(() => {
    // _getAvatarList();
    // _getAvatarMain();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container setting-avatar">
      <div className="setting-avatar__inner">
        <PageTop
          title={"아바타 변경"}
          description={
            "보유중인 아바타 중 대표 아바타를 설정해보세요. 대표 아바타는 스튜디오, 마켓 등의 활동에 활용됩니다."
          }
        />
        {detail === null || detail === undefined || detail.length === 0 ? (
          <div className="box-empty-avatar">
            <div className="img"></div>
            <p className="title">생성된 아바타가 없습니다.</p>
            <p className="description">
              재미버스에 접속하여 나만의 아바타를 생성해보세요.
              <br />
              만약 생성된 아바타가 보이지 않으시면 다시 로그인 해주세요.
            </p>
            <NormalButton
              title={"재미버스 다운로드"}
              buttonStyle="round"
              size="xl"
              hierarchy="primary"
              icon="leading"
              iconType="download"
              iconColor="#FFFFFF"
              iconSize={20}
              onClick={() => {
                window.location.assign(`${REACT_APP_URL_ZEMIVERSE}/download`);
              }}
            />
          </div>
        ) : (
          <div className="box-avatar">
            {detail.map((data: any, idx: number) => (
              <AvatarList data={data} selected={selected} setSelected={setSelected} key={idx} />
            ))}
          </div>
        )}
        {(detail !== null || detail !== undefined || detail.length > 0) && detail.length !== 0 && (
          <NormalButton
            title={"저장"}
            onClick={() => {
              _postAvatarMain();
            }}
            buttonStyle="box"
            size="md"
            hierarchy="primary"
          />
        )}
      </div>
    </div>
  );
};

export default SettingAvatar;
