import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Editor } from "@toast-ui/react-editor";

import "@toast-ui/editor/dist/toastui-editor.css";
import "tui-color-picker/dist/tui-color-picker.css";
import "@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css";
import "@toast-ui/editor-plugin-table-merged-cell/dist/toastui-editor-plugin-table-merged-cell.css";
import tableMergedCell from "@toast-ui/editor-plugin-table-merged-cell";
import axios from "axios";
import { REACT_APP_SERVER_URL, getCookie } from "@shared/utils";
import { profileSetting, loginSetting } from "@store/index";

import colorSyntax from "@toast-ui/editor-plugin-color-syntax";
import { AUTH_COOKIE } from "@shared/constants";

interface EditorComponentProps {
  placeholder?: string;
  height?: string;
  editorRef?: any;
}

const EditorComponent = ({ placeholder = " ", height = "500px", editorRef }: EditorComponentProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const [imageFile, setImageFile] = useState<Blob | File>();

  // 유튜브 삽입을 위한 커스텀 툴바 아이템 생성
  const myCustomEl = document.createElement("span");

  // 팝업 바디 생성
  const container = document.createElement("div");
  const description = document.createElement("p");
  description.textContent = "Youtube 주소를 입력하고 Enter를 누르세요!";

  const urlInput = document.createElement("input");

  urlInput.style.width = "100%";

  // 팝업 input 창에 내용 입력 시 호출됨
  urlInput.addEventListener("keyup", (e: any) => {
    // 엔터를 누르면, 입력값이 Youtube 주소인지 정규식으로 검사
    if (e.key === "Enter") {
      if (
        /https:\/\/youtu.be\/.{11,}/.test(e.target.value) ||
        /https:\/\/www.youtube.com\/watch\?v=.{11,}/.test(e.target.value)
      ) {
        let str =
          '<iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/' +
          e.target.value.slice(-11) +
          '" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>';

        // 마크다운 모드에서 iframe 태그 삽입 후, 팝업을 닫고 위지윅 모드로 변환
        editorRef.current.getInstance().changeMode("markdown");
        editorRef.current.getInstance().insertText(str);
        editorRef.current.getInstance().eventEmitter.emit("closePopup");
        editorRef.current.getInstance().changeMode("wysiwyg");
      }
    }
  });

  container.appendChild(description);
  container.appendChild(urlInput);

  return (
    <div>
      <Editor
        ref={editorRef}
        initialValue={placeholder}
        previewStyle="vertical"
        height={height}
        initialEditType="wysiwyg"
        toolbarItems={[
          ["heading", "bold", "italic", "strike"],
          ["hr", "quote"],
          ["ul", "ol", "task", "indent", "outdent"],
          ["table", "image"],
          ["code", "codeblock"],
          [
            {
              name: "Youtube",
              tooltip: "Youtube",
              el: myCustomEl,
              popup: {
                body: container,
                style: { width: "auto" },
              },
            },
          ],
        ]}
        plugins={[colorSyntax, tableMergedCell]}
        customHTMLRenderer={{
          htmlBlock: {
            iframe(node) {
              return [
                {
                  type: "openTag",
                  tagName: "iframe",
                  outerNewLine: true,
                  attributes: node.attrs,
                },
                { type: "html", content: node.childrenHTML },
                { type: "closeTag", tagName: "iframe", outerNewLine: true },
              ];
            },
          },
        }}
        useCommandShortcut={true}
        hooks={{
          addImageBlobHook: async (blob: File, callback) => {
            await axios
              .get(`${REACT_APP_SERVER_URL}/file/signedUrl/image?files[0]=image`, {
                headers: {
                  token: getCookie(AUTH_COOKIE),
                },
              })
              .then(({ data }) => {
                const imageUrl = data.data?.image;

                if (data?.code === "TOKEN_EXPIRATION" && data?.error) {
                  dispatch(profileSetting({ nickname: "", picture: "", sns: "", email: "", id: "" }));
                  dispatch(loginSetting(false));
                  navigate(`/login`);
                } else {
                  axios
                    .put(imageUrl, blob)
                    .then(() => {
                      callback(imageUrl.substring(0, imageUrl.indexOf("?")), "첨부 이미지");
                    })
                    .catch((e) => {
                      console.error("Image upload failed:", e);
                    });
                }
              })
              .catch((e) => {
                console.error("S3 Presigned URL request failed:", e);
              });
          },
        }}
      />
    </div>
  );
};

export default EditorComponent;
