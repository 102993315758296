import { NormalButton } from "@components/index";
import { useNavigate } from "react-router-dom";
import Icon from "../../../../shared/Icon";
import { osName } from "react-device-detect";

import "./worldlist.scss";
import { useDispatch, useSelector } from "react-redux";
import { alertModalSetting, RootState, AlertModalState } from "@store/index";

export type Type = "recommend" | "popularity" | "creator";

interface NormalButtonProps {
  type?: Type | string;
  data?: any;
}

const WorldList = ({ type, data }: NormalButtonProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const alert: AlertModalState = useSelector((state: RootState) => state.alert);

  const openAppOrStore = (mapGUID: string) => {
    var appUrl = `nextor://zemiverse?${mapGUID}`;
    var storeUrl = "https://play.google.com/store/apps/details?id=com.nextor.zemiverse";

    window.location.href = appUrl;

    setTimeout(function () {
      window.location.href = storeUrl;
    }, 1000); // 1초 뒤 스토어로 리디렉션
  };
  if (type === "recommend") {
    return (
      <div
        className="box list__world recommend"
        onClick={() => {
          // navigate(`/world/${data.id}`, { state: { id: data.id } });
        }}
      >
        <div className="img__wrapper"></div>
        <div className="bottom">
          <div className="left">
            {/* <img src={data.user?} alt="" /> */}
            <div className="profile"></div>
            <div className="info">
              <p className="title">{data.title}</p>
              <p className="nickname">{data.user?.nickname}</p>
            </div>
          </div>
          <NormalButton
            title="Play"
            icon="leading"
            iconType="play-fill"
            iconSize={16}
            iconColor="#FFF"
            buttonStyle="box"
            size="xs"
            hierarchy="quaternary"
            onClick={() => {
              // console.log(navigator.userAgent);
              if (osName === "iOS") {
                // iOS
                dispatch(
                  alertModalSetting({
                    open: !alert.open,
                    title: "재미버스 iOS 버전은 개발 중입니다. 이에 불편을 드려 죄송합니다.",
                  })
                );
              } else if (osName === "Android") {
                // Android
                console.log(`android`);
                openAppOrStore(data?.mapGUID);
              } else if (osName === "Mac OS") {
                // Mac OS
                dispatch(
                  alertModalSetting({
                    open: !alert.open,
                    title: "재미버스 Mac OS 버전은 개발 중입니다. 이용에 불편을 드려 죄송합니다.",
                  })
                );
              } else {
                console.log(`web window`);
                openAppOrStore(data?.mapGUID);
              }
            }}
          />
        </div>
      </div>
    );
  } else if (type === "popularity") {
    return (
      <div
        className="box list__world popularity"
        onClick={() => {
          navigate(`/world/${data.id}`, { state: { id: data.id } });
        }}
      >
        <div className="img__wrapper">
          <img src={data.thumnail} alt="" />
        </div>
        <div className="bottom">
          <p className="title">{data.title}</p>
          <div className="bottom">
            <div className="box__like">
              <Icon className="heart-fill" icon="heart-fill" size={24} color="#98A2B3" />
              <p className="like">{data.like}</p>
            </div>
            <div className="box__view">
              <Icon className="play_fill" icon="play_fill" size={16} color="#98A2B3" />
              <p className="view">{data.view}</p>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (type === "creator") {
    return (
      <div
        className="box list__world creator"
        onClick={() => {
          navigate(`/world/${data.id}`, { state: { id: data.id } });
        }}
      >
        <div className="img__wrapper">
          {data.thumbnailImageUrl !== null && data.thumbnailImageUrl !== undefined && (
            <img src={data?.thumbnailImageUrl[0]} alt="" />
          )}
        </div>
        <div className="bottom">
          <div className="left">
            {/* <img src={data.user?.profile} alt="" /> */}
            <div className="profile"></div>
            <div className="info">
              <p className="title">{data.title}</p>
              <p className="nickname">{data.user?.nickname}</p>
              <div className="bottom">
                <div className="box__like">
                  <Icon className="heart-fill" icon="heart-fill" size={24} color="#98A2B3" />
                  <p className="like">{data?.likeCount}</p>
                </div>
                <div className="box__view">
                  <Icon className="play_fill" icon="play_fill" size={16} color="#98A2B3" />
                  <p className="view">{data?.playCount}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default WorldList;
