import { useEffect, useState } from "react";
import classNames from "classnames";

import { ReactComponent as CheckIcon } from "@assets/images/layer/check.svg";
import { ReactComponent as ChevronUpIcon } from "@assets/images/layer/chevron-up.svg";
import { ReactComponent as ChevronDownIcon } from "@assets/images/layer/chevron-down.svg";

import "./selectbox.scss";

export interface SelectBoxProps {
  selectData: any;
  name?: string;
  data: any;
  image?: boolean;
  classification?: boolean;
}
/**
 * slectbox components
 * @param selectData 현재 선택되어 있는 값(any)
 * @param name class명(string)
 * @param data selectbox의 optionList(any[])
 * @param image image가 있는 selectbox일 경우 (boolean)
 * @param classification default selectbox일 경우 (boolean)
 */
const SelectBox = ({ selectData, name, data, image, classification }: SelectBoxProps) => {
  const [toggle, setToggle] = useState(false);

  const selectedData = selectData;

  const imageSelectStyle = () => {
    return (
      <div className={classNames(`select-wrap`, name)}>
        <div className={classNames(`select image`)} onClick={() => setToggle(!toggle)}>
          <div className="left">
            {/* {selectedData.value.imageUrl ? (
              <img src={selectedData.value.imageUrl} alt={selectedData.value.title} />
            ) : ( */}
            <div className="img"></div>
            {/* )} */}
            <p>{selectedData.value?.nickname}</p>
          </div>
          <i></i>
        </div>
        <ul className={classNames(`select-ul image`, { on: toggle, off: !toggle })}>
          {data &&
            data.map((d: any, idx: number) => (
              <li
                key={idx}
                // className={classNames({ disabled: d.disable })}
                onClick={() => {
                  selectedData.onClick(d);
                  setToggle(false);
                }}
              >
                {/* <img src={d.imageUrl} alt={d.nickname} /> */}
                <div className="img"></div>
                <p>{d?.nickname}</p>
              </li>
            ))}
        </ul>
      </div>
    );
  };

  const classificationSelectStyle = () => {
    return (
      <div className={classNames(`select-wrap`, name)}>
        <div
          className={classNames(`select classification`, { on: toggle, off: !toggle })}
          onClick={() => setToggle(!toggle)}
        >
          <p>{selectedData.value.title}</p>
          {toggle ? <ChevronUpIcon /> : <ChevronDownIcon />}
        </div>
        <ul className={classNames(`select-ul classification`, { on: toggle, off: !toggle })}>
          {data &&
            data.map((d: any) => (
              <li
                key={d.id}
                className={classNames({ selected: selectedData.value.title === d.title })}
                onClick={() => {
                  selectedData.onClick(d);
                  setToggle(false);
                }}
              >
                <p>{d.title}</p>
                {selectedData.value.title === d.title && <CheckIcon />}
              </li>
            ))}
        </ul>
      </div>
    );
  };

  return (
    <>
      {image && imageSelectStyle()}
      {classification && classificationSelectStyle()}
    </>
  );
};

export default SelectBox;
