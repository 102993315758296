import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { RootState, PathState } from "@store/index";
import { ReactComponent as ViewIcon } from "@assets/images/board/view.svg";
import { ReactComponent as HeartIcon } from "@assets/images/board/heart.svg";
import { dateToString } from "@shared/utils/date";

import "./boardList.scss";

type BoardListProps = {
  data: any;
  detail?: boolean;
  background?: string;
};

/**
 * 게시판 리스트 (공지사항, 이벤트, 자유게시판, 팁공유게시판, 스크린샷 게시판, 1:1 문의, 디테일 페이지 타이틀에 사용됨)
 *
 * @data list data (any)
 * @detail 디테일 페이지인지 확인 (boolean)
 * @background 디테일 페이지에서 background가 white여야하므로 추가함 (string)
 */

const BoardList = ({ data, detail, background }: BoardListProps) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const path: PathState = useSelector((state: RootState) => state.path);

  const [newList, setNewLIst] = useState(false);

  const [eventDate, setEventDate] = useState("");

  /**
   * 현재 리스트가 생성된지 24시간이 지나기 전이라면 New 표시 띄워줌(디테일이 아닐 경우에만!)
   */
  const _newList = () => {
    var now = new Date();
    var yesterday = new Date(now.setDate(now.getDate() - 1));
    const date: Date = new Date(data.createdAt);
    setNewLIst(yesterday.getTime() < date.getTime());
  };

  const _eventDateCheck = () => {
    var state = ""; // 예정, 진행, 종료
    var now = new Date();
    const startDate = new Date(data.startDate);
    const endDate = new Date(data.endDate);

    if (startDate > now) {
      // 날짜가 오늘보다 나중인 경우 "예정"
      state = "예정";
    } else if (startDate <= now && endDate >= now) {
      // 오늘을 기준으로 날짜가 범위에 있는 경우 "진행"
      state = "진행";
    } else if (endDate < now) {
      // 날짜가 오늘보다 이전인 경우 "종료"
      state = "종료";
    }

    setEventDate(state);
  };

  useEffect(() => {
    if (!detail) {
      _newList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (path.nowPath === "event") {
      _eventDateCheck();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log(`now path >>>>>>>`, path.nowPath);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={classNames("board_list", path.nowPath, { fixed: data.fix, detail: detail })}
      onClick={() => {
        console.log(path.nowPath);
        if (
          path.nowPath === "notice" ||
          path.nowPath === "notice2" ||
          path.nowPath === "update" ||
          path.nowPath === "event"
        ) {
          navigate(`/news/${path.nowPath}/${data.id}`, { state: { id: data.id } });
        } else if (
          path.nowPath === "free-board" ||
          path.nowPath === "tip-board" ||
          path.nowPath === "screenshot-board"
        ) {
          navigate(`/community/${path.nowPath}/${data.id}`, { state: { id: data.id } });
        } else if (path.nowPath === "inquiry") {
          navigate(`/customer-service/${path.nowPath}/${data.id}`, { state: { id: data.id } });
        }
      }}
      style={{ background: background }}
    >
      {/* image */}
      {(path.nowPath === "event" || path.nowPath === "screenshot-board") && (
        <div className="img__wrapper">
          <img src={data.thumbnailImageUrl} alt={data.title} />
        </div>
      )}

      {/* category */}
      {(path.nowPath === "notice" ||
        (path.nowPath === "notice" && detail) ||
        path.nowPath === "event" ||
        (path.nowPath === "event" && detail) ||
        (["free-board", "tip-board"].includes(path.nowPath) && data.noti)) && (
        <p
          className={classNames("category", {
            [t("boardlist_category_1")]: data.category === "NOTICE",
            [t("boardlist_category_2")]: data.category === "MAINTENANCE",
            [t("boardlist_category_3")]: data.category === "MARKET",

            [t("boardlist_category_4")]: eventDate === "진행",
            [t("boardlist_category_5")]: eventDate === "종료",
            [t("boardlist_category_6")]: eventDate === "예정",
          })}
        >
          {data.category === "NOTICE"
            ? t("boardlist_category_1")
            : data.category === "MAINTENANCE"
            ? t("boardlist_category_2")
            : data.category === "MARKET"
            ? t("boardlist_category_3")
            : eventDate === "진행"
            ? t("boardlist_category_4")
            : eventDate === "종료"
            ? t("boardlist_category_5")
            : eventDate === "예정"
            ? t("boardlist_category_6")
            : ""}
        </p>
      )}

      {/* title */}
      <div className="title_box">
        <p className="title">
          {/* comment length */}
          {data.title}&nbsp;
          {!data.noti &&
            !detail &&
            (path.nowPath === "free-board" || path.nowPath === "tip-board") &&
            data.commentCount !== null &&
            data.commentCount !== undefined &&
            data.commentCount !== 0 && <span className="comment">&#91;{data.commentCount}&#93;</span>}
        </p>
        {/* new icon */}
        {newList && !detail && <span className="new"></span>}
      </div>

      {/* user */}
      {["free-board", "tip-board", "screenshot-board", "inquiry"].includes(path.nowPath) && (
        <div className="data_box">
          <div className="data_user">
            {data.user?.profileImageUrl && <img src={data.user?.profileImageUrl} alt={data.user} />}
            {data.user?.nickname && <p>{data.user?.nickname}</p>}
            {data.fix && <p>{t("admin")}</p>}
            {/* <p>{data.nickname}</p> */}
          </div>
          {data.likeCount !== undefined && data.likeCount !== null && (
            <div className="like">
              <HeartIcon />
              <p>{data.likeCount}</p>
            </div>
          )}
          <div className="view">
            <ViewIcon />
            <p>{data.views}</p>
          </div>
        </div>
      )}

      {/* state */}
      {path.nowPath === "inquiry" && (
        <div className={classNames("state", "category", data.category)}>{data.category}</div>
      )}

      {/* date  */}
      <p className="date">
        {path.nowPath === "event" && <span>{t("event_period")} : </span>}

        {path.nowPath === "event" &&
          dateToString(data.startDate, "yyyy.MM.dd hh:mm", i18n.language) +
            " ~ " +
            dateToString(data.endDate, "yyyy.MM.dd hh:mm", i18n.language)}

        {path.nowPath !== "event" && dateToString(data.assignedAt, "yyyy.MM.dd", i18n.language)}
      </p>
    </div>
  );
};

export default BoardList;
