import { useEffect, useState } from "react";

import { Comment } from "@components/index";
import { usehttp } from "@hooks/index";
import { getReplyList, postReply } from "@apis/board";

interface CommentListProps {
  commentData: any;
  getCommentList?: Function;
  postId?: string;
}

const CommentList = ({ commentData, getCommentList, postId }: CommentListProps) => {
  const [value, setValue] = useState("");
  const [replyList, setReplyList] = useState([]);
  const [commentOpen, setCommentOpen] = useState(false);

  const { sendRequest } = usehttp();

  // Get Reply List
  const _getReplyList = async () => {
    const { data: result } = await sendRequest(getReplyList, {
      commentId: commentData.id,
    });

    console.log(`_getReplyList`, result);

    if (result?.error) {
      window.alert(result.code);
      return;
    } else {
      setReplyList(result?.replies);
    }
  };

  // after google login -> get token
  const _postReply = async () => {
    const { data: result } = await sendRequest(postReply, {
      postId: postId,
      commentId: commentData.id,
      content: value,
    });

    console.log(`_postReply`, result);

    if (result?.error) {
      window.alert(result.code);
      return;
    } else {
      setValue("");
      _getReplyList();
      getCommentList();
    }
  };

  useEffect(() => {
    _getReplyList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* 댓글 */}
      <Comment
        commentData={commentData}
        onClick={_postReply}
        value={value}
        setValue={setValue}
        commentOpen={commentOpen}
        setCommentOpen={setCommentOpen}
      />
      {/* 대댓글 .map 돌리기 */}
      {commentOpen &&
        replyList.map((data: any, idx: number) => (
          <Comment
            commentData={data}
            value={value}
            setValue={setValue}
            commentOpen={commentOpen}
            setCommentOpen={setCommentOpen}
            key={idx}
            reply
          />
        ))}
      {/* 대대댓글 .map 돌리기 */}
      {/* <Comment reReply loggedInUser={loggedInUser} /> */}
    </>
  );
};

export default CommentList;
