import { Dispatch, SetStateAction } from "react";

import classNames from "classnames";

import "./chipsGroup.scss";

export interface ChipsGroupProps {
  data: any;
  category: number;
  setCategory: Dispatch<SetStateAction<number>>;
}

const ChipsGroup = ({ data, nowCategory, setNowCategory }) => {
  return (
    <div className="box-chips-group">
      <ul className="box-chips-group__inner">
        {data.map((data: any, idx: number) => (
          <li
            className={classNames({ on: nowCategory === idx })}
            onClick={() => {
              // console.log(data);
              setNowCategory(idx);
            }}
            key={idx}
          >
            {data.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ChipsGroup;
