import classNames from "classnames";
import { Outlet, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { MainHeader } from "@components/index";

import "./mainLayout.scss";

const MainLayout = () => {
  const { i18n } = useTranslation();
  const location = useLocation();

  return (
    <div className={classNames(`App`, i18n.language, location.pathname === "/" ? "main" : "")}>
      {/* Header Components가 들어가는 부분 */}
      <MainHeader />
      <div className="main_body">
        {/* side Nav가 들어가는 부분 */}
        {/* Main Content */}
        <Outlet />
      </div>
    </div>
  );
};

export default MainLayout;
