import { http } from "./http";

export function getWebviewList({ page, limit }: WebviewList) {
  return http.get<WebviewList>(`/world/list?page=${page}&limit=${limit}`);
}

export function getWebviewDetail({ id }: WebviewDetail) {
  return http.get<WebviewDetail>(`/world/detail?postId=${id}`);
}

type WebviewList = {
  page: number;
  limit: number;
};

type WebviewDetail = {
  id: string;
};
