const Border = ({ width, height }: { width: number | string; height: number }) => {
  return (
    <div
      style={{
        width,
        height,
        flex: "none",
        background: "#EAECF0",
      }}
    />
  );
};

export default Border;
