import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GOODS_DATA } from "./placehoder-data";
import { numberWithCommas } from "@utils/numberWithCommas";
import classNames from "classnames";
import Icon from "../../../shared/Icon";

const GoodsDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pageId = location.state?.id;
  const [times, setTimes] = useState(1);

  const [data, setData] = useState<any>({
    id: 1,
    nameEn: "Americano",
    nameKo: "아메리카노",
    price: 2500,
    description:
      "[진함 고소함] 견과류 풍미와 초콜릿처럼 달콤 쌉싸름한 맛이 밸런스 있게 어우러진 균형잡힌 바디감의 커피",
  });

  useEffect(() => {
    if (pageId !== null || pageId !== undefined) {
      setData(GOODS_DATA.find((data) => data.id === pageId));
    }
  }, [pageId]);

  const handleLookAround = () => {
    console.log("IR_Protocol, preview");
    const sendMessage = () => {
      window.vuplex.postMessage({ type: "IR_Protocol", message: "preview" });
    };
    if (window.vuplex) {
      sendMessage();
    } else {
      window.addEventListener("vuplexready", sendMessage);
    }
  };

  return (
    <>
      <div className={classNames("top-order", "goods-detail-wrapper")}>
        <div className={classNames("box-img", "goods-detail-img")}>
          <img src={data.imgUrl} alt="" />
          <div className="goods-images">
            <img src={data.imgUrl} alt="" />
            <img src="" alt="" />
            <img src="" alt="" />
          </div>
        </div>
        <div className={classNames("right", "goods-right")}>
          <p className={classNames("title-en", "goods-singer-detail")}>{data?.nameEn}</p>
          <p className={classNames("goods-title-detail")}>{data?.nameKo}</p>
          <div className="goods-description">
            {"· 판매 기간 : 1/1(월) ~ 1/31(수) 19:00\n· 배송 기간 : 3/1 ~ 3/15 이내 배송 예정"}
          </div>
          <p className={classNames("price", "goods-price")}>
            {String(numberWithCommas(data?.price))}원
          </p>

          <div className={classNames("bottom-order", "none-fixed")}>
            <div className={classNames("box-count", "goods-count")}>
              <div className="left">
                <div className="box-icon">
                  <Icon
                    className="minus"
                    icon="minus"
                    size="100%"
                    color="#344054"
                    onClick={() => {
                      if (times > 1) {
                        setTimes(times - 1);
                      }
                    }}
                  />
                </div>
                <p>{times}</p>
                <div className="box-icon">
                  <Icon
                    className="plus"
                    icon="plus"
                    size="100%"
                    color="#344054"
                    onClick={() => {
                      if (times < 10) {
                        setTimes(times + 1);
                      }
                    }}
                  />
                </div>
              </div>
              <p className="total-price">{numberWithCommas(data?.price * times)}원</p>
            </div>
          </div>
        </div>
      </div>

      <div className="goods-btn">
        <div className="good-sub-btn">
          <button onClick={handleLookAround}>구경하기</button>
          <button
            className="goods-buy-btn"
            onClick={() => {
              navigate(`/goods/order`, {
                state: { id: data.id, total: times * data.price },
              });
            }}
          >
            구매하기
          </button>
        </div>
      </div>
    </>
  );
};

export default GoodsDetail;
