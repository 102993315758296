import { http } from "./http";
import qs from "qs";

// get avatar list
export const getAvatarList = (data: {}) => {
  return http.get<any>(`/avatar`);
};

// post main avatar
export const postMainAvatar = (data: { CHGUID: string }) => {
  return http.post<any, null>(`/avatar/main`, data);
};

// get nickname duplication
export const getNicknameDuplication = (data: { nickname: string }) => {
  return http.get<any>(
    `/auth/nickname/exist${qs.stringify(
      { nickname: data.nickname },
      { arrayFormat: "repeat", skipNulls: true, addQueryPrefix: true }
    )}`
  );
};

// patch nickname
export const patchNickname = (data: { nickname: string }) => {
  return http.patch<any, null>(`/auth/nickname`, data);
};

// post login
export const postLogin = (data: { client?: string; platform: number; access_token: string }) => {
  return http.post<any, null>(`/auth/signIn`, data);
};

// get profile
export const getProfile = (data: {}) => {
  return http.get<any>(`/auth/myInfo`);
};

// put profile
export const putProfile = (data: {
  nickname: string;
  profileImageUrl: string;
  summary: string;
  backgroundColor: string;
  backgroundImageUrl: string;
  background: string;
  sns: object;
}) => {
  return http.put<any, null>(`/auth/myInfo`, data);
};
