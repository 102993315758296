import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { osName } from "react-device-detect";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Pagination } from "swiper/modules";
import { usehttp } from "@hooks/index";

import { NormalButton } from "@components/index";
import Icon from "../../../../shared/Icon";

import "./worldDetail.scss";
import classNames from "classnames";
import { getWorldDetail, postLikeWorld, postFavoriteWorld, postPlayeWorld } from "@apis/world";
import { dateToString } from "@utils/date";
import { alertModalSetting, RootState, AlertModalState, LoginState } from "@store/index";

import "@toast-ui/editor/dist/toastui-editor-viewer.css";
import { Viewer } from "@toast-ui/react-editor";

const WorldDetail = () => {
  const { i18n } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { sendRequest } = usehttp();

  const tabMenu = ["소개", "공지", "업데이트", "상점"];
  const [like, setLike] = useState(false);
  const [save, setSave] = useState(false);
  const [tab, setTab] = useState(0);
  const [detailData, setDetailData] = useState<any>({
    id: "",
    entityId: "",
    entityType: "",
    title: "",
    content: "",
    summary: "",
    updatedAt: "",
    createdAt: "",
    postId: "",
    playCount: 0,
    likeCount: 0,
    thumbnailImageUrl: [],
  });

  const alert: AlertModalState = useSelector((state: RootState) => state.alert);
  const login: LoginState = useSelector((state: RootState) => state.login);

  const openAppOrStore = (postId: string) => {
    var appUrl = `nextor://zemiverse?${postId}`;
    var storeUrl = "https://play.google.com/store/apps/details?id=com.nextor.zemiverse";

    window.location.href = appUrl;

    setTimeout(function () {
      window.location.href = storeUrl;
    }, 1000); // 1초 뒤 스토어로 리디렉션
  };

  // get world list 월드 리스트 가져오기
  const _getWorldDetail = async () => {
    const { data: result } = await sendRequest(getWorldDetail, {
      postId: location.state.id,
    });

    if (result?.error) {
      window.alert(result.code);
      return;
    } else {
      setDetailData(result);
      //!: 여기 !

      if (login.login) {
        setLike(result?.likeByMe);
        setSave(result?.favoriteByMe);
      }
    }
  };
  // like world
  const _postLike = async () => {
    const { data: result } = await sendRequest(postLikeWorld, {
      entityType: "POST",
      entityId: location.state.id,
    });

    // console.log(`_postLike`, result);

    if (result?.error) {
      window.alert(result.code);
    } else {
      _getWorldDetail();
    }
  };

  // favorite world
  const _postFavoriteWorld = async () => {
    const { data: result } = await sendRequest(postFavoriteWorld, {
      postId: location.state.id,
    });

    // console.log(`_postFavoriteWorld`, result);

    if (result?.error) {
      window.alert(result.code);
    } else {
      _getWorldDetail();
    }
  };

  // play world
  const _postPlayWorld = async () => {
    const { data: result } = await sendRequest(postPlayeWorld, {
      postId: location.state.id,
    });

    // console.log(`_postPlayWorld`, result);

    if (result?.error) {
      window.alert(result.code);
    } else {
      _getWorldDetail();
    }
  };

  useEffect(() => {
    // console.log(location.state?.id);
    if (location.state?.id) {
      _getWorldDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  useEffect(() => {
    // console.log(detailData);
  }, [detailData]);

  // console.log(detailData);

  return (
    <div className="container detail__world">
      <div className="detail__world__inner">
        <div className="top">
          <p
            onClick={() => {
              navigate("/");
            }}
          >
            월드
          </p>
          <Icon className="chevron-right" icon="chevron-right" size={16} color="#98A2B3" />
          <p>{detailData?.title}</p>
        </div>
        <div className="box__data">
          <Swiper
            slidesPerView={1}
            spaceBetween={0}
            loop
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
          >
            {detailData.thumbnailImageUrl &&
              detailData.thumbnailImageUrl.map((data: any, idx: number) => (
                <SwiperSlide key={idx}>
                  <img src={data} alt="" />
                </SwiperSlide>
              ))}
          </Swiper>
          <div className="right">
            <div className="top">
              <p className="title">{detailData?.title}</p>
              <div className="data">
                <div className="left">
                  {/* <img className="profile" src="ttps://dummyimage.com/44x44/000/fff" alt="user profile" /> */}
                  <div className="profile"></div>
                  <div className="user">
                    <p className="nickname">nickname</p>
                    <p className="creator">Creator</p>
                  </div>
                </div>
                <div className="right">
                  <Icon className="play-circle-fill" icon="play-circle-fill" size={24} color="#667085" />
                  <p className="view">{detailData?.playCount}</p>
                </div>
              </div>
              <div className="divider"></div>
              <p className="description">{detailData?.summary}</p>
            </div>
            <div className="box__button">
              <div className="left">
                <NormalButton
                  title=""
                  icon="leading"
                  iconType={save ? "star-fill" : "star"}
                  iconSize={24}
                  iconColor={save ? "#FEC84B" : "#101828"}
                  buttonStyle="box"
                  size="txl"
                  hierarchy="secondary_gray"
                  onClick={() => {
                    _postFavoriteWorld();
                  }}
                />
                <NormalButton
                  title={String(detailData?.likeCount)}
                  icon="leading"
                  iconType={like ? "heart-fill-L" : "heart"}
                  iconSize={24}
                  iconColor={like ? "#F04438" : "#101828"}
                  buttonStyle="box"
                  size="txl"
                  hierarchy="secondary_gray"
                  onClick={() => {
                    // setLike(!like);
                    _postLike();
                  }}
                />
              </div>
              <NormalButton
                title="월드 플레이"
                icon="leading"
                iconType="play-circle-fill"
                iconSize={24}
                iconColor="#FFF"
                buttonStyle="box"
                size="txl"
                hierarchy="primary"
                onClick={() => {
                  console.log(navigator.userAgent);
                  // console.log(navigator.userAgent);
                  if (osName === "iOS") {
                    // iOS
                    dispatch(
                      alertModalSetting({
                        open: !alert.open,
                        title: "재미버스 iOS 버전은 개발 중입니다. 이에 불편을 드려 죄송합니다.",
                      })
                    );
                  } else if (osName === "Android") {
                    // Android
                    console.log(`android`);
                    _postPlayWorld();
                    openAppOrStore(detailData?.postId);
                  } else if (osName === "Mac OS") {
                    // Mac OS
                    dispatch(
                      alertModalSetting({
                        open: !alert.open,
                        title: "재미버스 Mac OS 버전은 개발 중입니다. 이용에 불편을 드려 죄송합니다.",
                      })
                    );
                  } else {
                    console.log(`web window`);
                    _postPlayWorld();
                    openAppOrStore(detailData?.postId);
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className="bottom">
          <ul className="menu__tab">
            {tabMenu.map((data: string, idx: number) => (
              <li
                className={classNames({ on: tab === idx })}
                key={idx}
                onClick={() => {
                  setTab(idx);
                }}
              >
                {data}
              </li>
            ))}
          </ul>
          <div className="boxes">
            <div className={classNames("box__introduction", { on: tab === 0 })}>
              {detailData?.content && (
                <Viewer
                  initialValue={detailData.content || ""} // 유튜브 삽입 및 미리보기 를 위한 설정(iframe)
                  customHTMLRenderer={{
                    htmlBlock: {
                      iframe(node) {
                        return [
                          {
                            type: "openTag",
                            tagName: "iframe",
                            outerNewLine: true,
                            attributes: node.attrs,
                          },
                          { type: "html", content: node.childrenHTML },
                          { type: "closeTag", tagName: "iframe", outerNewLine: false },
                        ];
                      },
                    },
                  }}
                />
              )}
            </div>
            <div className={classNames("box__notification", { on: tab === 1 })}></div>
            <div className={classNames("box__update", { on: tab === 2 })}>
              <p>{dateToString(detailData?.updatedAt, "yyyy.MM.dd hh:mm", i18n.language)}</p>
            </div>
            <div className={classNames("box__store", { on: tab === 3 })}></div>
          </div>
          <div className="info">
            <p className="info__release">
              출시일 <span>{dateToString(detailData?.createdAt, "yyyy.MM.dd", i18n.language)}</span>
            </p>
            <p className="info__update">
              업데이트 <span>{dateToString(detailData?.updatedAt, "yyyy.MM.dd", i18n.language)}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorldDetail;
