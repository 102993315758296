const SEARCH_MODAL_SETTING = "searchModal/SEARCH_MODAL_SETTING" as const;
const WORLD_MODAL_SETTING = "searchModal/WORLD_MODAL_SETTING" as const;

type ModalAction = ReturnType<typeof searchModalSetting> | ReturnType<typeof worldSearchModalSetting>;

export const searchModalSetting = (diff: boolean) => ({
  type: SEARCH_MODAL_SETTING,
  payload: diff,
});

export const worldSearchModalSetting = (diff: { open: boolean; type: string }) => ({
  type: WORLD_MODAL_SETTING,
  payload: diff,
});

export type SearchModalState = {
  open: boolean;
  type: string;
};

const initialState: SearchModalState = {
  open: false,
  type: "search",
};

function searchModal(state: SearchModalState = initialState, action: ModalAction): SearchModalState {
  switch (action.type) {
    case SEARCH_MODAL_SETTING:
      return { ...state, open: action.payload };
    case WORLD_MODAL_SETTING:
      return { ...state, open: action.payload.open, type: action.payload.type };
    default:
      return state;
  }
}

export default searchModal;
