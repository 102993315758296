import { useRef, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Editor } from "@toast-ui/react-editor";

import { PageTop, InputBox, EditorComponent, NormalButton } from "@components/index";
import {
  RootState,
  ModalState,
  modalSetting,
  PathState,
  LoginState,
  alertModalSetting,
  AlertModalState,
  modalOpenSetting,
} from "@store/index";
import { boardTitle } from "@shared/utils";
import { usehttp } from "@hooks/index";
import { getDetail, postNewArticle, putArticle } from "@apis/board";

const Write = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const editorRef = useRef<Editor>(null);
  var regex = /<img[^>]*src="([^"]*)"/;

  const [title, setTitle] = useState("");
  const [detailData, setDetailData] = useState<any>([]);
  const { sendRequest } = usehttp();

  //파일 미리볼 url을 저장해줄 state
  const [fileImage, setFileImage] = useState("");
  // const [file, setFile] = useState<Blob | string>("");

  const path: PathState = useSelector((state: RootState) => state.path);
  const modal: ModalState = useSelector((state: RootState) => state.modal);
  const alert: AlertModalState = useSelector((state: RootState) => state.alert);
  const login: LoginState = useSelector((state: RootState) => state.login);

  let pathType =
    path.nowPath === "free-board"
      ? `FREE`
      : path.nowPath === "tip-board"
      ? `TIP`
      : path.nowPath === "screenshot-board"
      ? `PHOTO`
      : ``;

  // get detail
  const _getDetail = async () => {
    const { data: result } = await sendRequest(getDetail, {
      path: "community",
      postId: location.state?.id,
    });

    console.log(`_getDetail`, result);

    if (result?.error) {
      window.alert(result.code);
    } else {
      setDetailData(result);
    }
  };

  // post new article
  const _postNewArticle = async () => {
    const content = editorRef.current?.getInstance().getHTML();
    var match = regex.exec(content);

    if (path.nowPath === "screenshot-board" && (!match || match === null || match === undefined)) {
      dispatch(alertModalSetting({ open: !alert.open, title: t("write_empty_image") }));
      setTimeout(() => {
        dispatch(alertModalSetting({ open: false, title: "" }));
      }, 2000);
    } else {
      if (match) {
        var srcContent = match[1];
      }

      const { data: result } = await sendRequest(postNewArticle, {
        category: pathType, //FREE : 자유게시판, PHOTO: 스크린샷 게시판, TIP: 팁공유 게시판
        title: title,
        content: editorRef.current?.getInstance().getHTML(),
        // file

        ...(pathType === "PHOTO" ? { thumbnailImageUrl: srcContent.toString() } : {}),
      });

      console.log(`_getDetail`, result);

      if (result?.error) {
        window.alert(result.code);
      } else {
        dispatch(alertModalSetting({ open: !alert.open, title: "게시물이 등록 되었습니다." }));
        dispatch(modalOpenSetting(false));
        setTimeout(() => {
          dispatch(alertModalSetting({ open: false, title: "" }));
        }, 2000);
        navigate(-1);
      }
    }
  };

  // put article
  const _putArticle = async () => {
    dispatch(modalOpenSetting(false));
    const content = editorRef.current?.getInstance().getHTML();
    var match = regex.exec(content);

    if (path.nowPath === "screenshot-board" && (!match || match === null || match === undefined)) {
      dispatch(alertModalSetting({ open: !alert.open, title: t("write_empty_image") }));
      setTimeout(() => {
        dispatch(alertModalSetting({ open: false, title: "" }));
      }, 2000);
    } else {
      if (match) {
        var srcContent = match[1];
      }

      const { data: result } = await sendRequest(putArticle, {
        postId: location.state?.id,
        title: title,
        content: editorRef.current?.getInstance().getHTML(),

        // file
        ...(pathType === "PHOTO" ? { thumbnailImageUrl: srcContent.toString() } : {}),
      });

      console.log(`_putArticle`, result);

      if (result?.error) {
        window.alert(result.code);
      } else {
        dispatch(alertModalSetting({ open: !alert.open, title: "게시물이 수정 되었습니다." }));
        dispatch(modalOpenSetting(false));
        setTimeout(() => {
          dispatch(alertModalSetting({ open: false, title: "" }));
        }, 2000);
        navigate(-1);
      }
    }
  };

  const EmptyValueWarning = () => {
    dispatch(modalOpenSetting(!modal.open));
    const isTitleEmpty = !title;
    // const isFileEmpty = !file;
    // const isEditorEmpty = !editorRef.current?.getInstance().getMarkdown() || editorRef.current?.getInstance().getMarkdown().length < 100;
    const isCreating = !location.state;

    if (isTitleEmpty) {
      dispatch(
        modalSetting({
          open: true,
          type: "danger",
          title: t("write_empty_title"),
          description: "",
          confirmButton: "확인",
          confirmStyle: "red",
          onConfirm: () => {},
          alert: true,
        })
      );
      // } else if (isEditorEmpty) {
      //   window.alert(t("write_empty_contents"));
    }
    // else if (isFileEmpty) {
    //   if (path.nowPath === "screenshot-board") {
    //     dispatch(alertModalSetting({ open: !alert.open, title: t("write_empty_image") }));
    //   } else if (path.nowPath !== "screenshot-board") {
    //     if (isCreating) {
    //       _postNewArticle();
    //     } else {
    //       _patchArticle();
    //     }
    //   }
    // }
    else {
      if (isCreating) {
        _postNewArticle();
      } else {
        _putArticle();
      }
    }
  };

  // // 이미지 파일 저장
  // const saveFileImage = (e: any) => {
  //   setFileImage(URL.createObjectURL(e.target.files[0]));
  //   setFile(e.target.files[0]);
  //   e.target.value = "";
  // };

  // // 이미지 파일 삭제
  // const deleteFileImage = () => {
  //   URL.revokeObjectURL(fileImage);
  //   setFileImage("");
  // };

  useEffect(() => {
    // 1. DB에서 가져온 HTML이라고 가정
    const htmlString = "";
    // 2. Editor DOM 내용에 HTML 주입
    editorRef.current?.getInstance().setHTML(htmlString);
  }, []);

  useEffect(() => {
    if (modal.isOk === true) {
      // _submitController();
    }
  }, [modal]);

  useEffect(() => {
    if (location.state?.id) {
      _getDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  useEffect(() => {
    if (!login.login) {
      navigate(`/login`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (detailData) {
      setTitle(detailData.title ? detailData.title : "");
      editorRef.current?.getInstance().setHTML(detailData.content);
      if (path.nowPath === "screenshot-board") {
        if (detailData.file !== undefined || detailData.file !== null || detailData.file !== "") {
          setFileImage(`${detailData.file}`);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailData]);

  return (
    <div className="write">
      <div className="write__inner">
        <PageTop title={boardTitle(path.nowPath)} />
      </div>
      <div className="write__body">
        <div className="write__body--top">
          <InputBox
            name="title"
            value={title}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setTitle(e.target.value);
            }}
            placeholder={t("write_title_insert")}
            maxLength={40}
            // icon={true}
          />
        </div>
        <div className="write__body--editor">
          <EditorComponent editorRef={editorRef} />
        </div>
        {/* {path.nowPath === "screenshot-board" && (
          <div className="write__body--bottom">
            <div className="top">
              <p className="title">
                {t("write_thumnail")}
                {t("")}
                <span>{t("write_thumnail_content")}</span>
              </p>

              <div className="button--upload">
                <label className="button" htmlFor="file">
                  {t("write_select_image")}
                </label>
                <input className="hide" type="file" accept="image/*" onChange={saveFileImage} id="file" />
              </div>
            </div>

            {fileImage !== undefined && fileImage !== null && fileImage !== `undefined` && fileImage !== "" && (
              <div className="img--box">
                <img alt="uploadImg" src={fileImage} />
                <div
                  className="button--file--delete"
                  onClick={() => {
                    deleteFileImage();
                  }}
                ></div>
              </div>
            )}
          </div>
        )} */}
      </div>
      <div className="write__bottom">
        <NormalButton
          title="취소"
          hierarchy="secondary_gray"
          onClick={() => {
            navigate(-1);
          }}
          buttonStyle="box"
          size="md"
        />
        <NormalButton
          title={location?.state ? t("write_edit") : t("write_register")}
          hierarchy="primary"
          buttonStyle="box"
          size="md"
          onClick={() => {
            if (location?.state) {
              dispatch(
                modalSetting({
                  open: !modal.open,
                  type: "success",
                  title: t("write_edit_title"),
                  description: t("write_edit_question"),
                  confirmButton: t("write_edit_title"),
                  confirmStyle: "primary",
                  onConfirm: EmptyValueWarning,
                  alert: false,
                })
              );
            } else {
              dispatch(
                modalSetting({
                  open: !modal.open,
                  type: "success",
                  title: t("write_register_title"),
                  description: t("write_register_question"),
                  confirmButton: t("write_register_title"),
                  confirmStyle: "primary",
                  onConfirm: EmptyValueWarning,
                  alert: false,
                })
              );
            }
          }}
        />
      </div>
    </div>
  );
};

export default Write;
