import { useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";
import { numberWithCommas } from "@utils/numberWithCommas";
import "@components/layout/concertLayout/concertLayout.scss";

const GoodsOrder = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id, total } = location.state;
  const restPoint = 130000 - Number(total) > 0 ? 130000 - Number(total) : 0;

  const handleOnOrder = () => {
    console.log("IR_Protocol, payment");
    const sendMessage = () => {
      window.vuplex.postMessage({ type: "IR_Protocol", message: "payment" });
    };
    if (window.vuplex) {
      sendMessage();
    } else {
      window.addEventListener("vuplexready", sendMessage);
    }

    navigate(`/goods/complete`, {
      state: { id, total },
    });
  };

  return (
    <div className="goods-order">
      <div className="goods-item-wrap">
        <h2 className="goods-subtitle">받는 사람 정보</h2>
        <button className="change-address-btn">배송지 변경</button>
      </div>
      <ul className={classNames("concert-list", "goods-unordered-list")}>
        <li className="concert-item">
          <div className="goods-order-label">이름</div>
          <div className="goods-order-info">
            <div className="default-address-wrapper">
              <p>전주천</p>
              <span className="default-address">기본 배송지</span>
            </div>
          </div>
        </li>

        <li className="concert-item">
          <div className="goods-order-label">주소</div>
          <p className="goods-order-info">서울특별시 강남구 논현로 419 PMK빌딩 7층</p>
        </li>

        <li className="concert-item">
          <div className="goods-order-label">연락처</div>
          <p className="goods-order-info">010-1234-5678</p>
        </li>
        <li className="concert-item">
          <div className="goods-order-label">배송 요청사항</div>
          <p className="goods-order-info">
            문앞
            <button className="change-order-btn">변경</button>
          </p>
        </li>
      </ul>

      <h2 className={classNames("goods-subtitle", "goods-order-info-title")}>결제 정보</h2>
      <ul className={classNames("concert-list", "goods-unordered-last")}>
        <li className="concert-item">
          <div className="goods-order-label">총 상품 가격</div>
          <p className="goods-order-info">{numberWithCommas(total)}</p>
        </li>

        <li className="concert-item">
          <div className={classNames("goods-order-label", "long-labels")}>결제 수단</div>
          <div className="goods-order-info">
            <div className="goods-radio-wrapper">
              <input type="radio" id="card" name="payment" value="card" className="goods-radio" />
              <label className="goods-radio-label" htmlFor="card">
                신용카드
              </label>
            </div>
            <div className="goods-radio-wrapper">
              <input
                type="radio"
                id="point"
                name="payment"
                value="point"
                className="goods-radio"
                checked
                onChange={() => {}}
              />
              <label className="goods-radio-label" htmlFor="point">
                <div className="point-wrapper">
                  <p>포인트 결제</p>
                  <span className="goods-rest-points">사용 가능 130,000P</span>
                </div>
              </label>
            </div>
          </div>
        </li>

        <li className="concert-item">
          <div className="goods-order-label">배송비</div>
          <p className="goods-order-info">0원</p>
        </li>
        <li className="concert-item">
          <div className="goods-order-label">포인트 사용</div>
          <p className={classNames("goods-order-info", "goods-points-use")}>
            - {numberWithCommas(total)}P <span>{` (잔여: ${numberWithCommas(restPoint)}P)`}</span>
          </p>
        </li>
        <li className="concert-item">
          <div className="goods-order-label">총 결제 금액</div>
          <p className="goods-order-info">0원</p>
        </li>
      </ul>

      <button className="goods-order-btn" onClick={handleOnOrder}>
        결제하기
      </button>
    </div>
  );
};

export default GoodsOrder;
