// export const categories: { name: string; path: string }[] = {
export const categories: any = {
  recommend: "추천",
  popular: "인기",
  new: "신규",
  // favorite: "즐겨찾기",
  // recent: "최근 참여한",
  // myworld: "My 월드",
};

export const getCategoryPath = (path: string) => (categories[path] ? path : "recommend");
