// import "@components/layout/restaurantLayout/restaurantLayout.scss";
import { useState } from "react";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import Icon from "../../../shared/Icon";
import "@components/layout/KioskLayout/kioskLayout.scss";

const RestaurantOrder = () => {
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [headCount, setHeadCount] = useState(2);
  const [selected, setSelected] = useState(0);

  const category = [
    { id: 0, title: "오전 10:00" },
    { id: 1, title: "오전 11:00" },
    { id: 2, title: "오후 12:00" },
    { id: 3, title: "오후 1:00" },
    { id: 4, title: "오후 2:00" },
    { id: 5, title: "오후 3:00" },
    { id: 6, title: "오후 4:00" },
    { id: 7, title: "오후 5:00" },
    { id: 8, title: "오후 6:00" },
  ];

  return (
    <>
      <div className="restaurant-order">
        <DatePicker
          selected={selectedDate}
          onChange={(date: Date) => setSelectedDate(date)}
          locale={ko} // 한글로 변경
          dateFormat="yyyy-MM-dd aa hh:mm" // 시간 포맷 변경
          inline
          renderCustomHeader={({ monthDate, customHeaderCount, decreaseMonth, increaseMonth }) => (
            <div className="date-picker-inner">
              <button
                aria-label="Previous Month"
                className={"react-datepicker__navigation react-datepicker__navigation--previous"}
                onClick={decreaseMonth}
              >
                <span
                  className={
                    "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
                  }
                >
                  {"<"}
                </span>
              </button>
              <span className="react-datepicker__current-month">
                {monthDate.toLocaleString("ko", {
                  month: "long",
                  year: "numeric",
                })}
              </span>
              <button
                aria-label="Next Month"
                className={"react-datepicker__navigation react-datepicker__navigation--next"}
                onClick={increaseMonth}
              >
                <span
                  className={
                    "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
                  }
                >
                  {">"}
                </span>
              </button>
            </div>
          )}
        />

        <div className="select-time">
          <h1>시간을 선택하세요.</h1>
          <ul className="restaurant-time">
            {category.map(({ id, title }) => (
              <li
                key={id}
                className={classNames({ on: id === selected })}
                onClick={() => setSelected(id)}
              >
                {title}
              </li>
            ))}
          </ul>
        </div>

        <div className="restaurant-headcount">
          <h1>
            인원을 선택하세요. <span>(최소 2인부터 예약 가능합니다.)</span>
          </h1>
          <div className={classNames("bottom-order", "restaurant-count")}>
            <div className="box-count">
              <div className="left">
                <div className="box-icon">
                  <Icon
                    className="minus"
                    icon="minus"
                    size="100%"
                    color="#344054"
                    onClick={() => {
                      if (headCount > 2) setHeadCount(headCount - 1);
                    }}
                  />
                </div>
                <p>{headCount}</p>
                <div className="box-icon">
                  <Icon
                    className="plus"
                    icon="plus"
                    size="100%"
                    color="#344054"
                    onClick={() => {
                      if (headCount < 10) {
                        setHeadCount(headCount + 1);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        className="restaurant-reservation"
        onClick={() =>
          navigate("/restaurant/complete", {
            state: { selectedDate, headCount, selectedTime: category[selected].title },
          })
        }
      >
        예약하기
      </button>
    </>
  );
};

export default RestaurantOrder;
