import "./pageTop.scss";

export interface PageTopProps {
  title: string;
  description?: string;
}

/**
 * 페이지 별 상단 제목(새소식, 커뮤니티, 고객센터 메인 및 상세, 글쓰기 등)
 * @title 페이지 이름
 * @description 페이지 상세 내용
 */
const PageTop = ({title, description} : PageTopProps) => {
  return(
    <div className="page_top">
      <p className="title">{title}</p>
      {description && <p className="description">{description}</p>}
    </div>
  );
}

export default PageTop;